import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

const Container = styled.div`
  position: absolute;
  width: 75%;
  bottom: 0;
  left: 0;
  transform: translate(calc(-50% - 2rem), -3rem);
  svg {
    enable-background: new 0 0 378.2 185;
    fill: rgba(0, 0, 0, 0.35);
  }
  ${media.tablet`
    svg {}
  `}
`

const Name = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 378.2 185">
        <g>
          <g>
            <path
              d="M168.8,0.5c4.5,0.3,8.5,2.1,12.1,5.3c4.5,4.3,8,8.7,10.6,13.3c1.8,3.1,2.7,5.9,2.9,8.3c0.2,2.4-0.2,4.4-1.2,6.1
			c-1,1.7-2.6,3.6-5,5.5c-1.1,0.9-2.3,1.8-3.6,2.7s-2.9,2-4.7,3.2c-1.8,1.2-3.1,2-3.9,2.5c-0.2,0.2-0.6,0.4-1.1,0.7
			c-0.5,0.3-0.9,0.5-1.1,0.6c-1.3,0.8-2.1,1.5-2.4,2.2c-0.3,0.7-0.2,1.7,0.1,3.1c1.1,3.2,2.3,6.1,3.7,8.5c0.8,1.3,1.9,3,3.5,5.3
			c1.5,2.3,2.5,3.6,2.9,4.1l6.3,10.2c0.2,0.3,0.4,0.8,0.7,1.6c0.7,1.7,0.8,2.8,0.4,3.5c-0.4,0.6-1.5,1-3.2,1c-1.3,0.1-3.3,0.1-6,0
			c-2,0-3.3-0.8-4-2.5c-0.4-1.1-0.8-2.1-1.3-3.1c-0.4-1-1.1-2.1-1.8-3.4c-0.8-1.3-1.4-2.2-1.7-2.7c-0.3-0.5-1.1-1.6-2.1-3.2
			c-1.1-1.6-1.7-2.5-1.8-2.8c-2.4-3.5-4.7-7.2-6.9-10.9c-0.3-0.6-1-2.3-2.1-5c-0.6-1.4-1.4-2-2.4-1.8c-1.2,0.2-1.8,0.9-1.8,2.2
			c0,3.1,0.1,5.2,0.4,6.2c0.3,1.4,0.9,3.4,1.7,6c0.8,2.6,1.5,4.5,1.8,5.6c1.3,4.9,2.1,8.5,2.5,10.9c0.2,1.7,0,2.8-0.5,3.4
			c-0.5,0.6-1.7,0.9-3.6,0.9c-2.4,0.1-4.2,0.1-5.4,0c-1.1-0.1-1.8-0.3-2.2-0.7c-0.4-0.3-0.6-1-0.7-2c-0.1-1.5-0.3-3.1-0.7-4.9
			c-0.4-1.8-0.7-3.2-1-4.1c-0.2-0.9-0.8-2.6-1.6-4.9c-0.8-2.4-1.3-3.8-1.4-4.3c-1.5-4.2-2.2-7.9-2.2-10.9c0-3.4,0.9-6.7,2.6-9.7
			c2-3.3,3.6-5.9,4.9-7.7c3.5-5.3,4.2-11,1.9-17.2c-0.4-1-0.9-2.4-1.5-4.2c-0.6-1.8-0.9-2.8-1-3c-1.1-3-1.4-6.5-0.9-10.5
			c0.2-1.5,0.6-2.5,1.3-3c0.6-0.5,1.7-0.8,3.2-0.8c0.3,0,3.2,0,8.7,0C165.5,0.3,168.4,0.4,168.8,0.5z M178.5,34.4
			c2.7-2.3,3.6-5.1,2.8-8.5c-0.8-3.8-2.9-7-6.3-9.6c-3.4-2.6-7.1-3.6-10.9-3.2c-1.3,0.1-2.1,0.4-2.4,0.9c-0.3,0.5-0.3,1.3,0.1,2.5
			c0.2,0.8,0.6,1.9,1.1,3.4c0.5,1.5,0.9,2.6,1.1,3.2c1.4,4.6,1.1,8.8-0.7,12.7c-0.7,1.6-0.9,2.6-0.7,3s1.2,0.7,2.9,0.7
			c0.1,0,0.2,0,0.4,0h0.1C170.5,39.4,174.7,37.7,178.5,34.4z"
            />
            <path
              d="M216.1,0c0.5,0,1,0,1.5,0.1c0.5,0.1,0.9,0.1,1.2,0.1s0.5,0.1,0.9,0.4c0.3,0.2,0.5,0.4,0.6,0.4c0,0,0.2,0.3,0.4,0.7
			c0.2,0.4,0.4,0.8,0.4,1c0,0.2,0.2,0.7,0.5,1.4c0.3,0.7,0.5,1.3,0.6,1.5c0.9,2.5,2.3,5.1,4.3,8c1.6,2.3,4.6,6.2,9,11.9
			c0.6,0.9,1.2,1.3,1.9,1.3c0.9,0,1.4-0.7,1.6-2.1c0.4-3.4,0.6-6.1,0.6-8c0.2-4.8,1.6-9.4,4.1-13.8c1.3-2.1,3-3,5.3-2.9
			c0.8,0,1.9,0,3.2,0c1.4,0,2.4,0,3.1,0c2.1,0.1,2.7,1.1,1.9,3.1c-0.1,0.4-0.3,0.8-0.6,1.3c-2.1,3.4-3.2,7.5-3.4,12.2
			c-0.1,1.5-0.1,2.6-0.1,3.2c-0.1,6.7-2.5,12.2-7.1,16.6c-4.6,4.5-7,6.8-7.2,6.9c-5.1,4.3-6.9,10.2-5.3,17.5c0.2,1,0.5,2,0.8,3.2
			c0.3,1.1,0.7,2.4,1.2,3.7c0.4,1.3,0.8,2.4,1,3.2c1.8,6.1,2.7,9.7,2.9,10.7c0.5,2.6,0.4,4.4-0.2,5.2c-0.6,0.8-2.3,1.2-5.1,1.2
			c-2.3,0-3.6,0-4.1,0c-1.1-0.1-1.8-0.3-2.2-0.7c-0.4-0.4-0.6-1-0.6-1.9c-0.1-1.6-0.3-3.2-0.7-4.9c-0.4-1.7-0.7-3.1-1-4.2
			c-0.3-1.1-0.8-2.7-1.5-4.9c-0.7-2.2-1.3-3.6-1.5-4.4c-0.9-2.7-1.5-5.2-1.8-7.2c-0.6-3.2-0.3-6.8,0.9-10.7c1-3.3,1.9-7.5,2.8-12.5
			c0.7-3.3-0.1-6.7-2.4-10c-1.4-2.1-3.6-5.1-6.6-9.1c-3.7-5-6-8.8-6.9-11.5c-0.5-1.4-0.8-2.4-0.9-3.1c-0.3-1.7,0.4-2.6,2.1-2.8
			c0.2,0,1.7,0,4.4,0C214.9,0.1,215.6,0.1,216.1,0z"
            />
            <path
              d="M299.1,0.1c1.4,0,2.3,0.2,2.7,0.7c0.4,0.4,0.7,1.3,0.7,2.6c-0.2,4.3,1,9.1,3.5,14.3c1.3,2.8,2.4,5.6,3.4,8.4
			c1.2,3.4,1,6.8-0.4,10.2c-1.6,3.3-2.9,6-4,8c-3.3,5.9-3.7,12.1-1,18.6c0.5,1.2,1.3,3,2.4,5.4c1.1,2.5,1.9,4.3,2.4,5.4
			c0.8,2.1,1.9,5.2,3.2,9.4c0.2,0.5,0.3,1.3,0.4,2.5c0,1.6-0.7,2.4-2.1,2.5c-2.6,0.1-5.1,0.1-7.7,0c-1.3-0.1-2.1-1-2.5-2.8
			c-0.6-2.3-1-3.9-1.3-5c-0.7-2.4-2.3-3.5-4.7-3.5c-4.6,0-7,0-7.1,0c-1.2,0.1-2,0.3-2.4,0.7c-0.4,0.4-0.5,1.2-0.4,2.4
			c0,0.2,0,1.1,0.1,2.6c0,1.6,0,2.5-0.1,2.6c-0.1,1-0.3,1.7-0.7,2.1c-0.4,0.4-1.1,0.7-2.2,0.7c-1.2,0.1-3.2,0.1-6,0
			c-1.7,0-2.5-0.7-2.4-2.1c0.2-2.4,0.1-4.8-0.3-7.4c-0.4-2.6-1-5.4-1.7-8.5c-0.7-3-1.3-5.4-1.6-7.2c-0.9-6.1,0.4-11.6,3.8-16.5
			l5.9-8.1c3.8-4.9,5-10.4,3.5-16.5c-0.5-1.6-1-3.8-1.6-6.8c-0.9-3.8-0.8-7.5,0.3-11c0.4-1.5,0.9-2.5,1.5-2.9
			c0.6-0.5,1.6-0.7,3.1-0.7c0.4,0,2.6,0,6.5,0C296.6,0.1,298.9,0.1,299.1,0.1z M293.9,22.3c-0.1,1.7-0.1,2.6-0.1,2.6
			c0.4,4.1-0.6,7.9-2.9,11.3l-3.4,4.7c-3,4.3-4.7,6.6-4.9,6.9c-2.4,3.6-3.4,7.8-3.1,12.5c0.2,2.5,1.3,3.7,3.2,3.8
			c2.2,0.2,4.5,0.2,7.1,0c1.4-0.1,2-0.9,1.8-2.4c0-0.4-0.1-0.9-0.3-1.5c-2-5.6-1.4-11.2,1.8-16.8c1.2-2.3,2.4-4.9,3.7-8
			c1.4-3.4,1.2-7.1-0.4-11c0-0.1-0.5-0.9-1.6-2.5L293.9,22.3z"
            />
            <path
              d="M359.3,42.9c2.7-4.2,4.4-7.2,5.2-8.8c1.3-2.6,1.5-5.6,0.6-9.1c-0.6-2.2-1.6-5.2-3.1-9.1c-1.4-4-1.8-8-1.2-11.8
			c0.2-1.6,0.6-2.6,1.2-3.2c0.6-0.5,1.6-0.8,3.1-0.8c2.3-0.1,4.1-0.1,5.6,0c1.2,0,2,0.2,2.4,0.7c0.4,0.4,0.6,1.2,0.4,2.3
			c-0.7,4.7,0.2,10.3,2.8,16.8c0.1,0.2,0.2,0.5,0.3,0.9c0.1,0.4,0.2,0.7,0.3,0.9c2.4,6.2,1.7,11.9-1.9,17.2
			c-0.2,0.2-1.7,2.6-4.6,7.2c-2.9,4.7-3.7,9.9-2.4,15.5c0.4,1.4,1,3.3,1.8,5.9c0.8,2.6,1.4,4.4,1.7,5.5c1.4,4.9,2.2,8.5,2.5,10.9
			c0.3,1.7,0.2,2.8-0.4,3.4c-0.5,0.6-1.7,0.9-3.6,0.9c-0.3,0-1.3,0-3,0c-1.7,0-2.7,0-2.9,0c-1.9,0-3.1-0.9-3.8-2.7
			c-1.2-3.1-3.8-7.7-7.8-13.5c-3-4.5-5.3-8-6.8-10.3c-3.5-5.9-4.5-11.6-2.8-17.1c0.1-0.3,0.2-0.9,0.3-1.8l-0.3-0.9
			c-1.2,0.7-1.8,1.1-1.8,1.2c-4.2,5.4-5.8,10.8-4.9,16.2c0.1,0.5,1.1,4.2,2.9,11l3.4,12.4c0.1,0.4,0.2,1,0.3,1.8
			c0,1.3-0.2,2.2-0.7,2.7c-0.5,0.5-1.4,0.9-2.7,1c-2.3,0.1-4.4,0.1-6.5,0c-1.1-0.1-1.8-0.3-2.2-0.7c-0.4-0.4-0.6-1.1-0.6-2.2
			c-0.2-2.9-1.3-7.5-3.2-13.5c-1.5-4.6-2.6-8.5-3.4-11.8c-1-4.3-0.2-8.6,2.2-13c0.2-0.4,1.2-2,2.9-4.9l2.9-4.7
			c2.6-4.4,3.2-9,1.6-13.7l-2.9-8.7c-1.4-4-1.7-7.9-0.9-11.5c0.3-1.2,0.7-2,1.2-2.5c0.5-0.5,1.3-0.7,2.5-0.7c2.1-0.1,4.2-0.1,6.5,0
			c1.2,0,2,0.2,2.5,0.6c0.5,0.4,0.8,1.1,1,2.1c1,4.5,3.8,10,8.5,16.5c1.3,1.7,2.8,3.9,4.4,6.6c2.3,3.7,2.8,8,1.8,12.7
			c0,0.2-0.1,0.6-0.3,1.3c-0.2,0.7-0.3,1.2-0.4,1.5c-0.1,0.3-0.2,0.8-0.2,1.6c0,0.8,0,1.4,0.2,1.8C358.5,43.6,359.2,43,359.3,42.9z"
            />
          </g>
          <g>
            <path
              d="M34.5,134.2c2,0,3.4-1,4.4-2.9c1.3-2.7,1.7-5.2,1.2-7.7c-0.2-0.8-1-3.6-2.4-8.4c-0.2-0.5-0.5-1.3-0.8-2.4
			c-0.3-1.1-0.6-2-0.9-2.9c-0.2-0.8-0.4-1.6-0.5-2.3c-0.3-2-0.2-4.6,0.1-7.8c0.2-2,1.3-3,3.2-3.1c2.3-0.1,4.5-0.1,6.8,0
			c2.1,0.1,2.9,1.1,2.5,3.1c-0.8,4.3,0,9.6,2.4,15.9c0.4,0.8,0.9,2.5,1.6,5c1.4,4.4,0.9,8.5-1.3,12.4c-2,3.4-3.4,5.8-4.4,7.2
			c-4.5,6.4-5.5,13.4-2.9,20.9c0.1,0.3,0.6,1.8,1.5,4.4c0.9,2.6,1.5,4.7,1.9,6.2c0.9,3,1.5,5.9,1.9,8.5c0.2,1.6,0,2.7-0.5,3.3
			c-0.5,0.6-1.6,1-3.3,1c-2.3,0.1-4.2,0.1-5.9,0c-1-0.1-1.7-0.3-2.1-0.7c-0.4-0.4-0.7-1.1-0.7-2.2c-0.2-3.4-1.2-7.7-2.9-12.8
			c-1.2-3.4-1.9-5.4-2.1-6c-1.6-4.8-2.2-9-1.9-12.7c0.2-1.2,0.1-2-0.3-2.4c-0.4-0.4-1.2-0.7-2.4-0.7c-3.5-0.1-6.8-0.1-9.7,0
			c-2.7,0.1-4,1.4-4,4c-0.1,3,0.1,5.7,0.7,8.1c0.3,1.4,0.9,3.3,1.7,5.8c0.8,2.5,1.4,4.2,1.7,5.1c0.5,1.9,1.3,5.1,2.4,9.6
			c0.2,0.7,0.2,1.5,0.1,2.4c-0.2,1.7-1,2.5-2.4,2.5c-2.9,0.1-5.6,0.1-8,0c-1.5,0-2.3-0.8-2.4-2.5c0-1.1-0.1-2.1-0.2-3.2
			c-0.1-1-0.4-2.2-0.8-3.5c-0.4-1.3-0.7-2.3-0.8-2.9C5,171.9,4.6,170.7,4,169c-0.6-1.7-0.9-2.7-1-3c-1-3.1-1.9-6.4-2.7-9.9
			c-0.9-4.2-0.1-8.3,2.2-12.4c1.6-2.9,3.2-5.7,5-8.3c3.6-5.3,4.3-11,1.9-17.2c-1.6-4.4-2.6-8-3.2-10.9c-0.2-1.2-0.1-3.8,0.1-7.8
			c0.2-1.8,1.2-2.7,2.9-2.8c2.7-0.1,5.2-0.1,7.4,0c1.6,0.1,2.4,1,2.4,2.6c0,0.3-0.1,0.7-0.1,1.2c-0.6,3.6-0.2,7.4,1.2,11.3
			c0.2,0.6,0.5,1.4,0.8,2.4c0.3,1,0.7,2,1,2.8c0.3,0.8,0.6,1.6,0.9,2.4c1.3,4.1,1.3,8,0,11.5c-0.5,1.3-0.6,2.1-0.4,2.5
			c0.2,0.4,1.1,0.6,2.6,0.7c0.1,0,0.3,0,0.6,0h4.1v-0.1C32.7,134.2,34.3,134.2,34.5,134.2z"
            />
            <path
              d="M71.9,175.3c-3.7-5.3-6.2-11.9-7.4-19.9c-0.7-4.4,0.2-8.7,2.8-12.8l4.7-7.7c3.2-5,3.9-10.3,1.9-15.9
			c-0.8-2.3-1.5-4.4-2.1-6.3c-1.2-3.9-0.3-7.4,2.5-10.3c2.6-2.6,5.5-4.2,8.8-5c4-1,8.5-1.1,13.4-0.3c3.6,0.6,6.6,2.5,8.8,5.6
			c4,5.4,7,11.9,8.8,19.6c0.8,3.6,0.2,7.1-1.6,10.5c-0.5,0.9-1.3,2.2-2.4,4c-1.1,1.8-1.9,3.1-2.4,4.1c-1.8,3.1-2.9,5.6-3.5,7.4
			c-1.1,2.8-1,6.5,0.1,10.9c0.2,0.8,0.7,2.8,1.6,5.9c0.1,0.3,0.2,0.7,0.4,1.3c0.2,0.5,0.3,1,0.4,1.2c1.5,4.5,1.2,8.3-0.9,11.5
			c-2.1,3.1-5.4,5-10.2,5.6c-0.2,0-2,0.1-5.3,0.3C83.2,185.5,76.9,182.3,71.9,175.3z M79.6,165.7c1,2.9,2.3,4.9,4,6
			c1.7,1,4,1.5,7.1,1.3c3.6-0.3,5-2.2,4-5.6c-0.1-0.4-0.7-2.4-1.9-5.9c-2.5-7.8-1.4-14.8,3.1-21.1c1.1-1.7,2.6-4.3,4.7-7.8
			c1.8-3.2,2.2-6.6,1.3-10.2c-0.8-3.2-1.8-6.3-2.9-9.1c-1.5-3.4-4-5.1-7.7-5c-0.3,0-0.7,0-1.3,0c-0.5,0-1,0-1.4,0
			c-3.5,0.3-4.8,2.2-3.8,5.6c0.2,1,0.7,2.7,1.6,5.2c2,5.6,1.4,10.9-1.8,15.8l-5,8.1c-2,3.3-2.9,6.4-2.8,9.3
			C76.8,156,77.7,160.5,79.6,165.7z"
            />
            <path
              d="M137.3,185c-1.8-0.1-2.9-0.4-3.4-0.9c-0.5-0.5-0.8-1.6-0.9-3.2c-0.2-2.6-1.1-6.5-2.8-11.9c-1.9-5.6-3-9.2-3.4-10.9
			c-1.4-5.5-0.6-10.7,2.4-15.5l5.2-8.1c2.9-4.6,3.5-9.6,1.8-14.9l-2.9-8.7c-1.1-2.9-1.4-6.3-0.9-10c0.2-1.7,0.6-2.8,1.2-3.3
			c0.6-0.5,1.7-0.8,3.4-0.8c2.1-0.1,3.8-0.1,5.2-0.1c1.4,0,2.3,0.3,2.7,0.8c0.4,0.5,0.6,1.4,0.4,2.6c-0.7,4.5,0,9.5,2.2,14.9
			c0.1,0.3,0.3,0.8,0.5,1.5c0.2,0.7,0.4,1.2,0.5,1.6c2.5,6.2,1.8,12-1.9,17.5c-0.4,0.6-1.9,2.9-4.6,7.1c-2.8,4.6-3.6,9.7-2.2,15.3
			c0.2,1.2,1.2,4.7,3.1,10.6c0.8,2.4,2.5,3.5,5.2,3.5c2.7-0.1,6.6-0.1,11.6,0c2,0,3.3,0.3,4,0.8c0.7,0.5,1.2,1.8,1.6,3.8
			c0.3,1.4,0.4,2.6,0.4,3.7c0.1,1.8-0.1,2.9-0.7,3.5s-1.6,0.9-3.3,1c-0.2,0-1,0-2.4,0c-3.4,0-6.8,0-10,0
			C141.7,185,137.7,185,137.3,185z"
            />
            <path
              d="M193.3,96.8c2,0.2,2.8,1.1,2.5,2.8c-0.9,4.4,0,10.1,2.6,16.9l1.2,3.8c1.6,4.7,1,9.2-1.6,13.5c-2.2,3.6-3.7,6.1-4.6,7.4
			c-3.9,5.8-4.9,12-2.8,18.7c0.3,1,0.8,2.6,1.6,4.9c0.8,2.3,1.4,4.1,1.8,5.4c1.1,4,1.9,7.3,2.4,9.9c0.2,1.8,0,3-0.5,3.6
			c-0.5,0.6-1.7,1-3.5,1c-2.3,0.1-4.1,0.1-5.6,0c-1.2-0.1-2-0.3-2.4-0.7c-0.4-0.4-0.6-1.2-0.7-2.4c-0.1-2.8-1.2-7.4-3.2-13.5
			c-1.4-4.3-2.5-8.2-3.2-11.8c-1-4.1-0.3-8.3,2.1-12.4c0.1-0.3,1.2-2.3,3.4-5.9l2.8-4.4c2.5-4.2,2.9-8.6,1.5-13.3l-2.9-8.5
			c-1.4-4.1-1.7-7.9-1-11.2c0.3-1.6,0.7-2.6,1.3-3.1c0.5-0.5,1.6-0.7,3.2-0.7c0.1,0,0.9,0,2.4,0C192,96.8,193.2,96.8,193.3,96.8z"
            />
            <path
              d="M222.7,185c-1.4-0.1-2.3-0.4-2.8-0.8c-0.5-0.4-0.7-1.3-0.7-2.6c-0.1-0.9-0.2-1.8-0.4-2.7c-0.1-0.9-0.3-1.9-0.6-2.9
			s-0.5-1.9-0.7-2.6c-0.2-0.7-0.6-1.6-1-2.9c-0.4-1.2-0.7-2.1-0.9-2.7c-1.5-4.8-2.6-8.8-3.2-12.1c-0.8-3.9,0-8,2.2-12.1
			c0.3-0.4,1.2-2,2.8-4.7l3.2-5.3c2.5-4.1,3-8.5,1.6-13.3c-0.6-1.7-1.5-4.1-2.6-7.4c-1.4-4.1-1.8-8-1.3-11.8
			c0.3-1.8,0.7-2.9,1.3-3.5c0.6-0.5,1.7-0.9,3.4-1c6.3,0,11.5,0,15.8,0c7.5,0.1,13.2,3.2,17.1,9.4c2.8,4.4,4.9,9.5,6.5,15.3
			c1,4,0.4,7.9-1.6,11.6c-0.5,0.9-1.4,2.5-2.8,4.7l-2.9,4.7c-2.9,4.8-3.7,10.1-2.4,15.8c0,0.3,0.7,2.9,2.2,7.8
			c0.7,2.3,1.1,3.5,1.2,3.7c1.1,4.6-0.3,8.3-4.3,11.2c-3.3,2.5-7.4,3.8-12.1,4c-0.4,0-3.1,0-8.2,0C226,185,223.1,185,222.7,185z
			 M236.3,108.3h-1.2c-0.5,0-0.8,0-0.9,0c-1.4,0.1-2.3,0.4-2.7,1c-0.4,0.5-0.4,1.4,0,2.6c0.3,1.2,0.8,2.8,1.5,4.8
			c0.7,2,1.1,3.3,1.3,3.9c1.5,4.5,1,8.8-1.5,13l-3.1,5.3c-2,3.2-3.1,5.2-3.4,5.7c-1.9,3.3-2.6,6.9-2.1,10.7
			c0.5,3.4,2.1,8.6,4.7,15.6c0.6,1.8,1.6,2.7,3.1,2.8c3.4,0,6.1-0.2,8.1-0.6c1.2-0.2,2-0.8,2.5-1.7c0.5-0.9,0.6-2,0.4-3.2
			c0-0.2-0.3-1.4-1-3.7c-1.2-4.3-1.8-6.6-1.9-6.8c-1.1-5.5-0.3-10.4,2.4-14.7l3.1-5.2c1.8-2.9,2.8-4.8,3.2-5.6
			c1.4-2.8,1.8-6.4,1.2-10.5c-0.6-4.2-2-7.4-4.1-9.8c-1.2-1.3-2.4-2.1-3.5-2.5c-0.3-0.1-2.4-0.6-6.2-1.5V108.3z"
            />
            <path
              d="M304.3,96.8c1.4,0,2.3,0.2,2.7,0.7c0.4,0.4,0.7,1.3,0.7,2.6c-0.2,4.3,1,9.1,3.5,14.3c1.3,2.8,2.4,5.6,3.4,8.4
			c1.2,3.4,1,6.8-0.4,10.2c-1.6,3.3-2.9,6-4,8c-3.3,5.9-3.7,12.1-1,18.6c0.5,1.2,1.3,3,2.4,5.4c1.1,2.5,1.9,4.3,2.4,5.4
			c0.8,2.1,1.9,5.2,3.2,9.4c0.2,0.5,0.3,1.3,0.4,2.5c0,1.6-0.7,2.4-2.1,2.5c-2.6,0.1-5.1,0.1-7.7,0c-1.3-0.1-2.1-1-2.5-2.8
			c-0.6-2.3-1-3.9-1.3-5c-0.7-2.4-2.3-3.5-4.7-3.5c-4.6,0-7,0-7.1,0c-1.2,0.1-2,0.3-2.4,0.7c-0.4,0.4-0.5,1.2-0.4,2.4
			c0,0.2,0,1.1,0.1,2.6c0,1.6,0,2.5-0.1,2.6c-0.1,1-0.3,1.7-0.7,2.1c-0.4,0.4-1.1,0.7-2.2,0.7c-1.2,0.1-3.2,0.1-6,0
			c-1.7,0-2.5-0.7-2.4-2.1c0.2-2.4,0.1-4.8-0.3-7.4c-0.4-2.6-1-5.4-1.7-8.5c-0.7-3-1.3-5.4-1.6-7.2c-0.9-6.1,0.4-11.6,3.8-16.5
			l5.9-8.1c3.8-4.9,5-10.4,3.5-16.5c-0.5-1.6-1-3.8-1.6-6.8c-0.9-3.8-0.8-7.5,0.3-11c0.4-1.5,0.9-2.5,1.5-2.9
			c0.6-0.5,1.6-0.7,3.1-0.7c0.4,0,2.6,0,6.5,0C301.8,96.8,304.1,96.8,304.3,96.8z M299.1,119c-0.1,1.7-0.1,2.6-0.1,2.6
			c0.4,4.1-0.6,7.9-2.9,11.3l-3.4,4.7c-3,4.3-4.7,6.6-4.9,6.9c-2.4,3.6-3.4,7.8-3.1,12.5c0.2,2.5,1.3,3.7,3.2,3.8
			c2.2,0.2,4.5,0.2,7.1,0c1.4-0.1,2-0.9,1.8-2.4c0-0.4-0.1-0.9-0.3-1.5c-2-5.6-1.4-11.2,1.8-16.8c1.2-2.3,2.4-4.9,3.7-8
			c1.4-3.4,1.2-7.1-0.4-11c0-0.1-0.5-0.9-1.6-2.5L299.1,119z"
            />
            <path
              d="M336.7,96.7c0.5,0,1,0,1.5,0.1c0.5,0.1,0.9,0.1,1.2,0.1s0.5,0.1,0.9,0.4c0.3,0.2,0.5,0.4,0.6,0.4c0,0,0.2,0.3,0.4,0.7
			c0.2,0.4,0.4,0.8,0.4,1c0,0.2,0.2,0.7,0.5,1.4c0.3,0.7,0.5,1.3,0.6,1.5c0.9,2.5,2.3,5.1,4.3,8c1.6,2.3,4.6,6.2,9,11.9
			c0.6,0.9,1.2,1.3,1.9,1.3c0.9,0,1.4-0.7,1.6-2.1c0.4-3.4,0.6-6.1,0.6-8c0.2-4.8,1.6-9.4,4.1-13.8c1.3-2.1,3-3,5.3-2.9
			c0.8,0,1.9,0,3.2,0c1.4,0,2.4,0,3.1,0c2.1,0.1,2.7,1.1,1.9,3.1c-0.1,0.4-0.3,0.8-0.6,1.3c-2.1,3.4-3.2,7.5-3.4,12.2
			c-0.1,1.5-0.1,2.6-0.1,3.2c-0.1,6.7-2.5,12.2-7.1,16.6c-4.6,4.5-7,6.8-7.2,6.9c-5.1,4.3-6.9,10.2-5.3,17.5c0.2,1,0.5,2,0.8,3.2
			c0.3,1.1,0.7,2.4,1.2,3.7c0.4,1.3,0.8,2.4,1,3.2c1.8,6.1,2.7,9.7,2.9,10.7c0.5,2.6,0.4,4.4-0.2,5.2c-0.6,0.8-2.3,1.2-5.1,1.2
			c-2.3,0-3.6,0-4.1,0c-1.1-0.1-1.8-0.3-2.2-0.7c-0.4-0.4-0.6-1-0.6-1.9c-0.1-1.6-0.3-3.2-0.7-4.9c-0.4-1.7-0.7-3.1-1-4.2
			c-0.3-1.1-0.8-2.7-1.5-4.9c-0.7-2.2-1.3-3.6-1.5-4.4c-0.9-2.7-1.5-5.2-1.8-7.2c-0.6-3.2-0.3-6.8,0.9-10.7c1-3.3,1.9-7.5,2.8-12.5
			c0.7-3.3-0.1-6.7-2.4-10c-1.4-2.1-3.6-5.1-6.6-9.1c-3.7-5-6-8.8-6.9-11.5c-0.5-1.4-0.8-2.4-0.9-3.1c-0.3-1.7,0.4-2.6,2.1-2.8
			c0.2,0,1.7,0,4.4,0C335.5,96.8,336.2,96.8,336.7,96.7z"
            />
          </g>
        </g>
      </svg>
    </Container>
  )
}

export default Name
