import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 85vw;
  transform: translate(-2rem, -85%);
  padding-top: 5rem;
  svg {
    enable-background: new 0 0 653.3 191.3;
    fill: rgba(0, 0, 0, 0.9);
    transform: translate(-10px, 0) rotate(-2deg);
  }
  ${media.tablet`
    width: 40vw;
    transform: translate(-2rem, -75%);
    svg {
      transform: translate(-10px, 0) rotate(-2deg);
    }
  `}
`

const Heading = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 653.3 191.3">
        <g>
          <path
            d="M38.5,54c1,0,1.7,0.2,2.1,0.5c0.4,0.4,0.5,1.1,0.4,2.1c-0.7,4.6,0.2,11,2.7,19.4c1,3.2,2.1,6.8,3.1,11
		c0.3,1.3,0.4,2.9,0.4,4.7c-0.1,1.2-0.6,2.1-1.6,2.5c-0.9,0.4-1.9,0.3-3-0.4c-0.2-0.2-0.4-0.4-0.6-0.6c-1.1-1.2-2.7-1.5-4.8-0.6
		c-4,1.9-9.5,2.3-16,1.4c-3.1-0.6-5.9-2-8.3-4.4c-2.2-2-3.8-3.9-5.2-5.7C3.8,78.3,1.2,71,0.1,62.2C-0.4,58,0.6,53.8,3,49.9
		c2.2-3.5,4-6.3,5.6-8.6c3.6-5.3,4.4-11,2.4-17.1c-0.8-2.3-1.5-4.5-2.2-6.7c-1-4.1,0-7.7,3-10.5c2.2-1.9,4.3-3.2,6.2-4.1
		c5.3-2.3,11.2-2.5,17.8-0.5c2.8,0.8,5.1,2.5,7,5.2c4.7,6.4,7.7,13.9,9.1,22.4c0.1,0.4,0.2,0.9,0.3,1.4c0.1,2.1-0.3,3.5-1,4.4
		c-0.7,0.8-2.1,1.2-4.2,1.1c-2.2,0-3.8-0.1-4.8-0.1c-1.2,0-2.1-0.2-2.5-0.7c-0.4-0.4-0.6-1.2-0.5-2.3c0.3-3.6-0.7-8.3-3-14.1
		c-2-5-5.7-7-11.1-6.1c-2.6,0.4-3.7,2-3.4,4.6c0,0.2,0.4,1.4,1,3.6c0.9,3.3,1.5,5.1,1.5,5.3c1.1,4.4,0.6,8.5-1.7,12.3
		c-2.2,3.7-3.8,6.4-5.2,8.1c-4.5,6.3-5.8,13.5-3.6,21.3c1,3.3,1.8,5.5,2.2,6.7c1.8,4.6,5.1,6.7,10,6.4c3.6-0.2,5-2.1,4.3-5.5
		c-0.1-0.6-0.4-1.5-0.7-2.7c-0.4-1.2-0.7-2-0.7-2.6c-0.6-2.1-1.1-4.1-1.6-6.2c-0.4-2.1-1.5-3.3-3.5-3.7c-1.3-0.2-2.2-0.5-2.6-1
		c-0.4-0.4-0.6-1.2-0.5-2.3c0.2-2.6,1.4-4,3.7-4.1c0.3,0,2.7,0.1,7.3,0.2l0-0.2C36,53.9,38.2,53.9,38.5,54z"
          />
          <path
            d="M80.6,2.8c4.7,0.4,8.8,2.3,12.4,5.8c4.6,4.6,8.1,9.2,10.7,13.9c1.7,3.3,2.7,6.2,2.9,8.6c0.1,2.4-0.3,4.5-1.3,6.3
		c-1,1.8-2.8,3.6-5.3,5.6c-1.1,0.9-2.4,1.8-3.8,2.7c-1.3,0.9-3,2-5,3.2s-3.2,2-4.1,2.5c-0.2,0.2-0.6,0.5-1.1,0.8
		c-0.6,0.3-0.9,0.5-1.1,0.6c-1.3,0.8-2.1,1.6-2.4,2.2c-0.3,0.7-0.3,1.8,0,3.2c1,3.3,2.3,6.3,3.6,8.9c0.8,1.3,1.9,3.1,3.5,5.6
		c1.5,2.4,2.5,3.9,2.9,4.4l6.4,10.6c0.2,0.3,0.5,0.9,0.8,1.7c0.7,1.7,0.8,2.9,0.4,3.6c-0.4,0.7-1.5,1-3.4,0.9
		c-1.3,0.1-3.4,0-6.2-0.1c-2,0-3.4-1-4-2.7c-0.4-1.1-0.8-2.2-1.2-3.2c-0.5-1-1.1-2.2-1.8-3.5c-0.8-1.3-1.4-2.3-1.7-2.8
		c-0.4-0.6-1.1-1.6-2.1-3.3c-1.1-1.6-1.6-2.6-1.7-2.9c-2.3-3.8-4.6-7.5-7-11.5c-0.3-0.6-1-2.3-2-5.2c-0.6-1.4-1.4-2-2.4-1.9
		c-1.2,0.2-1.8,1-1.8,2.3c-0.1,3.2,0,5.4,0.4,6.4c0.3,1.4,0.8,3.5,1.7,6.2c0.8,2.7,1.4,4.7,1.8,5.8c1.2,5.1,2,8.8,2.4,11.4
		c0.2,1.7-0.1,2.9-0.6,3.5c-0.6,0.6-1.8,0.9-3.7,0.8c-2.4,0-4.3,0-5.6-0.1c-1.1-0.1-1.9-0.3-2.3-0.7c-0.4-0.4-0.7-1-0.8-2.1
		c-0.1-1.5-0.3-3.2-0.7-5.1s-0.6-3.3-0.9-4.3s-0.7-2.7-1.6-5.1c-0.8-2.4-1.2-3.9-1.3-4.4c-1.4-4.4-2.1-8.1-2.1-11.3
		c0.1-3.6,1-6.9,2.9-10c2.1-3.4,3.8-6,5.2-7.8c3.8-5.4,4.5-11.3,2.4-17.8c-0.4-1-0.8-2.5-1.4-4.3c-0.6-1.9-0.9-2.9-1-3.1
		c-1-3.1-1.3-6.8-0.7-10.8c0.2-1.5,0.7-2.6,1.4-3.1C60.7,2.2,61.7,2,63.3,2c0.3,0,3.3,0.1,9,0.2C77.2,2.5,80.2,2.7,80.6,2.8z
		 M89.9,38.1c2.7-2.2,3.8-5.2,3.1-8.7c-0.7-4-2.8-7.4-6.4-10c-3.5-2.7-7.2-4-11.2-3.5c-1.3,0.1-2.2,0.4-2.5,0.8
		c-0.4,0.5-0.3,1.4,0,2.6c0.2,0.8,0.6,2,1,3.5c0.6,1.5,0.8,2.6,1,3.4c1.3,4.8,1,9.1-1.1,13.1c-0.7,1.6-1,2.7-0.8,3.1
		c0.2,0.4,1.2,0.7,3,0.8c0.1,0,0.3,0,0.5,0l0.1,0C81.5,43,85.9,41.4,89.9,38.1z"
          />
          <path
            d="M115.1,83c-3.8-5.6-6.1-12.5-7.2-20.8c-0.6-4.6,0.5-9,3.2-13.2l5.1-7.8c3.5-5.1,4.2-10.6,2.4-16.5c-0.8-2.3-1.4-4.5-2-6.6
		c-1.1-4.1-0.2-7.6,2.8-10.6c2.7-2.5,5.8-4.3,9.3-5c4.2-0.9,8.8-0.9,13.9,0c3.8,0.7,6.7,2.7,9.1,6c4.1,5.7,6.9,12.6,8.8,20.5
		c0.7,3.8,0,7.4-1.9,10.9c-0.5,0.9-1.3,2.3-2.5,4s-2,3.3-2.5,4.2c-1.9,3.3-3.2,5.7-3.9,7.5c-1.2,3-1.2,6.7,0,11.3
		c0.2,0.8,0.7,2.9,1.6,6.1c0.1,0.3,0.3,0.7,0.5,1.3s0.4,1,0.5,1.3c1.4,4.7,1,8.7-1.2,11.9c-2.2,3.3-5.8,5.1-10.7,5.6
		c-0.2,0-2,0.1-5.5,0.2C126.6,93.8,120.2,90.4,115.1,83z M123.3,73.2c0.9,3.1,2.3,5.2,4,6.3c1.7,1.1,4.2,1.6,7.3,1.5
		c3.8-0.2,5.1-2.1,4.2-5.7c-0.1-0.4-0.7-2.4-1.9-6.1c-2.4-8.1-1.2-15.3,3.7-21.8c1.1-1.7,2.8-4.3,5.1-8c1.9-3.4,2.4-6.8,1.6-10.6
		c-0.7-3.4-1.7-6.5-2.9-9.6c-1.4-3.6-4.1-5.4-7.8-5.4c-0.3,0-0.7,0-1.3,0s-1,0-1.4,0c-3.7,0.2-5,2.1-4.1,5.7c0.2,1,0.7,2.8,1.6,5.3
		c1.9,5.8,1.2,11.3-2.1,16.4l-5.4,8.3c-2.1,3.5-3.1,6.6-3.1,9.5C120.5,63.1,121.4,67.8,123.3,73.2z"
          />
          <path
            d="M207.8,45c6.4-6.4,9.1-12.9,7.8-19.6c-0.2-0.8-0.6-3.9-1.3-9.3c-0.4-3,0-6.7,1.6-11c0.6-2,2.1-2.9,4.1-2.8
		c3.7,0,6,0,6.8,0.1c2.4,0.2,3.2,1.2,2.4,3.2c-1.9,4.9-2,10.9-0.4,18.3c1.6,7-0.1,13.3-4.9,18.8c-0.6,0.7-1.5,1.9-2.9,3.4
		c-1.2,1.6-2.3,2.8-2.9,3.4c-5.2,5.9-7.2,13.1-5.9,21.9c0.1,0.7,0.3,1.8,0.4,3.3c0.3,1.5,0.4,2.8,0.6,4c0.2,1.2,0.3,2.4,0.4,3.5
		c0.2,2.6,0.3,5.2,0.1,7.9c0,1.9-0.4,3.1-1.1,3.7c-0.7,0.6-1.9,0.9-3.6,0.8c-2.7,0-5.3,0-7.7-0.2c-1.5,0-2.6-0.3-3.1-0.8
		c-0.5-0.5-0.9-1.4-1.1-2.9c-0.7-4.4-2.8-10.4-6.3-17.6c-0.1-0.1-0.7-0.7-1.7-1.9l-0.6,0.3c0.2,2,0.2,3,0.2,3.1c0.1,0.6,0.4,2,0.7,4
		c0.4,2.1,0.6,3.7,0.8,4.8c0.3,1.9,0.4,3.9,0.4,6c-0.1,1.9-0.6,3.1-1.2,3.7s-1.8,0.9-3.6,0.8c-3.1,0-5.8,0-8.1-0.2
		c-1.3,0-2.3-0.2-2.8-0.8s-0.9-1.3-1.1-2.6c-0.6-3.3-2.2-7.7-4.8-13.3c-2.5-5.4-4.2-9.2-5.2-11.7c-2.6-6.3-2.2-12.3,0.9-18.1
		c0.7-1.3,1.9-3.7,3.4-6.9c2.2-4.6,2.3-9.2,0.3-13.8c-0.6-1.2-2-4.3-4.3-9.2c-2.3-4.7-3.3-8.9-3.1-12.6c0-1.5,0.4-2.6,0.9-3.2
		c0.5-0.6,1.5-0.9,3.1-0.8c2.6,0,4.6,0,6.2,0.1c1.1,0,2,0.2,2.5,0.8c0.5,0.5,0.8,1.2,0.8,2.2c-0.1,2.2,0.1,4.4,0.8,6.7
		s1.3,4.2,2,5.7c0.7,1.5,1.7,3.5,3,6.1c0.3,0.5,0.9,1.8,1.8,3.9c2.2,4.9,2.2,9.6,0,14.2c-0.5,1.2-0.8,2.5-0.7,3.7
		c1-0.5,1.5-0.8,1.5-0.9c5.5-5.9,7.6-11.7,6.3-17.7c-0.7-4-1.3-7.4-2-10.2c-0.6-3.5-0.3-7.2,1-11c0.3-1.2,0.8-2.1,1.5-2.6
		c0.6-0.5,1.5-0.7,2.8-0.6c2.3-0.1,4.5,0,6.7,0.1c1.2,0,2.1,0.3,2.6,0.9c0.5,0.5,0.7,1.4,0.5,2.6c-0.3,4.5,0.9,9.8,3.7,15.7
		c0.6,1.4,1.4,3.5,2.5,6.3c2,4.8,1.8,9.4-0.6,13.9c-0.1,0.1-0.7,1.5-1.8,4.2l0.6,0.5C207.3,45.4,207.7,45.1,207.8,45z"
          />
          <path
            d="M277.7,2.3c1.2,0,2,0,2.5,0.1c1.5,0.3,2.3,1.2,2.2,2.8c-0.1,2.4-0.2,5.1-0.5,8.1c-0.1,0.9-0.4,1.5-0.8,1.8
		c-0.4,0.3-1.1,0.5-2.1,0.5c-0.1,0.1-1.6,0.1-4.5,0s-4.4-0.1-4.5-0.1c-2.5-0.1-3.9,0.2-4.3,0.8c-0.4,0.6-0.2,2,0.5,4.1
		c0.9,3.3,1.7,5.8,2.1,7.7c0.9,3.9,0.4,7.6-1.6,11.1c-2,3.5-3.6,6.1-5,7.8c-4.9,6.6-6.1,14.3-3.6,22.7c2.3,6.8,3.4,10.7,3.5,11.3
		c0.7,3,1.3,5.9,1.5,8.9c0.1,1.4-0.2,2.4-0.7,3s-1.5,0.8-3.1,0.7c-2,0.1-4,0-5.8-0.1c-1.3,0-2.2-0.2-2.8-0.7
		c-0.5-0.4-0.8-1.2-0.7-2.5c-0.1-3.5-1.3-8.8-3.5-16c-1.1-3.5-2-6.6-2.6-9.5c-1.2-5.6-0.3-10.8,2.8-15.4c2.6-4,4.2-6.6,5.1-7.9
		c3.7-5.3,4.4-11,2.4-17.2l-1.9-5.7c-0.8-2.7-2.7-4-5.4-3.9c-1.6,0-4.8-0.1-9.3-0.2c-1.9-0.1-3.3-0.6-4-1.2c-0.7-0.7-1.1-1.9-1-3.8
		c0-1.5,0.3-3.2,0.6-4.9c0.2-1.3,0.6-2.2,1.2-2.8c0.5-0.6,1.5-0.8,2.8-0.7c0.6,0,3.5,0.1,8.6,0.2c1.4,0,5.6,0.1,12.4,0.3
		C269.2,2.1,275.7,2.3,277.7,2.3z"
          />
          <path
            d="M317.4,40.1c2.1,0,3.6-0.9,4.7-3c1.4-2.8,1.9-5.4,1.4-8c-0.2-0.8-0.9-3.8-2.3-8.9c-0.2-0.5-0.5-1.3-0.7-2.5
		c-0.4-1.1-0.7-2.1-0.8-3c-0.2-0.9-0.4-1.7-0.4-2.4c-0.3-2.1-0.2-4.8,0.4-8.2c0.2-2.1,1.4-3.1,3.5-3.1c2.4,0,4.7,0,7.1,0.2
		c2.2,0.1,3,1.3,2.5,3.3c-0.9,4.5-0.2,10.1,2.1,16.7c0.4,0.8,0.9,2.6,1.6,5.2c1.3,4.6,0.8,8.9-1.7,13c-2.2,3.6-3.7,6-4.8,7.5
		c-4.8,6.6-6.1,13.9-3.6,21.8c0.1,0.3,0.6,1.9,1.4,4.6c0.8,2.8,1.5,4.9,1.9,6.5c0.8,3.2,1.5,6.2,1.8,8.9c0.2,1.6-0.1,2.8-0.6,3.5
		c-0.6,0.7-1.7,1-3.5,0.9c-2.4,0-4.4,0-6.2-0.1c-1-0.1-1.8-0.4-2.2-0.8c-0.5-0.4-0.7-1.2-0.7-2.3c-0.1-3.6-1-8.1-2.8-13.5
		c-1.1-3.6-1.9-5.7-2.1-6.3c-1.5-5-2.1-9.5-1.7-13.3c0.2-1.2,0.1-2.1-0.2-2.5c-0.4-0.5-1.2-0.7-2.5-0.8c-3.7-0.2-7.1-0.3-10.2-0.2
		c-2.8,0-4.2,1.4-4.3,4.1c-0.2,3.2,0.1,6,0.6,8.5c0.3,1.4,0.8,3.5,1.7,6.1c0.8,2.6,1.4,4.4,1.7,5.3c0.5,2,1.2,5.3,2.3,10.1
		c0.2,0.7,0.3,1.5,0.1,2.5c-0.2,1.8-1.1,2.6-2.6,2.5c-3.1,0-5.9,0-8.3-0.2c-1.5,0-2.4-1-2.4-2.7c0-1.1-0.1-2.2-0.1-3.3
		c-0.2-1.1-0.4-2.3-0.8-3.7c-0.4-1.4-0.6-2.4-0.7-3c-0.2-0.6-0.5-1.8-1.1-3.6c-0.6-1.8-0.9-2.9-1-3.2c-0.9-3.3-1.9-6.7-2.6-10.4
		c-0.8-4.4,0-8.7,2.6-12.9c1.7-3.1,3.5-5.9,5.4-8.5c3.9-5.4,4.7-11.5,2.4-18c-1.5-4.6-2.6-8.5-3.2-11.5c-0.2-1.2-0.1-4,0.4-8.2
		c0.2-1.9,1.3-2.8,3.2-2.8c2.9,0,5.4,0,7.7,0.2c1.6,0.1,2.5,1.1,2.4,2.9c0,0.3-0.1,0.7-0.2,1.2c-0.7,3.8-0.4,7.8,0.9,11.9
		c0.2,0.6,0.5,1.5,0.7,2.5c0.4,1.1,0.7,2.1,0.9,2.9c0.3,0.9,0.6,1.7,0.8,2.5c1.2,4.3,1.1,8.3-0.3,12c-0.5,1.3-0.6,2.2-0.5,2.6
		c0.3,0.4,1.2,0.7,2.7,0.9c0.1,0,0.3,0,0.6,0l4.3,0.1l0-0.2C315.5,40,317.1,40.1,317.4,40.1z"
          />
          <path
            d="M390.5,40.5c2.1,0,3.6-0.9,4.7-3c1.4-2.8,1.8-5.4,1.4-8c-0.2-0.8-0.9-3.7-2.3-8.9c-0.2-0.5-0.5-1.3-0.7-2.5
		c-0.4-1.1-0.7-2.1-0.8-3c-0.2-0.9-0.4-1.7-0.4-2.4c-0.3-2.1-0.2-4.8,0.4-8.2c0.2-2,1.4-3.1,3.5-3.1c2.4,0,4.7,0,7.1,0.2
		c2.2,0.1,3,1.3,2.5,3.3c-0.9,4.5-0.2,10,2.1,16.6c0.4,0.8,0.9,2.6,1.6,5.2c1.3,4.6,0.8,8.9-1.7,12.9c-2.2,3.6-3.7,6-4.8,7.4
		c-4.8,6.6-6,13.8-3.6,21.7c0.1,0.3,0.6,1.8,1.4,4.6c0.8,2.8,1.5,4.9,1.9,6.5c0.8,3.2,1.5,6.1,1.8,8.9c0.2,1.6-0.1,2.8-0.6,3.5
		c-0.6,0.7-1.7,1-3.5,0.9c-2.4,0-4.4,0-6.1-0.1c-1-0.1-1.8-0.4-2.2-0.8c-0.5-0.4-0.7-1.2-0.7-2.3c-0.1-3.6-1-8-2.8-13.5
		c-1.1-3.6-1.8-5.7-2.1-6.3c-1.5-5-2.1-9.4-1.7-13.2c0.2-1.2,0.1-2.1-0.2-2.5c-0.4-0.5-1.2-0.7-2.5-0.8c-3.7-0.2-7.1-0.3-10.1-0.2
		c-2.8,0-4.2,1.4-4.3,4.1c-0.2,3.2,0.1,6,0.6,8.5c0.3,1.4,0.8,3.5,1.7,6.1c0.8,2.6,1.4,4.4,1.7,5.3c0.5,1.9,1.2,5.3,2.3,10.1
		c0.2,0.7,0.3,1.5,0.1,2.5c-0.2,1.7-1.1,2.6-2.6,2.5c-3.1,0-5.8,0-8.3-0.2c-1.5,0-2.4-1-2.4-2.7c0-1.1-0.1-2.2-0.1-3.3
		c-0.2-1.1-0.4-2.3-0.8-3.7c-0.4-1.4-0.6-2.4-0.7-3c-0.2-0.6-0.5-1.8-1.1-3.6c-0.6-1.8-0.9-2.8-1-3.2c-0.9-3.3-1.9-6.7-2.6-10.4
		c-0.8-4.4,0-8.7,2.6-12.8c1.7-3.1,3.5-5.8,5.4-8.5c3.9-5.4,4.7-11.4,2.4-18c-1.5-4.6-2.6-8.5-3.2-11.5c-0.2-1.2-0.1-3.9,0.4-8.1
		c0.2-1.8,1.3-2.8,3.2-2.8c2.9,0,5.4,0,7.7,0.2c1.6,0.1,2.5,1.1,2.4,2.9c0,0.3-0.1,0.7-0.2,1.2c-0.7,3.8-0.4,7.7,0.9,11.8
		c0.2,0.6,0.5,1.5,0.7,2.5c0.4,1.1,0.7,2.1,0.9,2.9c0.3,0.9,0.6,1.7,0.8,2.5c1.2,4.3,1.1,8.3-0.3,12c-0.5,1.3-0.6,2.2-0.5,2.6
		c0.3,0.4,1.2,0.7,2.7,0.9c0.1,0,0.3,0,0.6,0l4.3,0.1l0,0C388.6,40.5,390.3,40.5,390.5,40.5z"
          />
          <path
            d="M441.8,0.3c1.4,0,2.4,0.3,2.9,0.8c0.5,0.5,0.7,1.4,0.6,2.7c-0.3,4.5,0.8,9.5,3.4,15.1c1.2,3,2.4,6,3.4,8.9
		c1.1,3.6,0.9,7.2-0.7,10.7c-1.8,3.5-3.1,6.2-4.4,8.3c-3.6,6.1-4.2,12.6-1.5,19.5c0.5,1.2,1.2,3.1,2.4,5.8c1,2.6,1.9,4.5,2.4,5.8
		c0.8,2.2,1.9,5.5,3.2,10c0.2,0.5,0.4,1.4,0.4,2.6c0,1.7-0.8,2.5-2.3,2.6c-2.7,0-5.4,0-8-0.2c-1.3-0.1-2.2-1.1-2.5-3
		c-0.5-2.4-1-4.1-1.3-5.3c-0.6-2.5-2.3-3.8-4.9-3.8c-4.8-0.1-7.3-0.2-7.4-0.2c-1.2,0.1-2.1,0.4-2.5,0.7c-0.4,0.4-0.6,1.2-0.6,2.5
		c0,0.2,0,1.1,0,2.8c0.1,1.7-0.1,2.6-0.2,2.8c-0.1,1-0.4,1.8-0.8,2.2c-0.4,0.5-1.2,0.7-2.3,0.7c-1.2,0.1-3.4,0-6.3-0.1
		c-1.8,0-2.6-0.8-2.5-2.3c0.3-2.5,0.2-5.1-0.1-7.8c-0.3-2.7-0.9-5.7-1.6-8.9c-0.7-3.2-1.2-5.7-1.4-7.6c-0.8-6.4,0.7-12.2,4.4-17.2
		l6.4-8.4c4.1-5.1,5.5-10.8,4.1-17.2c-0.5-1.7-1-4-1.5-7.1c-0.8-4-0.6-7.9,0.5-11.6c0.4-1.5,1-2.6,1.6-3.1s1.7-0.8,3.2-0.7
		c0.4,0,2.7,0.1,6.8,0.1C439.2,0.2,441.6,0.3,441.8,0.3z M435.9,23.6c-0.1,1.8-0.3,2.7-0.3,2.8c0.3,4.3-0.8,8.3-3.4,11.8l-3.7,4.9
		c-3.3,4.4-5,6.9-5.3,7.2c-2.6,3.7-3.8,8.1-3.5,13.1c0.1,2.6,1.2,3.9,3.3,4.1c2.3,0.2,4.7,0.3,7.4,0.2c1.4-0.1,2.1-0.9,2-2.5
		c0-0.4-0.1-0.9-0.3-1.5c-2-5.9-1.1-11.8,2.3-17.6c1.3-2.4,2.6-5.1,4.1-8.3c1.5-3.6,1.5-7.5-0.3-11.6c0-0.1-0.6-1-1.6-2.6
		L435.9,23.6z"
          />
          <path
            d="M490,29.4c-0.6-0.4-1.3-2-1.9-4.9c-0.4-1.6-1.2-3.8-2.4-6.9c-0.8-2.4-2.4-4.1-4.5-5c-2-0.8-3.7-0.8-5,0.4
		c-1.3,1-1.7,2.6-1,4.6l2.1,6.8c1.7,5.3,1,10.4-2,15.1l-5.2,8.1c-3.9,5.7-5,11.9-3.2,18.8c0.6,2.8,1.5,5.7,2.5,8.6
		c0.8,2.5,2.4,4.3,4.6,5.3c2,0.9,3.7,1,5-0.1c1.4-0.9,1.8-2.5,1.2-4.7c-0.2-0.6-0.6-1.8-1.1-3.6c-0.6-1.8-0.9-3.2-1.1-4.2
		c-0.4-1.5-0.3-2.5,0.1-3.1c0.4-0.6,1.4-0.9,3-0.9c1.7,0,3.2,0.1,4.7,0.1c1.5,0.1,2.5,0.5,3.1,0.9c0.6,0.4,1.1,1.3,1.4,2.7l2.5,8.7
		c1.4,4.5,1,8.4-1.1,11.7c-2.1,3.3-5.5,5.2-10.2,5.8c-5.8,0.8-10.8-0.6-15-4.4c-3.6-3-6.3-6.5-8.1-10.6c-2.3-5.3-3.8-10.6-4.6-15.8
		c-0.6-4.6,0.2-9,2.5-13c0.2-0.5,1.3-2.3,3.2-5.2l3.4-5.2c2.9-4.6,3.6-9.5,2-14.6l-2.2-7.1c-1.6-5.2-0.2-9.6,4.1-12.9
		c4-3.1,8.3-4.7,13-4.6c5.2,0.1,9.4,2.1,12.5,6.1c4.1,5.5,7,12.5,9,20.8c0.3,1.3,0.3,2.1-0.3,2.7c-0.4,0.5-1.3,0.8-2.7,0.8
		c-0.1,0-1,0-2.8-0.1C492.4,30.1,490.6,29.9,490,29.4z"
          />
          <path
            d="M528.7,30.9c1.3-2.8,2.3-5.3,3.1-7.4c0.2-0.7,0.8-2.7,1.5-6c1.1-4.6,3.6-9.2,7.4-13.9c1.1-1.4,2.5-2.1,4.1-2.2
		c3.8,0,6.7,0,8.7,0.2c2.3,0.1,2.9,1.3,1.6,3.2c-0.1,0.3-0.6,1-1.4,2c-2.5,3.3-4.2,7.2-5.3,11.6c-0.7,2.8-1.2,4.8-1.5,6
		c-1.3,5.2-3.9,9.5-7.7,12.8c-5.8,5.3-7.4,12.3-4.6,21.2c0.7,2.1,2.5,5.5,5.5,9.9c1,1.4,2.4,3.5,4.4,6c1.9,2.5,3.3,4.4,4.1,5.5
		c2.2,3.3,4.2,6.4,5.8,9.3c1.3,2.7,0.6,4-2.4,4c-0.6,0-3-0.1-7.1-0.2c-1.8,0-3.4-1.1-4.5-3c-2.3-4.1-4.8-8.1-7.5-12.2
		c-0.7-1-1.8-2.5-3.4-4.6s-2.7-3.7-3.4-4.8c-0.4-0.6-2.4-3.9-5.9-9.8c-1.1-1.9-2.4-2.2-3.7-0.6c-2.5,2.6-3.2,5.4-2.3,8.4
		c0.1,0.3,0.6,1.7,1.3,4.1c0.7,2.5,1.3,4.4,1.7,5.7c1.3,5.1,2.1,8.8,2.4,11.3c0.3,1.8,0.1,3.1-0.5,3.7c-0.6,0.6-1.8,0.9-3.8,0.8
		c-1.1,0-3.2-0.1-6.1-0.1c-1.7-0.1-2.7-1.1-2.7-2.9c0-1.5-0.1-3.2-0.5-5.1c-0.4-1.9-0.7-3.4-1-4.5c-0.4-1.1-0.8-2.8-1.6-5.1
		c-0.8-2.3-1.2-3.8-1.5-4.5c-0.7-2.2-1.2-4.4-1.6-6.4c-0.9-5.4-0.1-10.2,2.6-14.3c2.2-3.7,3.9-6.3,5.3-8.2c3.9-5.4,4.7-11.4,2.4-18
		c-0.5-1.6-1.3-4.2-2.4-7.9c-1-3.2-1.3-6.8-0.6-10.9c0.2-1.3,0.6-2.3,1.3-2.8c0.6-0.6,1.6-0.8,2.9-0.7c2.3-0.1,4.3,0,6.1,0.1
		c1.2,0,2.1,0.2,2.5,0.8c0.5,0.5,0.6,1.3,0.3,2.5c-0.8,4.3,0,10,2.4,17.3c0.1,0.2,0.8,3.4,2.1,9.7c0,0.1,0.2,0.8,0.6,2.1
		C527.1,33.1,528.1,32.4,528.7,30.9z"
          />
          <path
            d="M567.3,92.2c-1.6,0-2.7-0.3-3.2-0.8c-0.5-0.5-0.8-1.6-0.9-3.3c-0.5-3.2-1.4-7.3-2.6-12.6c-0.3-1.1-0.8-2.9-1.6-5.4
		c-0.7-2.5-1.3-4.4-1.6-5.7c-1.4-6.2-0.3-11.9,3.2-17.1l5.1-7.6c3.2-4.7,3.9-9.9,2.1-15.5l-2.9-9c-1-3.3-1.3-7-0.6-11.2
		c0.2-1.3,0.6-2.3,1.3-2.8c0.6-0.6,1.6-0.8,3-0.7c8.4,0.1,17.3,0.3,26.6,0.6c1.4,0,2.4,0.3,2.8,0.8c0.4,0.5,0.6,1.5,0.5,3
		c-0.3,2.6-0.4,4.7-0.4,6.5c0,1.2-0.2,2.1-0.6,2.5c-0.4,0.4-1.2,0.6-2.4,0.5c-0.9,0.1-2.9,0-6.1,0c-3.2-0.1-5.2-0.2-6.2-0.2
		c-2.2,0.1-3.4,0.4-3.8,1c-0.4,0.6-0.3,1.9,0.4,4c1,3.3,1.7,5.2,1.9,5.7c1.2,4.3,1.1,8.3-0.5,12c-0.5,1.4-0.7,2.4-0.4,2.8
		c0.3,0.5,1.2,0.7,2.8,0.8c1,0,2.6,0.1,4.8,0.1c2.2,0,3.8,0.1,4.9,0.1c1.2,0.1,2,0.4,2.3,0.8c0.3,0.4,0.2,1.2-0.5,2.3
		c-0.3,0.5-2,3.2-5.1,8c-0.8,1.4-2.4,2.1-4.6,2.1c-1.2,0-2.8-0.1-4.8-0.1c-2,0-3.5-0.1-4.8-0.1c-1.8,0.1-3.2,0.4-3.9,1.1
		c-0.8,0.7-1.2,1.9-1.3,3.8c-0.1,2.4,0.2,4.6,0.7,6.6c0,0.3,0.9,3.7,2.9,10.4c0.7,2.6,2.6,3.9,5.4,3.9c5.8,0.1,10,0.2,12.5,0.3
		c1.7,0,3,0.4,3.7,0.9c0.7,0.6,1.2,1.7,1.4,3.5c0.4,1.6,0.6,3.1,0.7,4.5c0.1,1.8-0.2,3.1-0.7,3.6S595,93,593.2,93
		c-0.7,0-5.1-0.1-13.1-0.3C572.2,92.3,567.9,92.2,567.3,92.2z"
          />
          <path
            d="M626.9,1.1c4.8,0.4,9,2.4,12.6,5.9c4.7,4.7,8.3,9.4,10.9,14.2c1.8,3.3,2.8,6.3,2.9,8.8c0.1,2.5-0.3,4.6-1.3,6.4
		c-1,1.8-2.9,3.7-5.4,5.7c-1.1,0.9-2.4,1.8-3.9,2.8s-3.1,2-5.1,3.2c-1.9,1.2-3.3,2-4.2,2.5c-0.2,0.2-0.6,0.5-1.2,0.8s-1,0.5-1.2,0.6
		c-1.3,0.8-2.2,1.6-2.5,2.2c-0.3,0.7-0.3,1.8,0.1,3.3c1,3.4,2.3,6.4,3.7,9.1c0.8,1.3,1.9,3.2,3.5,5.7s2.5,3.9,2.9,4.4l6.5,10.8
		c0.2,0.3,0.5,0.9,0.8,1.7c0.7,1.8,0.8,3,0.4,3.7c-0.4,0.7-1.6,1-3.4,0.9c-1.3,0.1-3.5,0-6.4-0.1c-2.1,0-3.5-1-4.1-2.7
		c-0.4-1.1-0.9-2.2-1.2-3.3s-1.1-2.2-1.8-3.6c-0.8-1.3-1.4-2.3-1.7-2.9c-0.4-0.6-1.1-1.7-2.1-3.3c-1.1-1.7-1.7-2.6-1.8-2.9
		c-2.4-3.8-4.7-7.6-7.1-11.7c-0.3-0.6-0.9-2.4-2.1-5.3c-0.6-1.4-1.5-2.1-2.5-2c-1.2,0.2-1.9,1-1.9,2.3c-0.1,3.3,0.1,5.5,0.4,6.5
		c0.3,1.5,0.8,3.6,1.7,6.4c0.8,2.8,1.4,4.8,1.8,5.9c1.2,5.2,2,9,2.4,11.6c0.2,1.8-0.1,2.9-0.6,3.6c-0.6,0.6-1.8,0.9-3.8,0.8
		c-2.5,0-4.4,0-5.7-0.1c-1.1-0.1-1.9-0.3-2.3-0.7c-0.4-0.4-0.7-1.1-0.8-2.1c-0.1-1.5-0.3-3.3-0.7-5.2c-0.4-1.9-0.7-3.4-0.9-4.3
		c-0.3-1-0.7-2.7-1.6-5.2c-0.8-2.5-1.3-4-1.4-4.5c-1.5-4.4-2.1-8.3-2.1-11.5c0.1-3.6,1-7,3-10.1c2.2-3.5,3.9-6.1,5.3-8
		c3.8-5.5,4.6-11.5,2.4-18.2c-0.4-1-0.8-2.5-1.5-4.4s-0.9-3-1-3.2c-1-3.2-1.4-6.9-0.7-11c0.2-1.6,0.7-2.6,1.4-3.2s1.8-0.9,3.3-0.8
		c0.3,0,3.4,0.1,9.2,0.2C623.5,0.7,626.5,0.9,626.9,1.1z M636.3,37c2.9-2.3,3.9-5.3,3.1-8.9c-0.7-4-2.9-7.5-6.5-10.2
		c-3.5-2.8-7.3-4-11.4-3.6c-1.3,0.1-2.2,0.4-2.6,0.8c-0.4,0.5-0.3,1.4,0,2.6c0.2,0.8,0.6,2,1.1,3.6c0.6,1.6,0.9,2.7,1.1,3.4
		c1.4,4.9,1,9.3-1.1,13.3c-0.7,1.7-1.1,2.7-0.8,3.2s1.3,0.7,3,0.8c0.1,0,0.3,0,0.5,0l0.2,0C627.8,42,632.2,40.4,636.3,37z"
          />
          <path
            d="M44.4,173.6c-0.1,5.8-0.3,9.9-0.6,12c-0.1,1.5-0.6,2.6-1.3,3.2c-0.7,0.6-1.9,0.9-3.5,0.8c-2.4,0-4.5,0-6.5-0.1
		c-1.3,0-2.3-0.2-2.8-0.7c-0.6-0.4-1-1.2-1.2-2.5c-0.5-1.9-1.2-4-2.1-6c-1-2-1.7-3.8-2.5-5.3c-0.8-1.4-1.8-3.3-3.2-5.7
		c-1.4-2.3-2.4-4.1-3-5.3c-0.3-0.6-1-1-2-1.2c0.3,1.9,0.4,3,0.4,3.1c0.2,0.7,0.7,2.1,1.3,4.2s1.1,3.7,1.4,4.9c0.9,4.1,1.5,7.4,2,10
		c0.1,1.5-0.2,2.6-0.7,3.1c-0.5,0.5-1.5,0.8-3.1,0.8c-2.3,0.1-4.4,0-6.5-0.1c-1.1-0.1-1.9-0.3-2.3-0.7C8,187.7,7.8,187,7.8,186
		c-0.1-1.5-0.3-3.2-0.7-5.1c-0.4-1.8-0.7-3.4-1.1-4.6c-0.4-1.2-0.8-2.9-1.5-5c-0.7-2.1-1.1-3.7-1.4-4.7c-0.8-3-1.5-6.2-1.8-9.5
		c-0.5-3.9,0.4-7.7,2.7-11.3c2.3-3.7,3.9-6.3,5.1-7.9c4.1-5.6,5-11.9,2.6-18.5c-0.4-1.2-1.2-3.7-2.4-7.6c-0.9-3.1-1.2-6.6-0.6-10.6
		c0.2-1.5,0.7-2.6,1.3-3.2s1.7-0.8,3.4-0.7c2.5,0,4.5,0,6,0.1c1.3,0,2.2,0.2,2.7,0.7c0.5,0.4,0.7,1.2,0.8,2.3
		c0.2,4.6,2.4,10.5,6.5,17.5c1,1.6,2,3.6,3.1,6.1c2.1,4.3,2.2,8.6,0.3,12.9l-3,7.4c-1.3,3.3-1.8,6.2-1.6,8.6
		c0.1,1.6,0.6,2.7,1.6,3.2c0.8-1.2,1.3-1.8,1.4-1.8c1.3-3.5,4-7.4,8.1-11.8l5.3-5.9c4.4-4.7,6.1-10.6,5.3-17.4
		c-0.1-1.1-0.2-3.4-0.5-6.8c-0.3-3.5,0.6-7.4,2.6-11.7c0.7-1.7,2-2.6,3.8-2.5c3.1,0,5.4,0,7.1,0.2c2.2,0.1,3.1,1.3,2.7,3.5
		c-0.9,4.3-0.3,9.5,1.9,15.5c0.7,1.8,1.3,4,1.9,6.3c1.2,4.5,0.7,8.8-1.7,12.8c-2.2,3.6-3.7,6.1-5,7.6c-4.7,6.5-5.9,13.7-3.6,21.4
		c0.2,0.6,0.8,2.3,1.6,4.9c0.8,2.7,1.5,4.7,1.9,6.2c0.8,3.3,1.5,6.4,1.8,9.2c0.2,1.5-0.1,2.6-0.6,3.3c-0.6,0.7-1.7,1-3.3,0.9
		c-2.5,0-4.6,0-6.5-0.1c-1-0.1-1.7-0.4-2.2-0.8c-0.4-0.4-0.6-1.1-0.6-2.2c-0.2-4.3-1.7-10.4-4.5-18.3c0-0.1-0.1-0.3-0.2-0.7
		c-0.2-0.4-0.3-0.6-0.3-0.6c-0.1-0.1-0.2-0.2-0.4-0.5s-0.4-0.4-0.7-0.3s-0.6,0.1-1,0C44.3,171,44.4,173.4,44.4,173.6z"
          />
          <path
            d="M101.4,97.8c1.5,0,2.4,0.3,2.9,0.8s0.7,1.4,0.6,2.7c-0.3,4.6,0.8,9.6,3.4,15.2c1.3,3,2.4,6.1,3.4,9
		c1.2,3.6,0.9,7.2-0.7,10.8c-1.8,3.5-3.2,6.2-4.4,8.3c-3.6,6.1-4.2,12.7-1.5,19.7c0.5,1.2,1.3,3.2,2.4,5.9c1,2.6,1.9,4.5,2.4,5.9
		c0.8,2.2,1.9,5.5,3.2,10.1c0.2,0.5,0.4,1.4,0.4,2.7c0,1.7-0.8,2.6-2.3,2.7c-2.7,0-5.4,0-8.1-0.2c-1.4-0.1-2.2-1.1-2.6-3.1
		c-0.5-2.4-1-4.2-1.3-5.3c-0.6-2.5-2.3-3.8-4.9-3.8c-4.9-0.1-7.4-0.2-7.5-0.2c-1.3,0.1-2.1,0.4-2.5,0.7c-0.4,0.4-0.6,1.3-0.6,2.5
		c0,0.2,0,1.1,0,2.8c0.1,1.7-0.1,2.6-0.2,2.8c-0.1,1-0.4,1.8-0.8,2.3c-0.4,0.5-1.2,0.7-2.3,0.7c-1.2,0.1-3.4,0-6.4-0.1
		c-1.8,0-2.6-0.8-2.5-2.3c0.3-2.5,0.2-5.1-0.1-7.9c-0.3-2.8-0.9-5.7-1.6-9c-0.7-3.2-1.2-5.8-1.4-7.6c-0.8-6.4,0.7-12.3,4.5-17.4
		l6.4-8.5c4.2-5.1,5.5-10.9,4.1-17.4c-0.5-1.7-1-4.1-1.5-7.2c-0.8-4.1-0.6-8,0.5-11.7c0.4-1.6,1-2.6,1.7-3.1
		c0.6-0.5,1.7-0.8,3.3-0.7c0.4,0,2.7,0.1,6.9,0.1C98.8,97.7,101.2,97.8,101.4,97.8z M95.5,121.2c-0.1,1.8-0.3,2.7-0.3,2.8
		c0.3,4.4-0.8,8.4-3.4,11.9l-3.7,4.9c-3.3,4.5-5,6.9-5.3,7.2c-2.6,3.8-3.8,8.2-3.6,13.2c0.1,2.6,1.3,4,3.3,4.2
		c2.3,0.2,4.8,0.3,7.5,0.2c1.5-0.1,2.1-0.9,2-2.5c0-0.4-0.1-0.9-0.3-1.6c-2-5.9-1.2-11.9,2.3-17.8c1.4-2.4,2.6-5.1,4.1-8.3
		c1.6-3.6,1.5-7.5-0.3-11.7c0-0.1-0.6-1-1.6-2.7L95.5,121.2z"
          />
          <path
            d="M142.7,97.3c4.7,0.4,9,2.4,12.6,5.9c4.6,4.6,8.2,9.4,10.8,14.1c1.8,3.3,2.8,6.3,2.9,8.8c0.1,2.5-0.3,4.6-1.3,6.4
		c-1,1.8-2.9,3.6-5.4,5.7c-1.1,0.9-2.4,1.8-3.9,2.8c-1.4,1-3,2-5,3.2c-1.9,1.2-3.3,2-4.2,2.5c-0.2,0.2-0.6,0.5-1.2,0.8
		c-0.6,0.3-0.9,0.5-1.2,0.6c-1.3,0.8-2.2,1.6-2.5,2.2c-0.3,0.7-0.3,1.8,0.1,3.2c1,3.4,2.3,6.4,3.7,9.1c0.8,1.3,1.9,3.2,3.5,5.7
		c1.5,2.4,2.5,3.9,2.9,4.4l6.4,10.8c0.2,0.3,0.5,0.9,0.8,1.7c0.7,1.8,0.8,3,0.4,3.6c-0.4,0.7-1.5,1-3.4,0.9c-1.3,0.1-3.4,0-6.3-0.1
		c-2.1,0-3.4-1-4.1-2.7c-0.4-1.1-0.8-2.2-1.2-3.2c-0.5-1-1.1-2.2-1.8-3.6c-0.8-1.3-1.4-2.3-1.7-2.9c-0.4-0.6-1.1-1.7-2.1-3.3
		c-1.1-1.6-1.7-2.6-1.8-2.9c-2.4-3.8-4.7-7.6-7.1-11.6c-0.3-0.6-0.9-2.4-2.1-5.3c-0.6-1.4-1.4-2.1-2.5-2c-1.2,0.2-1.9,1-1.9,2.3
		c-0.1,3.3,0.1,5.5,0.4,6.5c0.3,1.4,0.8,3.5,1.7,6.3c0.8,2.8,1.4,4.7,1.8,5.9c1.2,5.1,2,9,2.4,11.5c0.2,1.8-0.1,2.9-0.6,3.5
		c-0.6,0.6-1.8,0.9-3.8,0.8c-2.5,0-4.4,0-5.7-0.1c-1.1-0.1-1.9-0.3-2.3-0.7c-0.4-0.4-0.7-1.1-0.8-2.1c-0.1-1.5-0.3-3.3-0.7-5.2
		c-0.4-1.9-0.6-3.3-0.9-4.3c-0.3-1-0.7-2.7-1.6-5.2c-0.8-2.5-1.3-4-1.4-4.5c-1.4-4.4-2.1-8.2-2.1-11.4c0.1-3.6,1-7,3-10.1
		c2.2-3.5,3.9-6.1,5.3-7.9c3.8-5.5,4.6-11.5,2.4-18.1c-0.4-1-0.8-2.5-1.4-4.4c-0.6-1.9-0.9-3-1-3.2c-1-3.2-1.3-6.8-0.7-11
		c0.2-1.5,0.7-2.6,1.4-3.2c0.7-0.6,1.8-0.8,3.3-0.7c0.3,0,3.3,0.1,9.1,0.2C139.3,97,142.3,97.2,142.7,97.3z M152.2,133.1
		c2.9-2.3,3.9-5.3,3.1-8.9c-0.7-4-2.8-7.5-6.4-10.1c-3.5-2.8-7.3-4-11.3-3.6c-1.3,0.1-2.2,0.4-2.6,0.8c-0.4,0.5-0.3,1.4,0,2.6
		c0.2,0.8,0.6,2,1.1,3.5c0.6,1.5,0.8,2.7,1.1,3.4c1.3,4.8,1,9.3-1.1,13.3c-0.7,1.7-1,2.7-0.8,3.2c0.3,0.5,1.3,0.7,3,0.8
		c0.1,0,0.3,0,0.5,0l0.2,0C143.6,138.1,148.1,136.5,152.2,133.1z"
          />
          <path
            d="M199.4,127.3c1.3-2.8,2.3-5.3,3.1-7.5c0.2-0.7,0.8-2.7,1.5-6c1.1-4.6,3.6-9.3,7.4-14c1.1-1.4,2.5-2.1,4.2-2.2
		c3.8,0,6.7,0,8.8,0.2c2.4,0.2,2.9,1.3,1.6,3.2c-0.1,0.3-0.6,1-1.4,2c-2.5,3.3-4.3,7.2-5.3,11.6c-0.7,2.8-1.2,4.8-1.5,6
		c-1.3,5.2-3.9,9.5-7.7,12.9c-5.9,5.4-7.5,12.4-4.7,21.4c0.7,2.2,2.5,5.6,5.5,10c1,1.4,2.4,3.5,4.5,6c1.9,2.5,3.3,4.4,4.1,5.6
		c2.3,3.4,4.3,6.5,5.8,9.4c1.3,2.7,0.6,4.1-2.4,4.1c-0.6,0-3-0.1-7.1-0.2c-1.9,0-3.4-1.1-4.5-3c-2.3-4.1-4.8-8.1-7.6-12.2
		c-0.7-1-1.8-2.6-3.5-4.7c-1.6-2-2.7-3.7-3.5-4.8c-0.4-0.6-2.4-4-6-9.8c-1.1-2-2.4-2.2-3.7-0.6c-2.5,2.6-3.2,5.4-2.4,8.5
		c0.1,0.3,0.6,1.7,1.3,4.2c0.7,2.5,1.3,4.4,1.8,5.7c1.3,5.1,2.1,8.9,2.4,11.4c0.3,1.8,0.1,3.1-0.5,3.7c-0.6,0.6-1.8,0.9-3.8,0.8
		c-1.1,0-3.2-0.1-6.2-0.1c-1.8-0.1-2.7-1.1-2.7-2.9c0-1.5-0.1-3.3-0.5-5.2c-0.4-1.9-0.7-3.4-1.1-4.6c-0.4-1.1-0.8-2.9-1.6-5.2
		c-0.8-2.3-1.2-3.8-1.5-4.6c-0.7-2.3-1.2-4.4-1.6-6.5c-0.9-5.5-0.1-10.3,2.6-14.4c2.3-3.7,4-6.4,5.3-8.2c3.9-5.5,4.7-11.5,2.4-18.1
		c-0.5-1.6-1.3-4.3-2.4-8c-1-3.2-1.3-6.8-0.6-11c0.2-1.3,0.6-2.3,1.3-2.9c0.6-0.6,1.6-0.8,2.9-0.7c2.3-0.1,4.3,0,6.2,0.1
		c1.2,0,2.1,0.2,2.5,0.8c0.5,0.5,0.6,1.3,0.3,2.5c-0.8,4.3,0,10.1,2.4,17.4c0.1,0.2,0.8,3.4,2.1,9.7c0,0.1,0.2,0.8,0.6,2.2
		C197.7,129.5,198.8,128.7,199.4,127.3z"
          />
          <path
            d="M237.4,190.3c-1.7,0-2.7-0.3-3.2-0.8c-0.5-0.5-0.8-1.6-0.9-3.4c-0.5-3.2-1.4-7.4-2.7-12.8c-0.3-1.1-0.8-3-1.6-5.5
		c-0.7-2.5-1.3-4.4-1.6-5.8c-1.5-6.3-0.3-12.1,3.2-17.4l5.2-7.7c3.2-4.8,3.9-10.1,2.2-15.8l-2.9-9.2c-1.1-3.3-1.2-7.1-0.6-11.4
		c0.2-1.4,0.6-2.3,1.3-2.9c0.6-0.6,1.6-0.9,3.1-0.8c8.5,0.1,17.5,0.3,27,0.6c1.5,0,2.4,0.3,2.8,0.8c0.4,0.5,0.6,1.5,0.5,3
		c-0.3,2.6-0.4,4.8-0.4,6.6c0,1.3-0.2,2.1-0.6,2.5c-0.4,0.4-1.2,0.6-2.4,0.5c-0.9,0.1-3,0-6.2,0c-3.2-0.2-5.3-0.2-6.3-0.2
		c-2.2,0.1-3.5,0.4-3.9,1c-0.4,0.6-0.3,2,0.4,4.1c1,3.3,1.7,5.3,1.9,5.8c1.2,4.4,1.1,8.4-0.5,12.2c-0.5,1.5-0.7,2.4-0.4,2.9
		s1.2,0.7,2.8,0.8c1,0,2.7,0.1,4.8,0.1c2.2,0,3.9,0.1,5,0.1c1.2,0.1,2,0.4,2.3,0.8c0.3,0.4,0.2,1.2-0.5,2.3
		c-0.3,0.5-2.1,3.3-5.2,8.2c-0.8,1.5-2.4,2.1-4.7,2.1c-1.3,0-2.9-0.1-4.8-0.1c-2,0-3.6-0.1-4.8-0.1c-1.9,0.1-3.2,0.4-4,1.1
		s-1.2,2-1.3,3.8c-0.1,2.4,0.2,4.6,0.7,6.7c0,0.3,0.9,3.8,2.9,10.6c0.7,2.6,2.6,4,5.5,4c5.9,0.1,10.2,0.2,12.7,0.3
		c1.8,0,3,0.4,3.8,1c0.7,0.6,1.2,1.7,1.5,3.5c0.4,1.7,0.6,3.2,0.7,4.5c0.1,1.9-0.2,3.1-0.7,3.7c-0.5,0.6-1.7,0.9-3.6,0.8
		c-0.7,0-5.2-0.1-13.3-0.3C242.3,190.4,238,190.3,237.4,190.3z"
          />
          <path
            d="M319.5,96.9c1.2,0,2.1,0,2.5,0.1c1.5,0.3,2.3,1.2,2.2,2.8c-0.1,2.4-0.2,5.1-0.5,8.2c-0.1,0.9-0.4,1.5-0.8,1.9
		c-0.4,0.3-1.1,0.5-2.2,0.5c-0.1,0.1-1.6,0.2-4.6,0.1c-2.9-0.1-4.5-0.1-4.6-0.1c-2.5-0.1-3.9,0.2-4.3,0.8c-0.4,0.6-0.2,2,0.5,4.2
		c0.9,3.3,1.7,5.9,2.1,7.7c0.9,3.9,0.4,7.6-1.6,11.2c-2.1,3.6-3.7,6.1-5,7.9c-4.9,6.7-6.2,14.4-3.6,22.9c2.3,6.9,3.4,10.8,3.5,11.4
		c0.7,3,1.3,6,1.5,9c0.1,1.4-0.2,2.4-0.7,3c-0.5,0.6-1.5,0.8-3.1,0.7c-2.1,0.1-4,0-5.9-0.1c-1.3,0-2.3-0.2-2.8-0.7
		c-0.5-0.4-0.8-1.2-0.7-2.5c-0.1-3.5-1.3-8.9-3.6-16.2c-1.1-3.5-2.1-6.6-2.6-9.5c-1.2-5.7-0.3-10.9,2.8-15.5c2.6-4,4.2-6.7,5.1-7.9
		c3.7-5.4,4.5-11.1,2.4-17.3l-1.9-5.7c-0.8-2.7-2.7-4.1-5.5-4c-1.6,0-4.8-0.1-9.4-0.2c-2-0.1-3.3-0.6-4-1.3c-0.7-0.7-1.1-1.9-1-3.8
		c0-1.5,0.3-3.2,0.6-4.9c0.2-1.3,0.6-2.3,1.3-2.8c0.6-0.5,1.5-0.8,2.9-0.7c0.6,0,3.5,0.1,8.7,0.2c1.4,0,5.6,0.1,12.5,0.3
		C310.9,96.7,317.5,96.9,319.5,96.9z"
          />
          <path
            d="M340.8,97.5c2.1,0.2,2.9,1.3,2.5,3c-1,4.6-0.2,10.6,2.4,17.9l1.1,4c1.5,5,0.9,9.7-2,14.2c-2.4,3.8-4,6.3-5,7.6
		c-4.2,6-5.4,12.5-3.3,19.6c0.3,1,0.8,2.8,1.6,5.2c0.7,2.4,1.3,4.3,1.8,5.7c1,4.2,1.8,7.7,2.3,10.5c0.2,1.9-0.1,3.1-0.6,3.8
		c-0.6,0.7-1.8,1-3.6,0.9c-2.4,0-4.3,0-5.9-0.1c-1.2-0.1-2.1-0.4-2.5-0.9c-0.4-0.4-0.7-1.2-0.7-2.5c0-3-1-7.7-3.1-14.3
		c-1.3-4.5-2.4-8.8-3.1-12.5c-0.9-4.3-0.1-8.7,2.5-13c0.1-0.3,1.4-2.4,3.7-6.1l3-4.5c2.7-4.3,3.3-9,1.8-13.9l-2.9-9.1
		c-1.3-4.3-1.6-8.3-0.8-11.8c0.3-1.7,0.8-2.7,1.4-3.3c0.6-0.5,1.7-0.8,3.3-0.7c0.1,0,0.9,0,2.5,0.1
		C339.4,97.4,340.6,97.5,340.8,97.5z"
          />
          <path
            d="M385,141.4c2.9-4.3,4.8-7.4,5.6-9.1c1.4-2.7,1.6-5.9,0.8-9.6c-0.6-2.3-1.6-5.4-3-9.6c-1.3-4.2-1.6-8.3-0.9-12.3
		c0.2-1.6,0.7-2.8,1.3-3.3c0.6-0.6,1.7-0.8,3.2-0.7c2.4,0,4.3,0,5.9,0.1c1.2,0,2.1,0.2,2.5,0.8c0.5,0.5,0.6,1.3,0.3,2.4
		c-0.8,4.9,0.1,10.8,2.5,17.7c0.1,0.2,0.2,0.5,0.3,0.9c0.1,0.4,0.2,0.7,0.3,0.9c2.4,6.6,1.5,12.5-2.4,18c-0.2,0.2-1.9,2.7-5,7.5
		c-3.2,4.8-4.1,10.2-2.8,16.1c0.4,1.4,1,3.5,1.8,6.2c0.8,2.7,1.4,4.6,1.7,5.7c1.3,5.1,2.1,8.9,2.4,11.5c0.3,1.7,0.1,2.9-0.5,3.5
		c-0.6,0.6-1.8,0.9-3.8,0.8c-0.3,0-1.4,0-3.2-0.1c-1.8,0-2.8-0.1-3-0.1c-1.9,0-3.3-1-3.9-2.9c-1.1-3.3-3.7-8.1-7.9-14.4
		c-3.1-4.8-5.3-8.4-6.9-10.9c-3.6-6.3-4.4-12.2-2.5-18c0.1-0.3,0.2-0.9,0.3-1.8l-0.3-0.9c-1.2,0.7-1.8,1.1-1.8,1.2
		c-4.5,5.5-6.3,11.2-5.5,16.8c0.1,0.5,1,4.4,2.9,11.6l3.2,13c0.1,0.4,0.2,1,0.3,1.8c0,1.3-0.3,2.3-0.9,2.9c-0.5,0.6-1.4,0.9-2.8,0.9
		c-2.4,0-4.6,0-6.8-0.1c-1.1-0.1-1.9-0.4-2.3-0.8s-0.6-1.2-0.6-2.3c-0.1-3.1-1.1-7.8-3.1-14.3c-1.4-4.8-2.5-9-3.2-12.4
		c-0.9-4.5-0.1-9,2.6-13.4c0.2-0.4,1.2-2.1,3.2-5l3.2-4.8c2.9-4.5,3.5-9.3,2-14.3l-2.9-9.2c-1.3-4.2-1.5-8.2-0.6-12
		c0.3-1.2,0.7-2.1,1.3-2.6s1.4-0.8,2.6-0.7c2.2-0.1,4.4,0,6.8,0.1c1.2,0,2.1,0.2,2.6,0.7c0.5,0.4,0.9,1.1,1.1,2.2
		c0.9,4.7,3.8,10.6,8.5,17.5c1.3,1.7,2.8,4.2,4.5,7c2.3,4,2.8,8.4,1.5,13.2c0,0.2-0.1,0.7-0.3,1.4c-0.2,0.7-0.4,1.2-0.5,1.5
		c-0.1,0.3-0.2,0.9-0.2,1.7c-0.1,0.8,0,1.4,0.2,1.8C384.2,142.1,384.9,141.5,385,141.4z"
          />
          <path
            d="M443.2,148.8c1,0,1.7,0.2,2.1,0.5c0.4,0.4,0.5,1.1,0.4,2.1c-0.7,4.6,0.2,11,2.7,19.4c1,3.2,2.1,6.8,3.1,11
		c0.3,1.3,0.4,2.9,0.4,4.7c-0.1,1.2-0.6,2.1-1.6,2.5c-0.9,0.4-1.9,0.3-3-0.4c-0.2-0.2-0.4-0.4-0.6-0.6c-1.1-1.2-2.7-1.5-4.8-0.6
		c-4,1.9-9.5,2.3-16,1.4c-3.1-0.6-5.9-2-8.3-4.4c-2.2-2-3.8-3.9-5.2-5.7c-3.8-5.6-6.3-12.7-7.4-21.6c-0.5-4.2,0.5-8.4,2.9-12.3
		c2.2-3.5,4-6.3,5.6-8.6c3.6-5.3,4.4-11,2.4-17.1c-0.8-2.3-1.5-4.5-2.2-6.7c-1-4.1,0-7.7,3-10.5c2.2-1.9,4.3-3.2,6.2-4.1
		c5.3-2.3,11.2-2.5,17.9-0.5c2.8,0.8,5.1,2.5,7,5.2c4.7,6.4,7.7,13.9,9.1,22.4c0.1,0.4,0.2,0.9,0.3,1.4c0.1,2.1-0.3,3.5-1,4.4
		c-0.7,0.8-2.1,1.2-4.2,1.1c-2.2,0-3.8-0.1-4.8-0.1c-1.2,0-2.1-0.2-2.5-0.7c-0.4-0.4-0.6-1.2-0.5-2.3c0.3-3.6-0.7-8.3-3-14.1
		c-2-5-5.7-7-11.1-6.1c-2.6,0.4-3.7,2-3.4,4.6c0,0.2,0.4,1.4,1,3.6c0.9,3.3,1.5,5.1,1.5,5.3c1.1,4.4,0.6,8.5-1.7,12.3
		c-2.2,3.7-3.8,6.4-5.2,8.1c-4.5,6.3-5.8,13.5-3.6,21.3c1,3.3,1.8,5.5,2.2,6.7c1.8,4.6,5.1,6.7,10,6.4c3.6-0.2,5-2.1,4.3-5.5
		c-0.1-0.6-0.4-1.5-0.7-2.7c-0.4-1.2-0.7-2-0.7-2.6c-0.6-2.1-1.1-4.1-1.6-6.2c-0.4-2.1-1.5-3.3-3.5-3.7c-1.3-0.2-2.2-0.5-2.6-1
		c-0.4-0.4-0.6-1.2-0.5-2.3c0.2-2.6,1.4-4,3.7-4.1c0.3,0,2.7,0.1,7.3,0.2l0-0.3C440.7,148.6,442.9,148.7,443.2,148.8z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Heading
