import React from "react"
import styled from "styled-components"

const Container = styled.div`
  transform: rotate(-1deg);
  svg {
    enable-background: new 0 0 959.2 178.6;
  }
`

const Heading = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 959.2 178.6">
        <g>
          <path
            d="M26.9,50.8c-9.3,0-16-1.7-20.2-5.1C2.2,41.9,0,35.4,0,26.2C0,13.4,5.6,5.4,17,2.4C22.5,1,33.6,0.1,50.4,0
		c5.7,0,10.9,0.1,15.4,0.3c11,0.4,18.3,1.1,22,2.1c4.2,1.2,7.3,3.1,9.1,5.8c2,2.9,3.3,7.4,3.7,13.5h-41c0-4.7-3.3-7.8-8.7-7.8
		c-6.1,0-9.1,2.2-9.1,6.6c0,1.9,1.1,3.5,3.2,4.7c1.8,1.1,3.7,1.5,5.6,1.5H77c9.3,0,16,1.7,20.2,5.2c4.6,3.8,6.8,10.2,6.8,19.4
		c0,7.5-1.6,13.3-5,17.5c-3,3.8-7,6.1-11.9,7c-4.3,0.8-8.6,1.3-13.1,1.5c-3.3,0.1-10.3,0.2-20.9,0.2c-22.3,0-35.6-0.6-39.9-1.6
		C5.1,73.9,1.8,68.3,1,55.8h42.8c0,4.7,3.4,7.9,8.8,7.9c6.1,0,9.1-2.2,9.1-6.6c0-1.9-1.3-3.5-3.8-4.7c-2-1.1-4-1.6-6-1.6
		C51.9,50.8,26.9,50.8,26.9,50.8z"
          />
          <path
            d="M162,0c13.9,0,23.9,0.6,30.2,1.6c10.2,1.8,17,6.2,20.2,13.2c2,4.6,3,12.5,3,23.9s-1,19.4-3,23.9c-3.2,7-10,11.3-20.2,13.2
		c-6.3,1.1-16.4,1.6-30.2,1.6c-14,0-24-0.6-30.3-1.6c-10.2-1.8-17-6.2-20.2-13.2c-2-4.6-3-12.5-3-23.9s1-19.4,3-23.9
		c3.2-7,10-11.3,20.2-13.2C137.9,0.6,148,0,162,0z M162,64.1c4.9,0,8.2-1.9,10-5.8c1.5-3.2,2.1-9.8,2.1-19.8
		c0-10.6-0.7-17.3-2.1-20.2c-1.6-3.3-5-4.9-10-4.9c-4.9,0-8.2,1.6-10,4.9c-1.4,2.6-2,9.3-2,20.2c0,10.2,0.7,16.8,2,19.8
		C153.7,62.2,157,64.1,162,64.1z"
          />
          <path
            d="M266.2,2.4v36.1c0,12.3,0.2,18.9,0.6,19.8c1.6,3.9,4.8,5.8,9.7,5.8c4.9,0,8.1-1.9,9.8-5.8c0.5-1.1,0.7-7.7,0.7-19.8V2.4
		h41.8v36.3c0,11.5-1,19.5-2.9,23.9c-3.1,7-9.7,11.3-19.7,13.2c-6.1,1.1-16,1.6-29.6,1.6c-13.7,0-23.5-0.6-29.6-1.6
		c-10-1.8-16.6-6.2-19.7-13.2c-1.9-4.5-2.9-12.4-2.9-23.9V2.4H266.2z"
          />
          <path d="M400.1,30.5V2.4h39.3v73.4H399l-24-31.5v31.5h-39.3V2.4H377L400.1,30.5z" />
          <path
            d="M500.5,2.4c2.4,0,6.5-0.1,12.2-0.2c4-0.1,7.1,0,9.3,0.1c3,0.1,6,0.4,8.8,0.9c10.2,1.7,17,6,20.2,12.7c2,4.3,3,11.9,3,22.9
		s-1,18.6-3,22.9c-3.2,6.7-10,11-20.2,12.7c-6.1,1.1-16.3,1.6-30.3,1.6H449V2.4H500.5z M510.9,54.8c1-2.4,1.5-7.9,1.5-16.6
		c0-6.3-0.6-11.7-1.7-16.1c-0.4-1.5-1.5-2.8-3.1-4c-1.9-1.3-4.2-1.9-6.6-1.9h-10.6V62H501C505.6,62,509,59.6,510.9,54.8z"
          />
          <path d="M588.5,75.9V2.4h41.4v73.4L588.5,75.9L588.5,75.9z" />
          <path
            d="M690,2.4c2.4,0,6.5-0.1,12.2-0.2c4-0.1,7.1,0,9.3,0.1c3,0.1,6,0.4,8.8,0.9c10.2,1.7,17,6,20.2,12.7c2,4.3,3,11.9,3,22.9
		s-1,18.6-3,22.9c-3.2,6.7-10,11-20.2,12.7C714.2,75.5,704,76,690,76h-51.5V2.4H690z M700.4,54.8c1-2.4,1.5-7.9,1.5-16.6
		c0-6.3-0.6-11.7-1.7-16.1c-0.4-1.5-1.5-2.8-3.1-4c-1.9-1.3-4.2-1.9-6.6-1.9h-10.6V62h10.6C695.2,62,698.6,59.6,700.4,54.8z"
          />
          <path
            d="M793.6,31.3c0,0.1,13.3,0.1,39.8,0v15c-26.6,0.1-39.8,0.1-39.8,0V57h39.8v18.9h-81.2V2.4h81.2v18.9
		c-26.6-0.2-39.8-0.2-39.8,0V31.3z"
          />
          <path d="M886.3,65.8l-3.5,10.1h-40.5l26.1-73.4h64.8l26,73.4h-40.4l-3.6-10.1H886.3z M909.5,49.5l-8.7-24.8l-8.7,24.8H909.5z" />
          <path
            d="M32.4,146.4c-11.2,0-19.2-2.1-24.3-6.2C2.7,135.7,0,127.9,0,116.8c0-15.4,6.8-25,20.4-28.6c6.6-1.7,20.1-2.8,40.2-2.9
		c6.9,0,13.1,0.1,18.5,0.4c13.2,0.5,22,1.3,26.5,2.6c5,1.4,8.7,3.7,11,7c2.4,3.5,4,8.9,4.4,16.2H71.7c0-5.6-4-9.4-10.5-9.4
		c-7.3,0-11,2.7-11,7.9c0,2.3,1.3,4.2,3.8,5.7c2.2,1.3,4.4,1.9,6.8,1.9h31.7c11.2,0,19.2,2.1,24.3,6.3c5.5,4.5,8.2,12.2,8.2,23.3
		c0,9-2,16-6.1,21.1c-3.6,4.5-8.4,7.3-14.3,8.4c-5.1,0.9-10.4,1.5-15.7,1.7c-4,0.1-12.4,0.2-25.2,0.2c-26.8,0-42.8-0.7-48-2
		c-9.8-2.4-13.8-9.1-14.7-24.1h51.5c0,5.6,4.1,9.4,10.6,9.4c7.3,0,11-2.7,11-7.9c0-2.3-1.5-4.2-4.5-5.7c-2.4-1.3-4.8-1.9-7.2-1.9
		H32.4z"
          />
          <path
            d="M180.9,123c0,0.1,16,0.1,47.9,0v18.1c-32,0.1-47.9,0.1-47.9,0v12.8h47.9v22.7h-97.7V88.2h97.7V111c-32-0.2-47.9-0.2-47.9,0
		V123z"
          />
          <path
            d="M271.6,146.4c-11.2,0-19.2-2.1-24.3-6.2c-5.5-4.5-8.2-12.4-8.2-23.4c0-15.4,6.8-25,20.4-28.6c6.6-1.7,20.1-2.8,40.2-2.9
		c6.9,0,13.1,0.1,18.5,0.4c13.2,0.5,22,1.3,26.5,2.6c5,1.4,8.7,3.7,11,7c2.4,3.5,4,8.9,4.4,16.2h-49.3c0-5.6-4-9.4-10.5-9.4
		c-7.3,0-11,2.7-11,7.9c0,2.3,1.3,4.2,3.8,5.7c2.2,1.3,4.4,1.9,6.8,1.9h31.7c11.2,0,19.2,2.1,24.3,6.3c5.5,4.5,8.2,12.2,8.2,23.3
		c0,9-2,16-6.1,21.1c-3.6,4.5-8.4,7.3-14.3,8.4c-5.1,0.9-10.4,1.5-15.7,1.7c-4,0.1-12.4,0.2-25.2,0.2c-26.8,0-42.8-0.7-48-2
		c-9.8-2.4-13.8-9.1-14.7-24.1h51.5c0,5.6,4.1,9.4,10.6,9.4c7.3,0,11-2.7,11-7.9c0-2.3-1.5-4.2-4.5-5.7c-2.4-1.3-4.8-1.9-7.2-1.9
		H271.6z"
          />
          <path
            d="M401.7,146.4c-11.2,0-19.2-2.1-24.3-6.2c-5.5-4.5-8.2-12.4-8.2-23.4c0-15.4,6.8-25,20.4-28.6c6.6-1.7,20.1-2.8,40.2-2.9
		c6.9,0,13.1,0.1,18.5,0.4c13.2,0.5,22,1.3,26.5,2.6c5,1.4,8.7,3.7,11,7c2.4,3.5,4,8.9,4.4,16.2H441c0-5.6-4-9.4-10.5-9.4
		c-7.3,0-11,2.7-11,7.9c0,2.3,1.3,4.2,3.8,5.7c2.2,1.3,4.4,1.9,6.8,1.9h31.7c11.2,0,19.2,2.1,24.3,6.3c5.5,4.5,8.2,12.2,8.2,23.3
		c0,9-2,16-6.1,21.1c-3.6,4.5-8.4,7.3-14.3,8.4c-5.1,0.9-10.4,1.5-15.7,1.7c-4,0.1-12.4,0.2-25.2,0.2c-26.8,0-42.8-0.7-48-2
		c-9.8-2.4-13.8-9.1-14.7-24.1h51.5c0,5.6,4.1,9.4,10.6,9.4c7.3,0,11-2.7,11-7.9c0-2.3-1.5-4.2-4.5-5.7c-2.4-1.3-4.8-1.9-7.2-1.9
		H401.7z"
          />
          <path d="M499.9,176.6V88.2h49.8v88.4H499.9z" />
          <path
            d="M624.3,85.3c16.7,0,28.8,0.7,36.4,2c12.2,2.2,20.4,7.5,24.3,15.9c2.4,5.5,3.6,15,3.6,28.8s-1.2,23.3-3.6,28.8
		c-3.8,8.4-12,13.6-24.3,15.9c-7.6,1.3-19.7,2-36.4,2c-16.8,0-28.9-0.7-36.5-2c-12.2-2.2-20.4-7.5-24.3-15.9
		c-2.4-5.5-3.6-15-3.6-28.8s1.2-23.3,3.6-28.8c3.8-8.4,12-13.6,24.3-15.9C595.4,86,607.5,85.3,624.3,85.3z M624.3,162.5
		c5.9,0,9.9-2.3,12-7c1.7-3.9,2.6-11.8,2.6-23.8c0-12.7-0.8-20.9-2.6-24.3c-2-4-6.1-5.9-12-5.9s-9.9,2-12,5.9
		c-1.6,3.1-2.4,11.2-2.4,24.3c0,12.2,0.8,20.2,2.4,23.8C614.4,160.2,618.3,162.5,624.3,162.5z"
          />
          <path d="M776.1,122.1V88.2h47.3v88.4h-48.6l-28.9-37.9v37.9h-47.3V88.2h49.7L776.1,122.1z" />
          <path
            d="M866.6,146.4c-11.2,0-19.2-2.1-24.3-6.2c-5.5-4.5-8.2-12.4-8.2-23.4c0-15.4,6.8-25,20.4-28.6c6.6-1.7,20.1-2.8,40.2-2.9
		c6.9,0,13.1,0.1,18.5,0.4c13.2,0.5,22,1.3,26.5,2.6c5,1.4,8.7,3.7,11,7c2.4,3.5,4,8.9,4.4,16.2h-49.3c0-5.6-4-9.4-10.5-9.4
		c-7.3,0-11,2.7-11,7.9c0,2.3,1.3,4.2,3.8,5.7c2.2,1.3,4.4,1.9,6.8,1.9h31.7c11.2,0,19.2,2.1,24.3,6.3c5.5,4.5,8.2,12.2,8.2,23.3
		c0,9-2,16-6.1,21.1c-3.6,4.5-8.4,7.3-14.3,8.4c-5.1,0.9-10.4,1.5-15.7,1.7c-4,0.1-12.4,0.2-25.2,0.2c-26.8,0-42.8-0.7-48-2
		c-9.8-2.4-13.8-9.1-14.7-24.1h51.5c0,5.6,4.1,9.4,10.6,9.4c7.3,0,11-2.7,11-7.9c0-2.3-1.5-4.2-4.5-5.7c-2.4-1.3-4.8-1.9-7.2-1.9
		H866.6z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Heading
