import React from "react"

import Intro from "./Intro"
import RyanHoliday from "./RyanHoliday"
import BlackCoffee from "./BlackCoffee"
import Sponsors from "../Sponsors"

const AboutPage = () => {
  return (
    <>
      <Intro />
      <RyanHoliday />
      <BlackCoffee />
      <Sponsors />
    </>
  )
}

export default AboutPage
