import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

const Container = styled.div`
  padding: 1rem 0 0 0;
  transform: translate(0px, 20px) scale(1);
  transform-origin: top right;
  overflow: hidden;
  svg {
    enable-background: new 0 0 359.7 151.2;
    fill: rgba(0, 0, 0, 0.9);
    transform: translate(10px, 0) rotate(-2deg);
  }
  ${media.tablet`
    transform: translate(0, 0) scale(1.35);
    svg {
    }
  `}
`

const Heading = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 359.7 151.2">
        <g>
          <path
            d="M78.6,15.4c-0.3-0.2-0.7-1-1-2.5c-0.2-0.8-0.6-2-1.3-3.4C75.8,8.3,75,7.4,74,7s-1.9-0.3-2.6,0.2c-0.6,0.5-0.8,1.3-0.5,2.3
		l1.1,3.4c0.9,2.7,0.6,5.3-0.9,7.7l-2.6,4.2c-1.9,2.9-2.4,6.2-1.4,9.6c0.4,1.4,0.8,2.9,1.4,4.3c0.5,1.3,1.3,2.2,2.4,2.6
		c1,0.5,1.9,0.5,2.6-0.1c0.7-0.5,0.9-1.3,0.6-2.4c-0.1-0.3-0.3-0.9-0.6-1.8c-0.3-0.9-0.5-1.6-0.6-2.2c-0.2-0.7-0.2-1.3,0-1.6
		s0.7-0.5,1.5-0.5c0.9,0,1.7,0,2.4,0c0.7,0.1,1.3,0.2,1.6,0.4c0.3,0.2,0.5,0.7,0.8,1.4l1.4,4.4c0.7,2.3,0.6,4.3-0.4,6
		S77.4,47.6,75,48c-2.9,0.5-5.5-0.2-7.7-2.1c-1.9-1.5-3.3-3.3-4.3-5.3c-1.2-2.7-2.1-5.3-2.5-8c-0.4-2.4,0-4.6,1.1-6.7
		c0.1-0.3,0.6-1.2,1.6-2.7l1.7-2.7c1.4-2.4,1.7-4.9,0.9-7.5l-1.4-3.4c-0.8-2.7-0.2-4.9,2-6.7c2-1.7,4.2-2.5,6.6-2.5
		c2.7,0,4.8,1,6.4,3c2.1,2.8,3.7,6.3,4.8,10.5c0.2,0.6,0.1,1.1-0.1,1.4c-0.2,0.3-0.7,0.4-1.4,0.5c-0.1,0-0.5,0-1.4,0
		C79.8,15.7,78.9,15.6,78.6,15.4z"
          />
          <path
            d="M92.9,43.2c-2-2.9-3.4-6.5-4-10.8c-0.4-2.4,0.1-4.7,1.5-7l2.6-4.2c1.8-2.7,2.1-5.6,1-8.7c-0.4-1.2-0.8-2.3-1.1-3.4
		c-0.6-2.1-0.2-4,1.4-5.6c1.4-1.4,3-2.3,4.8-2.7c2.2-0.5,4.6-0.6,7.3-0.2c2,0.3,3.6,1.3,4.8,3c2.2,2.9,3.8,6.5,4.8,10.7
		c0.4,2,0.1,3.9-0.9,5.7c-0.3,0.5-0.7,1.2-1.3,2.2s-1,1.7-1.3,2.2c-1,1.7-1.6,3-1.9,4c-0.6,1.6-0.6,3.5,0.1,5.9
		c0.1,0.4,0.4,1.5,0.9,3.2c0.1,0.2,0.1,0.4,0.2,0.7s0.2,0.5,0.2,0.7c0.8,2.5,0.6,4.5-0.5,6.3c-1.1,1.7-3,2.7-5.5,3
		c-0.1,0-1.1,0.1-2.9,0.2C99.1,48.7,95.6,47,92.9,43.2z M97.1,38c0.5,1.6,1.3,2.7,2.2,3.2c0.9,0.6,2.2,0.8,3.9,0.7
		c2-0.2,2.7-1.2,2.2-3c-0.1-0.2-0.4-1.3-1-3.2c-1.3-4.2-0.8-8,1.7-11.5c0.6-0.9,1.4-2.3,2.6-4.3c1-1.8,1.2-3.6,0.7-5.5
		c-0.4-1.8-1-3.4-1.6-5c-0.8-1.9-2.2-2.8-4.2-2.7c-0.2,0-0.4,0-0.7,0c-0.3,0-0.5,0-0.8,0c-1.9,0.2-2.6,1.2-2.1,3
		c0.1,0.5,0.4,1.5,0.9,2.8c1.1,3,0.7,5.9-1,8.6l-2.7,4.4c-1.1,1.8-1.6,3.5-1.5,5.1C95.6,32.7,96.1,35.1,97.1,38z"
          />
          <path
            d="M140.5,23.8c1.4-2.3,2.4-3.9,2.8-4.8c0.7-1.4,0.8-3,0.3-5c-0.3-1.2-0.9-2.8-1.7-5c-0.7-2.2-1-4.3-0.6-6.4
		c0.1-0.9,0.3-1.4,0.6-1.7c0.3-0.3,0.9-0.4,1.7-0.4c1.2-0.1,2.2-0.1,3,0c0.6,0,1.1,0.1,1.3,0.4c0.3,0.1,0.4,0.5,0.3,1.1
		c-0.4,2.6,0.1,5.6,1.5,9.1c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5c1.3,3.4,0.9,6.5-1,9.4c-0.1,0.1-0.9,1.4-2.5,3.9
		c-1.6,2.6-2,5.4-1.3,8.4c0.2,0.7,0.5,1.8,1,3.2s0.8,2.4,0.9,3c0.7,2.7,1.2,4.7,1.4,5.9c0.2,0.9,0.1,1.5-0.2,1.8s-0.9,0.5-2,0.5
		c-0.2,0-0.7,0-1.6,0c-0.9,0-1.5,0-1.6,0c-1,0-1.7-0.5-2.1-1.4c-0.6-1.7-2.1-4.2-4.3-7.4c-1.7-2.5-2.9-4.3-3.7-5.6
		c-1.9-3.2-2.4-6.3-1.5-9.3c0.1-0.2,0.1-0.5,0.2-1l-0.2-0.5c-0.6,0.4-1,0.6-1,0.6c-2.3,2.9-3.2,5.9-2.6,8.8c0.1,0.3,0.6,2.3,1.6,6
		l1.8,6.7c0.1,0.2,0.1,0.5,0.2,1c0,0.7-0.1,1.2-0.4,1.5s-0.7,0.5-1.4,0.5c-1.2,0.1-2.4,0.1-3.5,0c-0.6-0.1-1-0.2-1.2-0.4
		s-0.3-0.6-0.3-1.2c-0.1-1.6-0.7-4.1-1.8-7.4c-0.8-2.5-1.4-4.7-1.8-6.4c-0.5-2.4-0.1-4.7,1.2-7.1c0.1-0.2,0.6-1.1,1.6-2.6l1.6-2.6
		c1.4-2.4,1.7-4.9,0.9-7.5l-1.6-4.7c-0.7-2.2-0.9-4.3-0.5-6.3c0.2-0.6,0.4-1.1,0.6-1.4c0.3-0.3,0.7-0.4,1.4-0.4
		c1.1-0.1,2.3-0.1,3.5,0c0.6,0,1.1,0.1,1.4,0.3s0.5,0.6,0.6,1.1c0.5,2.5,2.1,5.5,4.7,9c0.7,0.9,1.5,2.1,2.4,3.6c1.2,2,1.6,4.3,1,6.9
		c0,0.1-0.1,0.3-0.2,0.7c-0.1,0.4-0.2,0.6-0.2,0.8c-0.1,0.2-0.1,0.5-0.1,0.9s0,0.7,0.1,1C140.1,24.1,140.4,23.8,140.5,23.8z"
          />
          <path
            d="M171.9,0.3c2.6,0.1,4.7,1.2,6.2,3.3c2.1,3,3.7,6.6,4.7,10.8c0.5,2,0.2,3.8-0.8,5.5c-1.1,1.9-2,3.4-2.6,4.3
		c-2.3,3.4-2.8,7.1-1.5,11.1l0.6,2.3c0.4,1.1,1.1,2.1,2.1,3c1.2,0.9,2,1.5,2.5,2c0.7,0.7,0.7,1.4,0.1,2.1c-0.4,0.4-1.2,1.2-2.3,2.4
		c-0.7,0.6-1.5,0.7-2.2,0.1c-0.7-0.6-1.6-0.6-2.5,0c-1.4,0.7-3,1.1-4.8,1.2c-1.8,0.1-3.4,0-4.9-0.3c-1.6-0.2-2.9-0.9-4.1-2.2
		c-1.8-1.9-3-3.3-3.6-4.3c-1.7-2.9-2.8-6.1-3.1-9.8c-0.2-1.8,0.2-3.5,1-5.1c0.3-0.5,0.9-1.6,1.8-3l1.9-3.1c1.3-2.2,1.6-4.6,0.8-7.1
		c-0.4-1.2-0.8-2.5-1.2-3.8c-1-2.6-0.3-4.9,1.8-6.7c1.9-1.6,4-2.4,6.3-2.5c0.1,0,0.7,0,2.1,0C171.1,0.3,171.7,0.3,171.9,0.3z
		 M162.5,33.2c0.5,2.2,1,3.9,1.4,5.1c1,2.8,3,4,6,3.6c0.5-0.1,0.8-0.2,0.9-0.4c0.1-0.5,0-0.9-0.3-1.1c-0.3-0.3-1.1-1-2.6-2.2
		c-1.8-1.5-2.8-2.3-2.8-2.3c-0.4-0.4-0.7-0.7-0.7-1.1c0-0.3,0.2-0.8,0.6-1.2c0.3-0.4,1-1,2-2c0.6-0.6,1.2-0.9,1.8-0.8
		c0.9,0.1,1.3-0.3,1.4-1.1c0.3-1.6,1.3-3.8,3.1-6.6c1.7-2.6,2.6-4.7,2.7-6.3c0.2-1.6-0.3-3.8-1.5-6.7c-0.6-1.4-1.4-2.4-2.3-3
		c-0.9-0.6-2.2-0.7-3.7-0.5c-1.8,0.3-2.5,1.3-2,3c0.1,0.6,0.4,1.7,1,3c1.1,3,0.7,5.9-1,8.7c-1,1.6-1.9,3-2.7,4.5
		c-1,1.7-1.5,3.5-1.5,5.3C162.3,32.4,162.4,33.1,162.5,33.2z"
          />
          <path
            d="M195.7,46.8c-1.8-1.3-3.4-3.1-4.8-5.6c-1.4-2.4-2.3-5.3-2.8-8.6c-0.4-2.4,0-4.7,1.2-6.7l3-4.9c1.7-2.6,2-5.4,0.9-8.4
		c-0.7-1.9-1.2-3.5-1.6-4.8c-0.6-1.8-0.7-3.7-0.3-5.5c0.2-0.6,0.4-1.1,0.6-1.4c0.3-0.3,0.7-0.4,1.4-0.4c1.2-0.1,2.3-0.1,3.3,0
		c0.6,0.1,1,0.2,1.2,0.4s0.3,0.6,0.2,1.2c-0.4,2.6,0.1,5.7,1.5,9.3c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.3,0.2,0.5,0.2,0.6
		c1.1,2.9,0.7,5.7-1,8.4l-2.5,3.9c-1.8,2.8-2.2,5.8-1.4,9.1c0.5,2,1,3.6,1.5,4.8c0.6,1.5,1.5,2.4,2.6,2.8c1.1,0.3,2.6,0.4,4.2,0
		c0.4-0.1,0.8-0.3,1-0.7s0.3-0.9,0.2-1.4c0-0.1-0.2-0.6-0.5-1.7c-0.7-2.6-1.1-4-1.1-4.2c-0.7-2.9-0.3-5.6,1.2-8.1
		c1.1-1.9,2-3.3,2.7-4.3c1.8-2.7,2.1-5.7,1-8.8l-1.4-4c-0.6-1.8-0.8-3.7-0.6-5.9c0.1-0.8,0.3-1.4,0.6-1.7s0.9-0.5,1.7-0.5
		c1.2-0.1,2.2-0.1,3,0c1.2,0.1,1.7,0.6,1.5,1.7c-0.4,2.4,0,5.2,1.2,8.4c0.1,0.2,0.2,0.5,0.4,1c0.1,0.5,0.2,0.8,0.3,0.9
		c1.2,3.2,0.8,6.2-1.1,9c-0.7,1-1.6,2.4-2.6,4.2c-1.4,2.4-1.9,5-1.3,7.8c0.1,0.1,0.5,1.6,1.3,4.6c0.5,1.7,0.7,2.6,0.8,2.8
		c0.4,2.6-0.6,4.6-2.9,6.1c-1.3,0.9-2.7,1.3-4.2,1.4c-0.1,0-0.8,0-2.2,0C199.8,48.5,197.5,48,195.7,46.8z"
          />
          <path
            d="M226.2,48.3c-0.9,0-1.4-0.1-1.6-0.4c-0.2-0.2-0.4-0.8-0.5-1.7c-0.3-1.7-0.8-3.8-1.5-6.5c-0.2-0.6-0.5-1.5-0.9-2.8
		s-0.7-2.3-0.9-3c-0.8-3.2-0.3-6.2,1.4-9l2.6-4c1.6-2.5,1.9-5.2,1-8.1l-1.6-4.7c-0.6-1.7-0.7-3.7-0.4-5.9c0.1-0.7,0.3-1.2,0.6-1.5
		s0.8-0.4,1.6-0.4c4.4-0.1,9-0.1,13.9,0c0.7,0,1.2,0.1,1.4,0.4c0.2,0.2,0.3,0.8,0.3,1.6c-0.1,1.3-0.2,2.5-0.2,3.4
		c0,0.6-0.1,1.1-0.3,1.3s-0.6,0.3-1.2,0.3c-0.5,0.1-1.5,0.1-3.2,0c-1.6,0-2.7,0-3.2,0c-1.1,0.1-1.8,0.2-2,0.6
		c-0.2,0.3-0.1,1,0.2,2.1c0.6,1.7,0.9,2.7,1,3c0.7,2.2,0.7,4.3-0.1,6.3c-0.3,0.7-0.3,1.2-0.2,1.5c0.2,0.2,0.6,0.4,1.4,0.4
		c0.5,0,1.4,0,2.5,0s2,0,2.6,0c0.6,0.1,1,0.2,1.2,0.4c0.2,0.2,0.1,0.6-0.2,1.2c-0.2,0.3-1,1.7-2.6,4.3c-0.4,0.7-1.2,1.1-2.4,1.1
		c-0.6,0-1.5,0-2.5,0s-1.8,0-2.5,0c-1,0.1-1.6,0.3-2,0.6c-0.4,0.3-0.6,1-0.6,2c0,1.2,0.1,2.4,0.4,3.4c0,0.2,0.5,2,1.6,5.4
		c0.4,1.3,1.4,2,2.9,2c3,0,5.2,0,6.5,0c0.9,0,1.6,0.1,1.9,0.4c0.4,0.3,0.6,0.9,0.8,1.8c0.2,0.9,0.3,1.6,0.4,2.3
		c0.1,1-0.1,1.6-0.3,1.9c-0.3,0.3-0.9,0.4-1.8,0.4c-0.4,0-2.6,0-6.8,0C228.7,48.3,226.5,48.3,226.2,48.3z"
          />
          <path
            d="M261,0.7c2.5,0.2,4.7,1.1,6.6,2.9c2.5,2.4,4.4,4.8,5.8,7.2c1,1.7,1.5,3.2,1.6,4.5s-0.1,2.4-0.6,3.3s-1.4,1.9-2.7,3
		c-0.6,0.5-1.2,1-2,1.5c-0.7,0.5-1.6,1.1-2.6,1.7s-1.7,1.1-2.1,1.4c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.2-0.5,0.3-0.6,0.3
		c-0.7,0.4-1.1,0.8-1.3,1.2c-0.2,0.4-0.1,0.9,0.1,1.7c0.6,1.8,1.3,3.3,2,4.7c0.4,0.7,1.1,1.7,1.9,2.9s1.4,2,1.6,2.2l3.4,5.5
		c0.1,0.2,0.2,0.5,0.4,0.9c0.4,0.9,0.5,1.5,0.2,1.9c-0.2,0.3-0.8,0.5-1.8,0.5c-0.7,0.1-1.8,0.1-3.3,0c-1.1,0-1.8-0.5-2.2-1.4
		c-0.2-0.6-0.4-1.1-0.7-1.7c-0.2-0.5-0.6-1.1-1-1.8s-0.7-1.2-0.9-1.5c-0.2-0.3-0.6-0.9-1.2-1.7c-0.6-0.9-0.9-1.4-1-1.5
		c-1.3-1.9-2.5-3.9-3.8-5.9c-0.2-0.3-0.5-1.2-1.1-2.7c-0.3-0.7-0.7-1.1-1.3-1s-1,0.5-1,1.2c0,1.7,0.1,2.8,0.2,3.4
		c0.2,0.7,0.5,1.8,0.9,3.3c0.5,1.4,0.8,2.5,1,3c0.7,2.7,1.1,4.7,1.4,5.9c0.1,0.9,0,1.5-0.3,1.8c-0.3,0.3-1,0.5-2,0.5
		c-1.3,0.1-2.3,0.1-3,0c-0.6-0.1-1-0.2-1.2-0.4s-0.3-0.5-0.4-1.1c-0.1-0.8-0.2-1.7-0.4-2.7s-0.4-1.7-0.5-2.2s-0.4-1.4-0.9-2.7
		s-0.7-2.1-0.8-2.3c-0.8-2.3-1.2-4.3-1.2-5.9c0-1.9,0.5-3.6,1.4-5.3c1.1-1.8,2-3.2,2.6-4.2c1.9-2.9,2.3-6,1-9.4
		c-0.2-0.5-0.5-1.3-0.8-2.3s-0.5-1.5-0.6-1.6c-0.6-1.7-0.7-3.6-0.5-5.7c0.1-0.8,0.3-1.3,0.7-1.6c0.3-0.3,0.9-0.4,1.7-0.4
		c0.2,0,1.7,0,4.7,0C259.2,0.5,260.8,0.6,261,0.7z M266.3,19.1c1.4-1.2,2-2.8,1.5-4.7c-0.4-2.1-1.6-3.8-3.4-5.2
		c-1.9-1.4-3.9-2-5.9-1.8c-0.7,0.1-1.1,0.2-1.3,0.5c-0.2,0.3-0.2,0.7,0,1.4c0.1,0.4,0.3,1,0.6,1.8c0.3,0.8,0.5,1.4,0.6,1.8
		c0.7,2.5,0.6,4.8-0.4,6.9c-0.4,0.9-0.5,1.4-0.4,1.6s0.7,0.4,1.6,0.4c0.1,0,0.1,0,0.2,0h0.1C261.9,21.8,264.2,20.9,266.3,19.1z"
          />
          <path
            d="M289.6,0.4c1.1,0.1,1.5,0.6,1.4,1.5c-0.5,2.4,0,5.5,1.4,9.2l0.6,2.1c0.9,2.6,0.6,5-0.9,7.4c-1.2,2-2,3.3-2.5,4
		c-2.1,3.2-2.6,6.6-1.5,10.2c0.2,0.5,0.5,1.4,0.9,2.7s0.7,2.2,1,2.9c0.6,2.2,1,4,1.3,5.4c0.1,1,0,1.6-0.3,2
		c-0.3,0.3-0.9,0.5-1.9,0.5c-1.2,0.1-2.2,0.1-3,0c-0.6-0.1-1.1-0.2-1.3-0.4c-0.2-0.2-0.3-0.6-0.4-1.3c-0.1-1.6-0.6-4-1.8-7.4
		c-0.7-2.4-1.3-4.5-1.8-6.4c-0.5-2.2-0.2-4.5,1.1-6.7c0.1-0.2,0.7-1.2,1.8-3.2l1.5-2.4c1.3-2.3,1.6-4.7,0.8-7.2l-1.6-4.7
		c-0.7-2.2-0.9-4.3-0.6-6.1c0.2-0.9,0.4-1.4,0.7-1.7c0.3-0.3,0.9-0.4,1.7-0.4c0.1,0,0.5,0,1.3,0C288.9,0.4,289.6,0.4,289.6,0.4z"
          />
          <path
            d="M317.9,23.8c1.4-2.3,2.4-3.9,2.8-4.8c0.7-1.4,0.8-3,0.3-5c-0.3-1.2-0.9-2.8-1.7-5c-0.7-2.2-1-4.3-0.6-6.4
		c0.1-0.9,0.3-1.4,0.6-1.7c0.3-0.3,0.9-0.4,1.7-0.4c1.2-0.1,2.2-0.1,3,0c0.6,0,1.1,0.1,1.3,0.4c0.2,0.2,0.3,0.7,0.2,1.2
		c-0.4,2.6,0.1,5.6,1.5,9.1c0.1,0.1,0.1,0.3,0.2,0.5s0.1,0.4,0.2,0.5c1.3,3.4,0.9,6.5-1,9.4c-0.1,0.1-0.9,1.4-2.5,3.9
		c-1.6,2.6-2,5.4-1.3,8.4c0.2,0.7,0.5,1.8,1,3.2s0.8,2.4,0.9,3c0.7,2.7,1.2,4.7,1.4,5.9c0.2,0.9,0.1,1.5-0.2,1.8
		c-0.3,0.3-0.9,0.5-2,0.5c-0.2,0-0.7,0-1.6,0s-1.5,0-1.6,0c-1,0-1.7-0.5-2.1-1.4c-0.6-1.7-2.1-4.2-4.3-7.4c-1.7-2.5-2.9-4.3-3.7-5.6
		c-1.9-3.2-2.4-6.3-1.5-9.3c0.1-0.2,0.1-0.5,0.2-1l-0.2-0.5c-0.6,0.4-1,0.6-1,0.6c-2.3,2.9-3.2,5.9-2.6,8.8c0.1,0.3,0.6,2.3,1.6,6
		l1.8,6.7c0.1,0.2,0.1,0.5,0.2,1c0,0.7-0.1,1.2-0.4,1.5s-0.7,0.5-1.4,0.5c-1.2,0.1-2.4,0.1-3.5,0c-0.6-0.1-1-0.2-1.2-0.4
		c-0.2-0.2-0.3-0.6-0.3-1.2c-0.1-1.6-0.7-4.1-1.8-7.4c-0.8-2.5-1.4-4.7-1.8-6.4c-0.5-2.4-0.1-4.7,1.2-7.1c0.1-0.2,0.6-1.1,1.6-2.6
		l1.6-2.6c1.4-2.4,1.7-4.9,0.9-7.5l-1.6-4.6c-0.7-2.2-0.9-4.3-0.5-6.3c0.2-0.6,0.4-1.1,0.6-1.4c0.3-0.3,0.7-0.4,1.4-0.4
		c1.1-0.1,2.3-0.1,3.5,0c0.6,0,1.1,0.1,1.4,0.3s0.5,0.6,0.6,1.1c0.5,2.5,2.1,5.5,4.7,9c0.7,0.9,1.5,2.1,2.4,3.6c1.2,2,1.6,4.3,1,6.9
		c0,0.1-0.1,0.3-0.2,0.7c-0.1,0.4-0.2,0.6-0.2,0.8c-0.1,0.2-0.1,0.5-0.1,0.9s0,0.7,0.1,1C317.5,24.1,317.9,23.8,317.9,23.8z"
          />
          <path
            d="M352.8,27.5c0.5,0,0.9,0.1,1.1,0.3c0.2,0.2,0.3,0.5,0.2,1.1c-0.3,2.4,0.2,5.7,1.6,9.9c0.6,1.7,1.1,3.5,1.7,5.6
		c0.2,0.7,0.2,1.5,0.2,2.4c-0.1,0.6-0.3,1.1-0.8,1.3s-1,0.2-1.6-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.6-1.4-0.7-2.5-0.2
		c-2.1,1-4.8,1.3-8.3,0.9c-1.6-0.3-3-1-4.3-2.2c-1.1-1-2-2-2.7-2.9c-2-2.8-3.4-6.5-4.1-11.1c-0.3-2.2,0.1-4.3,1.4-6.4
		c1.1-1.8,2-3.3,2.8-4.5c1.8-2.8,2.2-5.7,1-8.8c-0.4-1.2-0.8-2.3-1.2-3.4c-0.6-2.1-0.1-4,1.4-5.5c1.1-1,2.2-1.7,3.1-2.2
		c2.7-1.2,5.7-1.4,9.2-0.5c1.4,0.4,2.7,1.2,3.7,2.6c2.5,3.3,4.1,7.1,5,11.5c0.1,0.2,0.1,0.5,0.2,0.7c0.1,1.1-0.1,1.8-0.5,2.2
		s-1.1,0.6-2.2,0.6s-2,0-2.5,0c-0.6,0-1.1-0.1-1.3-0.3c-0.2-0.2-0.3-0.6-0.2-1.2c0.1-1.9-0.5-4.3-1.7-7.2c-1.1-2.6-3-3.6-5.8-3
		c-1.3,0.3-1.9,1.1-1.7,2.4c0,0.1,0.2,0.7,0.6,1.8c0.5,1.7,0.8,2.6,0.8,2.7c0.6,2.3,0.4,4.4-0.7,6.3s-1.9,3.3-2.6,4.3
		c-2.3,3.3-2.8,7-1.6,11.1c0.6,1.7,1,2.9,1.2,3.4c1,2.4,2.7,3.4,5.2,3.2c1.9-0.2,2.6-1.1,2.2-2.9c-0.1-0.3-0.2-0.8-0.4-1.4
		c-0.2-0.6-0.3-1.1-0.4-1.3c-0.3-1.1-0.6-2.1-0.9-3.2c-0.2-1.1-0.8-1.7-1.8-1.8c-0.7-0.1-1.2-0.3-1.4-0.5c-0.2-0.2-0.3-0.6-0.2-1.2
		c0.1-1.3,0.7-2.1,1.8-2.2c0.2,0,1.4,0,3.8,0v-0.1C351.6,27.5,352.7,27.5,352.8,27.5z"
          />
        </g>
        <g>
          <path
            d="M8.8,52.7c1,0.1,1.5,0.6,1.3,1.5c-0.5,2.3,0,5.3,1.4,9l0.6,2c0.8,2.5,0.5,4.9-0.9,7.2c-1.1,1.9-2,3.2-2.4,3.9
		c-2.1,3.1-2.6,6.4-1.5,9.9c0.2,0.5,0.4,1.4,0.9,2.6C8.6,90,8.9,91,9.1,91.7c0.6,2.1,1,3.9,1.2,5.2c0.1,0.9,0,1.6-0.3,1.9
		s-0.9,0.5-1.8,0.5c-1.2,0.1-2.2,0.1-3,0c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.3-0.6-0.4-1.2c-0.1-1.5-0.6-3.9-1.7-7.2
		c-0.7-2.3-1.3-4.4-1.7-6.2c-0.5-2.2-0.2-4.4,1.1-6.6c0.1-0.2,0.7-1.2,1.8-3.1l1.5-2.3c1.3-2.2,1.6-4.6,0.8-7l-1.6-4.5
		c-0.7-2.2-0.9-4.2-0.5-5.9c0.2-0.8,0.4-1.4,0.7-1.6c0.3-0.3,0.8-0.4,1.7-0.4c0.1,0,0.5,0,1.2,0C8.2,52.7,8.8,52.7,8.8,52.7z"
          />
          <path
            d="M36.4,75.4c1.4-2.2,2.3-3.8,2.7-4.7c0.7-1.4,0.8-3,0.3-4.8c-0.3-1.1-0.9-2.8-1.6-4.8c-0.7-2.1-0.9-4.2-0.6-6.2
		c0.1-0.8,0.3-1.4,0.6-1.7s0.9-0.4,1.6-0.4c1.2-0.1,2.2-0.1,3,0c0.6,0,1.1,0.1,1.3,0.4c0.2,0.2,0.3,0.6,0.2,1.2
		c-0.4,2.5,0.1,5.5,1.5,8.9c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5c1.2,3.3,0.9,6.3-1,9.1c-0.1,0.1-0.9,1.4-2.4,3.8
		c-1.6,2.5-2,5.2-1.2,8.2c0.2,0.7,0.5,1.8,1,3.1c0.4,1.4,0.7,2.3,0.9,2.9c0.7,2.6,1.2,4.5,1.3,5.8c0.2,0.9,0.1,1.5-0.2,1.8
		s-0.9,0.5-1.9,0.5c-0.2,0-0.7,0-1.6,0c-0.9,0-1.4,0-1.5,0c-1,0-1.7-0.5-2-1.4c-0.6-1.7-2-4.1-4.1-7.2c-1.6-2.4-2.8-4.2-3.6-5.5
		c-1.9-3.1-2.4-6.1-1.5-9.1c0.1-0.2,0.1-0.5,0.2-0.9L28,74.9c-0.6,0.4-0.9,0.6-0.9,0.6c-2.2,2.9-3.1,5.7-2.6,8.6
		c0.1,0.3,0.6,2.2,1.6,5.9l1.8,6.6c0.1,0.2,0.1,0.5,0.2,0.9c0,0.7-0.1,1.2-0.4,1.4c-0.3,0.3-0.7,0.5-1.4,0.5c-1.2,0.1-2.3,0.1-3.4,0
		c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.3-0.6-0.3-1.2c-0.1-1.6-0.7-4-1.7-7.2c-0.8-2.4-1.4-4.5-1.8-6.2c-0.5-2.3-0.1-4.6,1.2-6.9
		c0.1-0.2,0.6-1.1,1.6-2.6l1.6-2.5c1.4-2.3,1.7-4.8,0.9-7.3l-2.1-4.6c-0.7-2.1-0.9-4.2-0.5-6.1c0.2-0.6,0.4-1.1,0.6-1.3
		c0.3-0.3,0.7-0.4,1.3-0.4c1.1-0.1,2.2-0.1,3.4,0c0.6,0,1.1,0.1,1.3,0.3c0.3,0.2,0.4,0.6,0.5,1.1c0.5,2.4,2,5.3,4.5,8.7
		c0.7,0.9,1.5,2.1,2.3,3.5c1.2,2,1.5,4.2,0.9,6.7c0,0.1-0.1,0.3-0.2,0.7c-0.1,0.4-0.2,0.6-0.2,0.8c-0.1,0.2-0.1,0.4-0.1,0.9
		c0,0.4,0,0.7,0.1,0.9C36,75.8,36.4,75.5,36.4,75.4z"
          />
          <path
            d="M73.8,52.7c0.6,0,1,0,1.2,0c0.8,0.2,1.2,0.6,1.2,1.4c0,1.2-0.1,2.6-0.2,4.1c-0.1,0.5-0.2,0.8-0.4,0.9
		c-0.2,0.2-0.6,0.2-1.1,0.2c-0.1,0.1-0.8,0.1-2.3,0.1s-2.3,0-2.3,0c-1.2,0-2,0.2-2.2,0.5s-0.1,1,0.3,2.1c0.5,1.7,0.9,3,1.2,3.9
		c0.5,2,0.3,3.9-0.7,5.7c-1,1.8-1.8,3.1-2.4,4c-2.4,3.4-3,7.3-1.6,11.6c1.2,3.5,1.8,5.4,1.9,5.7c0.4,1.5,0.7,3,0.9,4.5
		c0.1,0.7-0.1,1.2-0.3,1.5c-0.3,0.3-0.8,0.4-1.6,0.4c-1,0.1-2,0.1-3,0c-0.7,0-1.1-0.1-1.4-0.3c-0.3-0.2-0.4-0.6-0.4-1.2
		c-0.1-1.8-0.8-4.5-2-8.1c-0.6-1.8-1.1-3.4-1.4-4.8c-0.7-2.9-0.3-5.5,1.2-7.9c1.2-2.1,2.1-3.4,2.5-4.1c1.8-2.8,2.2-5.7,1-8.7l-1-2.9
		c-0.5-1.4-1.4-2-2.8-2c-0.8,0-2.4,0-4.8,0c-1-0.1-1.7-0.2-2-0.6c-0.4-0.3-0.5-1-0.5-1.9c0-0.8,0.1-1.6,0.2-2.5
		c0.1-0.7,0.3-1.1,0.6-1.4s0.8-0.4,1.4-0.4c0.3,0,1.8,0,4.4,0c0.7,0,2.8,0,6.3,0C69.4,52.7,72.7,52.7,73.8,52.7z"
          />
          <path
            d="M86.2,99.3c-0.8,0-1.4-0.1-1.6-0.4c-0.2-0.2-0.4-0.8-0.5-1.7c-0.3-1.6-0.8-3.7-1.5-6.3c-0.2-0.6-0.4-1.5-0.9-2.7
		C81.3,87,81,86,80.8,85.3c-0.8-3.1-0.3-6,1.4-8.7l2.5-3.9c1.6-2.4,1.9-5.1,0.9-7.9L84,60.3c-0.6-1.7-0.7-3.6-0.4-5.7
		c0.1-0.7,0.3-1.2,0.6-1.4c0.3-0.3,0.8-0.4,1.5-0.4c4.3-0.1,8.8-0.1,13.5,0c0.7,0,1.2,0.1,1.4,0.4c0.2,0.2,0.3,0.7,0.3,1.5
		c-0.1,1.3-0.2,2.4-0.2,3.3c0,0.6-0.1,1-0.3,1.2c-0.2,0.2-0.6,0.3-1.2,0.3c-0.5,0.1-1.5,0.1-3.1,0c-1.6,0-2.6,0-3.2,0
		c-1.1,0.1-1.7,0.2-2,0.5c-0.2,0.3-0.1,1,0.2,2c0.6,1.7,0.9,2.6,1,2.9c0.7,2.2,0.7,4.2-0.1,6.1c-0.3,0.7-0.3,1.2-0.2,1.4
		c0.2,0.2,0.6,0.4,1.4,0.4c0.5,0,1.3,0,2.4,0s1.9,0,2.5,0c0.6,0.1,1,0.2,1.2,0.4c0.2,0.2,0.1,0.6-0.2,1.2c-0.2,0.3-1,1.6-2.5,4.1
		c-0.4,0.7-1.2,1.1-2.3,1.1c-0.6,0-1.4,0-2.4,0s-1.8,0-2.4,0c-0.9,0.1-1.6,0.2-2,0.6c-0.4,0.3-0.6,1-0.6,1.9c0,1.2,0.1,2.3,0.4,3.4
		c0,0.2,0.5,1.9,1.6,5.2c0.4,1.3,1.4,2,2.8,2c3,0,5.1,0,6.3,0c0.9,0,1.5,0.1,1.9,0.4s0.6,0.9,0.8,1.8c0.2,0.8,0.3,1.6,0.4,2.3
		c0.1,0.9-0.1,1.6-0.3,1.8c-0.3,0.3-0.9,0.4-1.8,0.4c-0.4,0-2.6,0-6.6,0C88.7,99.3,86.5,99.3,86.2,99.3z"
          />
          <path
            d="M120.1,52.9c2.4,0.2,4.5,1.1,6.4,2.8c2.4,2.3,4.3,4.6,5.6,7c0.9,1.7,1.5,3.1,1.6,4.4c0.1,1.2-0.1,2.3-0.6,3.2
		c-0.5,0.9-1.4,1.9-2.7,2.9c-0.6,0.5-1.2,1-1.9,1.4c-0.7,0.5-1.5,1.1-2.5,1.7s-1.7,1.1-2.1,1.3c-0.1,0.1-0.3,0.2-0.6,0.4
		c-0.3,0.2-0.5,0.3-0.6,0.3c-0.7,0.4-1.1,0.8-1.2,1.2c-0.2,0.4-0.1,0.9,0.1,1.6c0.6,1.7,1.2,3.2,2,4.5c0.4,0.7,1,1.6,1.8,2.8
		c0.8,1.2,1.3,1.9,1.5,2.2l3.4,5.4c0.1,0.2,0.2,0.4,0.4,0.9c0.4,0.9,0.4,1.5,0.2,1.8s-0.8,0.5-1.7,0.5c-0.7,0.1-1.7,0.1-3.2,0
		c-1,0-1.7-0.4-2.1-1.3c-0.2-0.6-0.4-1.1-0.7-1.6c-0.2-0.5-0.6-1.1-1-1.8c-0.4-0.7-0.7-1.2-0.9-1.4c-0.2-0.3-0.6-0.8-1.1-1.7
		c-0.6-0.8-0.9-1.3-0.9-1.5c-1.2-1.9-2.5-3.8-3.7-5.8c-0.2-0.3-0.5-1.2-1.1-2.7c-0.3-0.7-0.7-1-1.2-0.9c-0.6,0.1-0.9,0.5-0.9,1.2
		c0,1.7,0.1,2.8,0.2,3.3c0.2,0.7,0.5,1.8,0.9,3.2c0.4,1.4,0.8,2.4,1,3c0.7,2.6,1.1,4.5,1.3,5.8c0.1,0.9,0,1.5-0.3,1.8
		s-0.9,0.5-1.9,0.5c-1.2,0.1-2.2,0.1-2.9,0c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.3-0.5-0.4-1.1c-0.1-0.8-0.2-1.7-0.4-2.6
		c-0.2-1-0.4-1.7-0.5-2.2s-0.4-1.4-0.9-2.6c-0.4-1.2-0.7-2-0.7-2.3c-0.8-2.2-1.2-4.2-1.2-5.8c0-1.8,0.5-3.5,1.4-5.2
		c1-1.8,1.9-3.1,2.6-4.1c1.9-2.8,2.2-5.9,1-9.1c-0.2-0.5-0.5-1.3-0.8-2.2c-0.3-1-0.5-1.5-0.5-1.6c-0.6-1.6-0.7-3.5-0.5-5.5
		c0.1-0.8,0.3-1.3,0.7-1.6c0.3-0.3,0.9-0.4,1.7-0.4c0.2,0,1.7,0,4.6,0C118.4,52.8,119.9,52.9,120.1,52.9z M125.2,70.9
		c1.4-1.2,1.9-2.7,1.5-4.5c-0.4-2-1.5-3.7-3.4-5.1c-1.8-1.4-3.7-1.9-5.8-1.7c-0.7,0.1-1.1,0.2-1.3,0.5c-0.2,0.3-0.2,0.7,0,1.3
		c0.1,0.4,0.3,1,0.6,1.8c0.3,0.8,0.5,1.4,0.6,1.7c0.7,2.4,0.6,4.7-0.4,6.7c-0.4,0.8-0.5,1.4-0.4,1.6s0.6,0.4,1.5,0.4
		c0.1,0,0.1,0,0.2,0h0.1C121,73.5,123.2,72.6,125.2,70.9z"
          />
          <path
            d="M158.4,75.4c1.4-2.2,2.3-3.8,2.7-4.7c0.7-1.4,0.8-3,0.3-4.8c-0.3-1.1-0.9-2.8-1.6-4.8c-0.7-2.1-0.9-4.2-0.6-6.2
		c0.1-0.8,0.3-1.4,0.6-1.7s0.9-0.4,1.6-0.4c1.2-0.1,2.2-0.1,3,0c0.6,0,1.1,0.1,1.3,0.4c0.2,0.2,0.3,0.6,0.2,1.2
		c-0.4,2.5,0.1,5.5,1.5,8.9c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5c1.2,3.3,0.9,6.3-1,9.1c-0.1,0.1-0.9,1.4-2.4,3.8
		c-1.6,2.5-2,5.2-1.2,8.2c0.2,0.7,0.5,1.8,1,3.1c0.4,1.4,0.7,2.3,0.9,2.9c0.7,2.6,1.2,4.5,1.3,5.8c0.2,0.9,0.1,1.5-0.2,1.8
		s-0.9,0.5-1.9,0.5c-0.2,0-0.7,0-1.6,0c-0.9,0-1.4,0-1.5,0c-1,0-1.7-0.5-2-1.4c-0.6-1.7-2-4.1-4.1-7.2c-1.6-2.4-2.8-4.2-3.6-5.5
		c-1.9-3.1-2.4-6.1-1.5-9.1c0.1-0.2,0.1-0.5,0.2-0.9l-0.2-0.5c-0.6,0.4-0.9,0.6-0.9,0.6c-2.2,2.9-3.1,5.7-2.6,8.6
		c0.1,0.3,0.6,2.2,1.6,5.9l1.8,6.6c0.1,0.2,0.1,0.5,0.2,0.9c0,0.7-0.1,1.2-0.4,1.4c-0.3,0.3-0.7,0.5-1.4,0.5c-1.2,0.1-2.3,0.1-3.4,0
		c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.3-0.6-0.3-1.2c-0.1-1.6-0.7-4-1.7-7.2c-0.8-2.4-1.4-4.5-1.8-6.2c-0.5-2.3-0.1-4.6,1.2-6.9
		c0.1-0.2,0.6-1.1,1.6-2.6l1.6-2.5c1.4-2.3,1.7-4.8,0.9-7.3l-1.6-4.6c-0.7-2.1-0.9-4.2-0.5-6.1c0.2-0.6,0.4-1.1,0.6-1.3
		c0.3-0.3,0.7-0.4,1.3-0.4c1.1-0.1,2.2-0.1,3.4,0c0.6,0,1.1,0.1,1.3,0.3c0.3,0.2,0.4,0.6,0.5,1.1c0.5,2.4,2,5.3,4.5,8.7
		c0.7,0.9,1.5,2.1,2.3,3.5c1.2,2,1.5,4.2,0.9,6.7c0,0.1-0.1,0.3-0.2,0.7c-0.1,0.4-0.2,0.6-0.2,0.8c-0.1,0.2-0.1,0.4-0.1,0.9
		c0,0.4,0,0.7,0.1,0.9C157.9,75.8,158.3,75.5,158.4,75.4z"
          />
          <path
            d="M189.1,52.7c0.7,0,1.2,0.1,1.4,0.4c0.2,0.2,0.4,0.7,0.4,1.4c-0.1,2.3,0.5,4.8,1.9,7.6c0.7,1.5,1.3,3,1.8,4.5
		c0.6,1.8,0.5,3.6-0.2,5.4c-0.8,1.8-1.5,3.2-2.1,4.2c-1.8,3.1-2,6.4-0.5,9.8c0.3,0.6,0.7,1.6,1.2,2.9c0.6,1.3,1,2.3,1.2,2.9
		c0.4,1.1,1,2.8,1.7,5c0.1,0.3,0.2,0.7,0.2,1.3c0,0.8-0.4,1.3-1.1,1.3c-1.4,0.1-2.7,0.1-4.1,0c-0.7-0.1-1.1-0.5-1.3-1.5
		c-0.3-1.2-0.5-2.1-0.7-2.7c-0.4-1.2-1.2-1.9-2.5-1.9c-2.4,0-3.7,0-3.7,0c-0.6,0.1-1,0.2-1.2,0.4c-0.2,0.2-0.3,0.6-0.2,1.2
		c0,0.1,0,0.6,0,1.4s0,1.3,0,1.4c-0.1,0.5-0.2,0.9-0.4,1.1s-0.6,0.4-1.2,0.4c-0.6,0.1-1.7,0.1-3.2,0c-0.9,0-1.3-0.4-1.2-1.1
		c0.1-1.2,0.1-2.6-0.2-3.9c-0.2-1.4-0.5-2.9-0.9-4.5s-0.7-2.9-0.8-3.8c-0.5-3.2,0.2-6.1,2-8.7l3.1-4.3c2-2.6,2.7-5.5,1.9-8.7
		c-0.3-0.8-0.5-2-0.9-3.6c-0.5-2-0.4-4,0.2-5.9c0.2-0.8,0.5-1.3,0.8-1.6s0.9-0.4,1.6-0.4c0.2,0,1.4,0,3.4,0
		C187.8,52.7,189,52.7,189.1,52.7z M186.3,64.5c-0.1,0.9-0.1,1.4-0.1,1.4c0.2,2.2-0.3,4.2-1.6,6l-1.8,2.5c-1.6,2.3-2.5,3.5-2.6,3.7
		c-1.2,1.9-1.8,4.1-1.6,6.6c0.1,1.3,0.7,2,1.7,2c1.1,0.1,2.4,0.1,3.7,0c0.7-0.1,1-0.5,0.9-1.2c0-0.2-0.1-0.5-0.2-0.8
		c-1-3-0.7-5.9,0.9-8.9c0.6-1.2,1.3-2.6,2-4.2c0.7-1.8,0.7-3.8-0.2-5.9c0-0.1-0.3-0.5-0.9-1.3L186.3,64.5z"
          />
          <path
            d="M222.9,52.7c0.6,0,1,0,1.2,0c0.8,0.2,1.2,0.6,1.2,1.4c0,1.2-0.1,2.6-0.2,4.1c-0.1,0.5-0.2,0.8-0.4,0.9
		c-0.2,0.2-0.6,0.2-1.1,0.2c-0.1,0.1-0.8,0.1-2.3,0.1s-2.3,0-2.3,0c-1.2,0-2,0.2-2.2,0.5s-0.1,1,0.3,2.1c0.5,1.7,0.9,3,1.2,3.9
		c0.5,2,0.3,3.9-0.7,5.7c-1,1.8-1.8,3.1-2.4,4c-2.4,3.4-3,7.3-1.6,11.6c1.2,3.5,1.8,5.4,1.9,5.7c0.4,1.5,0.7,3,0.9,4.5
		c0.1,0.7-0.1,1.2-0.3,1.5c-0.3,0.3-0.8,0.4-1.6,0.4c-1,0.1-2,0.1-3,0c-0.7,0-1.1-0.1-1.4-0.3c-0.3-0.2-0.4-0.6-0.4-1.2
		c-0.1-1.8-0.8-4.5-2-8.1c-0.6-1.8-1.1-3.4-1.4-4.8c-0.7-2.9-0.3-5.5,1.2-7.9c1.2-2.1,2.1-3.4,2.5-4.1c1.8-2.8,2.2-5.7,1-8.7l-1-2.9
		c-0.5-1.4-1.4-2-2.8-2c-0.8,0-2.4,0-4.8,0c-1-0.1-1.7-0.2-2-0.6c-0.4-0.3-0.5-1-0.5-1.9c0-0.8,0.1-1.6,0.2-2.5
		c0.1-0.7,0.3-1.1,0.6-1.4s0.8-0.4,1.4-0.4c0.3,0,1.8,0,4.4,0c0.7,0,2.8,0,6.3,0C218.5,52.7,221.9,52.7,222.9,52.7z"
          />
          <path
            d="M238.3,52.7c1,0.1,1.5,0.6,1.3,1.5c-0.5,2.3,0,5.3,1.4,9l0.6,2c0.8,2.5,0.5,4.9-0.9,7.2c-1.1,1.9-2,3.2-2.4,3.9
		c-2.1,3.1-2.6,6.4-1.5,9.9c0.2,0.5,0.4,1.4,0.9,2.6c0.4,1.2,0.7,2.2,0.9,2.9c0.6,2.1,1,3.9,1.2,5.2c0.1,0.9,0,1.6-0.3,1.9
		s-0.9,0.5-1.8,0.5c-1.2,0.1-2.2,0.1-3,0c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.3-0.6-0.4-1.2c-0.1-1.5-0.6-3.9-1.7-7.2
		c-0.7-2.3-1.3-4.4-1.7-6.2c-0.5-2.2-0.2-4.4,1.1-6.6c0.1-0.2,0.7-1.2,1.8-3.1l1.5-2.3c1.3-2.2,1.6-4.6,0.8-7l-1.6-4.5
		c-0.7-2.2-0.9-4.2-0.5-5.9c0.2-0.8,0.4-1.4,0.7-1.6c0.3-0.3,0.8-0.4,1.7-0.4c0.1,0,0.5,0,1.2,0C237.6,52.7,238.2,52.7,238.3,52.7z"
          />
          <path
            d="M250.7,94.3c-2-2.8-3.3-6.3-3.9-10.5c-0.4-2.3,0.1-4.6,1.5-6.8l2.5-4.1c1.7-2.7,2.1-5.5,1-8.4c-0.4-1.2-0.8-2.3-1.1-3.4
		c-0.6-2.1-0.2-3.9,1.3-5.5c1.4-1.4,2.9-2.2,4.7-2.7c2.1-0.5,4.5-0.6,7.1-0.2c1.9,0.3,3.5,1.3,4.7,3c2.1,2.9,3.7,6.3,4.7,10.4
		c0.4,1.9,0.1,3.8-0.9,5.5c-0.3,0.5-0.7,1.2-1.2,2.1c-0.6,0.9-1,1.7-1.2,2.2c-0.9,1.7-1.6,3-1.9,3.9c-0.6,1.5-0.5,3.4,0.1,5.8
		c0.1,0.4,0.4,1.5,0.9,3.1c0.1,0.2,0.1,0.4,0.2,0.7c0.1,0.3,0.2,0.5,0.2,0.7c0.8,2.4,0.6,4.4-0.5,6.1s-2.9,2.7-5.4,3
		c-0.1,0-1,0.1-2.8,0.2C256.7,99.7,253.4,98,250.7,94.3z M254.8,89.2c0.5,1.6,1.2,2.6,2.1,3.2c0.9,0.5,2.1,0.8,3.8,0.7
		c1.9-0.2,2.6-1.1,2.1-3c-0.1-0.2-0.4-1.2-1-3.1c-1.3-4.1-0.8-7.8,1.6-11.2c0.6-0.9,1.4-2.3,2.5-4.1c0.9-1.7,1.2-3.5,0.7-5.4
		c-0.4-1.7-0.9-3.3-1.6-4.8c-0.8-1.8-2.1-2.7-4.1-2.7c-0.2,0-0.4,0-0.7,0c-0.3,0-0.5,0-0.7,0c-1.9,0.2-2.6,1.1-2,3
		c0.1,0.5,0.4,1.4,0.9,2.7c1,3,0.7,5.8-0.9,8.4l-2.7,4.3c-1,1.8-1.5,3.4-1.5,4.9C253.3,84.1,253.8,86.5,254.8,89.2z"
          />
          <path
            d="M297,75.4c1.4-2.2,2.3-3.8,2.7-4.7c0.7-1.4,0.8-3,0.3-4.8c-0.3-1.1-0.9-2.8-1.6-4.8c-0.7-2.1-0.9-4.2-0.6-6.2
		c0.1-0.8,0.3-1.4,0.6-1.7c0.3-0.3,0.9-0.4,1.6-0.4c1.2-0.1,2.2-0.1,3,0c0.6,0,1.1,0.1,1.3,0.4c0.2,0.2,0.3,0.6,0.2,1.2
		c-0.4,2.5,0.1,5.5,1.5,8.9c0.1,0.1,0.1,0.3,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5c1.2,3.3,0.9,6.3-1,9.1c-0.1,0.1-0.9,1.4-2.4,3.8
		c-1.6,2.5-2,5.2-1.2,8.2c0.2,0.7,0.5,1.8,1,3.1c0.4,1.4,0.7,2.3,0.9,2.9c0.7,2.6,1.2,4.5,1.3,5.8c0.2,0.9,0.1,1.5-0.2,1.8
		c-0.3,0.3-0.9,0.5-1.9,0.5c-0.2,0-0.7,0-1.6,0s-1.4,0-1.5,0c-1,0-1.7-0.5-2-1.4c-0.6-1.7-2-4.1-4.1-7.2c-1.6-2.4-2.8-4.2-3.6-5.5
		c-1.9-3.1-2.4-6.1-1.5-9.1c0.1-0.2,0.1-0.5,0.2-0.9l-0.2-0.5c-0.6,0.4-0.9,0.6-0.9,0.6c-2.2,2.9-3.1,5.7-2.6,8.6
		c0.1,0.3,0.6,2.2,1.6,5.9l1.8,6.6c0.1,0.2,0.1,0.5,0.2,0.9c0,0.7-0.1,1.2-0.4,1.4c-0.3,0.3-0.7,0.5-1.4,0.5c-1.2,0.1-2.3,0.1-3.4,0
		c-0.6-0.1-1-0.2-1.2-0.4c-0.2-0.2-0.3-0.6-0.3-1.2c-0.1-1.6-0.7-4-1.7-7.2c-0.8-2.4-1.4-4.5-1.8-6.2c-0.5-2.3-0.1-4.6,1.2-6.9
		c0.1-0.2,0.6-1.1,1.6-2.6l1.6-2.5c1.4-2.3,1.7-4.8,0.9-7.3l-1.6-4.6c-0.7-2.1-0.9-4.2-0.5-6.1c0.2-0.6,0.4-1.1,0.6-1.3
		c0.3-0.3,0.7-0.4,1.3-0.4c1.1-0.1,2.2-0.1,3.4,0c0.6,0,1.1,0.1,1.3,0.3c0.3,0.2,0.4,0.6,0.5,1.1c0.5,2.4,2,5.3,4.5,8.7
		c0.7,0.9,1.5,2.1,2.3,3.5c1.2,2,1.5,4.2,0.9,6.7c0,0.1-0.1,0.3-0.2,0.7c-0.1,0.4-0.2,0.6-0.2,0.8c-0.1,0.2-0.1,0.4-0.1,0.9
		c0,0.4,0,0.7,0.1,0.9C296.6,75.8,297,75.5,297,75.4z"
          />
          <path
            d="M327.7,52.7c0.7,0,1.2,0.1,1.4,0.4c0.2,0.2,0.4,0.7,0.4,1.4c-0.1,2.3,0.5,4.8,1.9,7.6c0.7,1.5,1.3,3,1.8,4.5
		c0.6,1.8,0.5,3.6-0.2,5.4c-0.8,1.8-1.5,3.2-2.1,4.2c-1.8,3.1-2,6.4-0.5,9.8c0.3,0.6,0.7,1.6,1.2,2.9c0.6,1.3,1,2.3,1.2,2.9
		c0.4,1.1,1,2.8,1.7,5c0.1,0.3,0.2,0.7,0.2,1.3c0,0.8-0.4,1.3-1.1,1.3c-1.4,0.1-2.7,0.1-4.1,0c-0.7-0.1-1.1-0.5-1.3-1.5
		c-0.3-1.2-0.5-2.1-0.7-2.7c-0.4-1.2-1.2-1.9-2.5-1.9c-2.4,0-3.7,0-3.7,0c-0.6,0.1-1,0.2-1.2,0.4c-0.2,0.2-0.3,0.6-0.2,1.2
		c0,0.1,0,0.6,0,1.4s0,1.3,0,1.4c-0.1,0.5-0.2,0.9-0.4,1.1c-0.2,0.2-0.6,0.4-1.2,0.4c-0.6,0.1-1.7,0.1-3.2,0c-0.9,0-1.3-0.4-1.2-1.1
		c0.1-1.2,0.1-2.6-0.2-3.9c-0.2-1.4-0.5-2.9-0.9-4.5s-0.7-2.9-0.8-3.8c-0.5-3.2,0.2-6.1,2-8.7l3.1-4.3c2-2.6,2.7-5.5,1.9-8.7
		c-0.3-0.8-0.5-2-0.9-3.6c-0.5-2-0.4-4,0.2-5.9c0.2-0.8,0.5-1.3,0.8-1.6s0.9-0.4,1.6-0.4c0.2,0,1.4,0,3.4,0
		C326.4,52.7,327.6,52.7,327.7,52.7z M325,64.5c-0.1,0.9-0.1,1.4-0.1,1.4c0.2,2.2-0.3,4.2-1.6,6l-1.8,2.5c-1.6,2.3-2.5,3.5-2.6,3.7
		c-1.2,1.9-1.8,4.1-1.6,6.6c0.1,1.3,0.7,2,1.7,2c1.1,0.1,2.4,0.1,3.8,0c0.7-0.1,1-0.5,0.9-1.2c0-0.2-0.1-0.5-0.2-0.8
		c-1-3-0.7-5.9,0.9-8.9c0.6-1.2,1.3-2.6,2-4.2c0.7-1.8,0.7-3.8-0.2-5.9c0-0.1-0.3-0.5-0.9-1.3L325,64.5z"
          />
          <path
            d="M344.9,99.5c-0.9-0.1-1.5-0.2-1.8-0.5s-0.4-0.8-0.5-1.7c-0.1-1.4-0.6-3.5-1.5-6.3c-1-3-1.6-4.9-1.8-5.8
		c-0.7-2.9-0.3-5.7,1.2-8.2l2.7-4.3c1.6-2.4,1.9-5.1,0.9-7.9l-1.6-4.6c-0.6-1.6-0.7-3.3-0.5-5.3c0.1-0.9,0.3-1.5,0.6-1.8
		c0.3-0.3,0.9-0.4,1.8-0.4c1.1-0.1,2-0.1,2.7,0c0.7,0,1.2,0.2,1.4,0.4c0.2,0.3,0.3,0.7,0.2,1.4c-0.4,2.4,0,5,1.2,7.9
		c0.1,0.2,0.1,0.4,0.3,0.8c0.1,0.4,0.2,0.7,0.3,0.9c1.3,3.3,1,6.4-1,9.3c-0.2,0.3-1,1.6-2.4,3.7c-1.5,2.4-1.9,5.2-1.2,8.1
		c0.1,0.6,0.6,2.5,1.6,5.6c0.4,1.2,1.3,1.9,2.7,1.9c1.5-0.1,3.5-0.1,6.2,0c1,0,1.7,0.1,2.1,0.4s0.7,1,0.9,2c0.2,0.7,0.2,1.4,0.2,2
		c0.1,0.9-0.1,1.6-0.4,1.9s-0.9,0.5-1.8,0.5c-0.1,0-0.5,0-1.2,0c-1.8,0-3.6,0-5.3,0C347.2,99.5,345.1,99.5,344.9,99.5z"
          />
        </g>
        <g>
          <path
            d="M164.3,142.7c0,3-0.1,5.1-0.2,6.2c-0.1,0.8-0.3,1.3-0.6,1.7c-0.4,0.3-1,0.5-1.8,0.5c-1.2,0.1-2.3,0.1-3.3,0
		c-0.7,0-1.2-0.1-1.5-0.3s-0.5-0.6-0.7-1.3c-0.3-1-0.6-2-1.2-3.1c-0.5-1.1-1-2-1.3-2.7c-0.4-0.7-1-1.7-1.7-2.9
		c-0.7-1.2-1.3-2.1-1.6-2.7c-0.2-0.3-0.5-0.5-1-0.6c0.2,1,0.2,1.5,0.2,1.6c0.1,0.4,0.3,1.1,0.7,2.1c0.4,1.1,0.6,1.9,0.8,2.5
		c0.5,2.1,0.9,3.8,1.1,5.2c0.1,0.8-0.1,1.3-0.3,1.6c-0.3,0.3-0.8,0.4-1.6,0.5c-1.2,0.1-2.3,0.1-3.3,0c-0.6-0.1-1-0.2-1.2-0.4
		s-0.3-0.5-0.3-1.1c-0.1-0.8-0.2-1.7-0.4-2.6c-0.2-1-0.4-1.7-0.6-2.4c-0.2-0.6-0.5-1.5-0.8-2.6c-0.4-1.1-0.6-1.9-0.8-2.4
		c-0.5-1.5-0.8-3.2-1-4.9c-0.3-2,0.1-4,1.3-5.9c1.1-1.9,2-3.3,2.5-4.1c2.1-3,2.4-6.2,1.1-9.6c-0.2-0.6-0.7-1.9-1.3-3.9
		c-0.5-1.6-0.7-3.4-0.4-5.5c0.1-0.8,0.3-1.3,0.6-1.6c0.3-0.3,0.9-0.4,1.7-0.4c1.3-0.1,2.3-0.1,3.1,0c0.7,0,1.2,0.1,1.4,0.3
		c0.2,0.2,0.4,0.6,0.4,1.2c0.2,2.4,1.3,5.4,3.6,9c0.5,0.8,1.1,1.9,1.7,3.1c1.1,2.2,1.2,4.4,0.3,6.7l-1.4,3.9
		c-0.6,1.7-0.9,3.2-0.7,4.4c0.1,0.8,0.3,1.4,0.9,1.7c0.4-0.6,0.7-1,0.7-1c0.6-1.8,2-3.9,4-6.2l2.7-3.1c2.2-2.5,3.1-5.5,2.5-9
		c-0.1-0.6-0.2-1.7-0.3-3.5c-0.2-1.8,0.2-3.8,1.2-6.1c0.4-0.9,1-1.3,1.9-1.3c1.6-0.1,2.8-0.1,3.7,0c1.1,0.1,1.6,0.6,1.4,1.7
		c-0.4,2.2-0.1,4.9,1.1,8c0.4,1,0.7,2,1,3.3c0.7,2.3,0.4,4.5-0.7,6.6c-1.1,1.9-1.9,3.2-2.5,4c-2.4,3.4-2.9,7.1-1.6,11.1
		c0.1,0.3,0.4,1.2,0.9,2.5c0.5,1.4,0.8,2.4,1,3.2c0.5,1.7,0.8,3.3,1,4.8c0.1,0.8,0,1.4-0.3,1.7c-0.3,0.3-0.9,0.5-1.7,0.5
		c-1.3,0.1-2.4,0.1-3.3,0c-0.5-0.1-0.9-0.2-1.1-0.4s-0.3-0.6-0.3-1.1c-0.2-2.2-1-5.3-2.5-9.4c0-0.1,0-0.2-0.1-0.4
		c-0.1-0.2-0.1-0.3-0.2-0.3c0,0-0.1-0.1-0.2-0.3s-0.2-0.2-0.4-0.2c-0.1,0.1-0.3,0.1-0.5,0C164.2,141.4,164.3,142.6,164.3,142.7z"
          />
          <path
            d="M197.7,103.6c0.7,0,1.2,0.1,1.5,0.4c0.2,0.2,0.4,0.7,0.4,1.4c-0.1,2.3,0.5,4.9,1.9,7.7c0.7,1.5,1.3,3,1.8,4.5
		c0.6,1.9,0.6,3.7-0.2,5.5s-1.6,3.2-2.1,4.3c-1.8,3.2-2,6.5-0.6,10c0.3,0.6,0.7,1.6,1.3,2.9c0.6,1.3,1,2.3,1.3,2.9
		c0.4,1.1,1,2.8,1.7,5.1c0.1,0.3,0.2,0.7,0.2,1.3c0,0.8-0.4,1.3-1.1,1.3c-1.4,0.1-2.8,0.1-4.1,0c-0.7-0.1-1.1-0.6-1.3-1.5
		c-0.3-1.2-0.6-2.1-0.7-2.7c-0.4-1.3-1.2-1.9-2.5-1.9c-2.5,0-3.8,0-3.8,0c-0.6,0.1-1.1,0.2-1.3,0.4c-0.2,0.2-0.3,0.6-0.2,1.3
		c0,0.1,0,0.6,0,1.4c0,0.8,0,1.3,0,1.4c-0.1,0.5-0.2,0.9-0.4,1.2c-0.2,0.2-0.6,0.4-1.2,0.4c-0.6,0.1-1.7,0.1-3.3,0
		c-0.9,0-1.3-0.4-1.3-1.1c0.1-1.3,0.1-2.6-0.2-4c-0.2-1.4-0.5-2.9-0.9-4.6c-0.4-1.6-0.7-2.9-0.8-3.9c-0.5-3.3,0.2-6.2,2.1-8.9
		l3.2-4.4c2.1-2.6,2.7-5.6,1.9-8.9c-0.3-0.8-0.6-2.1-0.9-3.7c-0.5-2.1-0.4-4,0.2-6c0.2-0.8,0.5-1.3,0.8-1.6s0.9-0.4,1.7-0.4
		c0.2,0,1.4,0,3.5,0C196.4,103.6,197.6,103.6,197.7,103.6z M195,115.6c-0.1,0.9-0.1,1.4-0.1,1.4c0.2,2.2-0.3,4.3-1.6,6.1l-1.8,2.5
		c-1.6,2.3-2.5,3.6-2.6,3.7c-1.3,2-1.8,4.2-1.7,6.7c0.1,1.3,0.7,2,1.7,2.1c1.2,0.1,2.4,0.1,3.8,0c0.7-0.1,1.1-0.5,1-1.3
		c0-0.2-0.1-0.5-0.2-0.8c-1.1-3-0.7-6,1-9c0.6-1.2,1.3-2.6,2-4.3c0.7-1.9,0.7-3.8-0.2-6c0-0.1-0.3-0.5-0.9-1.3L195,115.6z"
          />
          <path
            d="M223.8,103.8c2.4,0.2,4.6,1.1,6.5,2.9c2.4,2.3,4.3,4.7,5.7,7.1c1,1.7,1.5,3.2,1.6,4.4c0.1,1.3-0.1,2.4-0.6,3.3
		s-1.4,1.9-2.7,3c-0.6,0.5-1.2,1-1.9,1.5c-0.7,0.5-1.6,1.1-2.5,1.7c-1,0.6-1.7,1.1-2.1,1.3c-0.1,0.1-0.3,0.2-0.6,0.4
		s-0.5,0.3-0.6,0.3c-0.7,0.4-1.1,0.8-1.3,1.2s-0.1,0.9,0.1,1.7c0.6,1.7,1.2,3.3,2,4.6c0.4,0.7,1,1.6,1.9,2.9c0.8,1.2,1.3,2,1.5,2.2
		l3.4,5.5c0.1,0.2,0.2,0.5,0.4,0.9c0.4,0.9,0.4,1.5,0.2,1.9c-0.2,0.3-0.8,0.5-1.7,0.5c-0.7,0.1-1.8,0.1-3.3,0
		c-1.1,0-1.8-0.4-2.1-1.3c-0.2-0.6-0.4-1.1-0.7-1.7c-0.2-0.5-0.6-1.1-1-1.8c-0.4-0.7-0.7-1.2-0.9-1.5s-0.6-0.9-1.2-1.7
		c-0.6-0.8-0.9-1.3-1-1.5c-1.3-1.9-2.5-3.9-3.7-5.9c-0.2-0.3-0.5-1.2-1.1-2.7c-0.3-0.7-0.7-1.1-1.3-1c-0.6,0.1-1,0.5-1,1.2
		c0,1.7,0.1,2.8,0.2,3.3c0.2,0.7,0.5,1.8,0.9,3.3c0.4,1.4,0.8,2.4,1,3c0.7,2.6,1.1,4.6,1.3,5.9c0.1,0.9,0,1.5-0.3,1.8
		S218,151,217,151c-1.3,0.1-2.2,0.1-2.9,0c-0.6-0.1-1-0.2-1.2-0.4s-0.3-0.5-0.4-1.1c-0.1-0.8-0.2-1.7-0.4-2.7s-0.4-1.7-0.5-2.2
		c-0.1-0.5-0.4-1.4-0.9-2.7s-0.7-2-0.8-2.3c-0.8-2.3-1.2-4.2-1.2-5.9c0-1.9,0.5-3.6,1.4-5.2c1.1-1.8,1.9-3.2,2.6-4.1
		c1.9-2.9,2.2-6,1-9.3c-0.2-0.5-0.5-1.3-0.8-2.3s-0.5-1.5-0.6-1.6c-0.6-1.6-0.7-3.5-0.5-5.6c0.1-0.8,0.3-1.3,0.7-1.6
		c0.3-0.3,0.9-0.4,1.7-0.4c0.2,0,1.7,0,4.7,0C222,103.7,223.6,103.8,223.8,103.8z M229,122.1c1.4-1.2,1.9-2.8,1.5-4.6
		c-0.4-2.1-1.6-3.8-3.4-5.2c-1.9-1.4-3.8-2-5.9-1.7c-0.7,0.1-1.1,0.2-1.3,0.5c-0.2,0.3-0.2,0.7,0,1.3c0.1,0.4,0.3,1,0.6,1.8
		s0.5,1.4,0.6,1.7c0.7,2.5,0.6,4.8-0.4,6.8c-0.4,0.8-0.5,1.4-0.4,1.6c0.1,0.2,0.6,0.4,1.5,0.4c0.1,0,0.1,0,0.2,0h0.1
		C224.7,124.8,226.9,123.9,229,122.1z"
          />
          <path
            d="M257.7,119.1c0.6-1.4,1.1-2.7,1.5-3.9c0.1-0.4,0.3-1.4,0.7-3.1c0.5-2.4,1.7-4.8,3.7-7.3c0.6-0.7,1.3-1.1,2.1-1.2
		c2-0.1,3.5-0.1,4.5,0c1.2,0.1,1.5,0.6,0.9,1.7c-0.1,0.2-0.3,0.5-0.7,1c-1.2,1.7-2.1,3.8-2.6,6c-0.3,1.4-0.6,2.5-0.7,3.1
		c-0.6,2.7-1.9,4.9-3.8,6.7c-3,2.8-3.7,6.5-2.1,11c0.4,1.1,1.3,2.8,2.9,5.1c0.5,0.7,1.3,1.8,2.3,3.1s1.8,2.2,2.2,2.8
		c1.2,1.7,2.2,3.3,3.1,4.8c0.7,1.4,0.3,2.1-1.2,2.1c-0.3,0-1.5,0-3.7,0c-1,0-1.7-0.5-2.4-1.5c-1.2-2.1-2.6-4.1-4-6.2
		c-0.4-0.5-1-1.3-1.8-2.3c-0.8-1-1.5-1.8-1.8-2.4c-0.2-0.3-1.3-2-3.2-5c-0.6-1-1.2-1.1-1.9-0.2c-1.2,1.4-1.6,2.8-1.1,4.4
		c0.1,0.2,0.3,0.9,0.7,2.1c0.4,1.3,0.7,2.2,1,2.9c0.7,2.6,1.2,4.6,1.3,5.8c0.2,1,0.1,1.6-0.2,1.9c-0.3,0.3-0.9,0.5-1.9,0.5
		c-0.6,0-1.6,0-3.2,0c-0.9-0.1-1.4-0.5-1.4-1.4c0-0.8-0.1-1.7-0.3-2.7c-0.2-1-0.4-1.8-0.6-2.3c-0.2-0.6-0.5-1.5-0.9-2.7
		c-0.4-1.2-0.7-2-0.8-2.3c-0.4-1.2-0.7-2.3-0.9-3.3c-0.5-2.8-0.1-5.3,1.2-7.5c1.1-1.9,2-3.3,2.6-4.3c2-2.9,2.3-6,1-9.3
		c-0.3-0.8-0.7-2.2-1.3-4c-0.6-1.6-0.7-3.5-0.4-5.6c0.1-0.7,0.3-1.2,0.6-1.5c0.3-0.3,0.8-0.4,1.5-0.4c1.2-0.1,2.2-0.1,3.2,0
		c0.6,0,1.1,0.1,1.3,0.4c0.2,0.2,0.3,0.7,0.2,1.3c-0.4,2.2,0.1,5.2,1.4,8.9c0.1,0.1,0.4,1.8,1.2,5c0,0.1,0.1,0.4,0.3,1.1
		C256.9,120.3,257.4,119.9,257.7,119.1z"
          />
          <path
            d="M282.7,151c-0.8,0-1.4-0.1-1.6-0.4s-0.4-0.8-0.5-1.7c-0.3-1.6-0.8-3.8-1.5-6.4c-0.2-0.6-0.5-1.5-0.9-2.8s-0.7-2.2-0.9-2.9
		c-0.8-3.2-0.3-6.1,1.4-8.9l2.5-4c1.6-2.5,1.9-5.2,1-8l-1.6-4.6c-0.6-1.7-0.7-3.6-0.4-5.8c0.1-0.7,0.3-1.2,0.6-1.5
		c0.3-0.3,0.8-0.4,1.5-0.4c4.3-0.1,8.9-0.1,13.7,0c0.7,0,1.2,0.1,1.4,0.4c0.2,0.2,0.3,0.8,0.3,1.5c-0.1,1.3-0.2,2.4-0.2,3.3
		c0,0.6-0.1,1.1-0.3,1.3c-0.2,0.2-0.6,0.3-1.2,0.3c-0.5,0.1-1.5,0.1-3.1,0c-1.6,0-2.7,0-3.2,0c-1.1,0.1-1.8,0.2-2,0.6
		c-0.2,0.3-0.1,1,0.2,2.1c0.6,1.7,0.9,2.7,1,2.9c0.7,2.2,0.7,4.3-0.1,6.2c-0.3,0.7-0.3,1.2-0.2,1.5c0.2,0.2,0.6,0.4,1.4,0.4
		c0.5,0,1.3,0,2.5,0c1.1,0,2,0,2.5,0c0.6,0.1,1,0.2,1.2,0.4c0.2,0.2,0.1,0.6-0.2,1.2c-0.2,0.3-1,1.7-2.5,4.2
		c-0.4,0.7-1.2,1.1-2.4,1.1c-0.6,0-1.5,0-2.5,0s-1.8,0-2.5,0c-1,0.1-1.6,0.3-2,0.6s-0.6,1-0.6,1.9c0,1.2,0.1,2.4,0.4,3.4
		c0,0.2,0.5,1.9,1.6,5.3c0.4,1.3,1.4,2,2.9,2c3,0,5.2,0,6.4,0c0.9,0,1.5,0.1,1.9,0.4c0.4,0.3,0.6,0.9,0.8,1.8
		c0.2,0.8,0.3,1.6,0.4,2.3c0.1,1-0.1,1.6-0.3,1.9c-0.3,0.3-0.9,0.4-1.8,0.4c-0.4,0-2.6,0-6.7,0C285.2,151,283,151,282.7,151z"
          />
          <path
            d="M325.4,103.6c0.6,0,1.1,0,1.3,0c0.8,0.2,1.2,0.6,1.2,1.4c0,1.2-0.1,2.6-0.2,4.2c-0.1,0.5-0.2,0.8-0.4,1
		c-0.2,0.2-0.6,0.2-1.1,0.2c-0.1,0.1-0.8,0.1-2.3,0.1s-2.3,0-2.3,0c-1.3,0-2,0.2-2.2,0.5c-0.2,0.3-0.1,1,0.3,2.1
		c0.5,1.7,0.9,3,1.2,4c0.5,2,0.3,3.9-0.7,5.8c-1,1.8-1.8,3.2-2.5,4.1c-2.5,3.5-3,7.4-1.6,11.8c1.2,3.5,1.9,5.5,1.9,5.8
		c0.4,1.5,0.7,3.1,0.9,4.6c0.1,0.7-0.1,1.3-0.3,1.5c-0.3,0.3-0.8,0.4-1.6,0.4c-1.1,0.1-2.1,0.1-3,0c-0.7,0-1.2-0.1-1.4-0.3
		c-0.3-0.2-0.4-0.6-0.4-1.3c-0.1-1.8-0.8-4.5-2-8.3c-0.6-1.8-1.1-3.4-1.4-4.8c-0.7-2.9-0.3-5.6,1.3-8c1.3-2.1,2.1-3.5,2.5-4.1
		c1.9-2.8,2.2-5.8,1-8.9l-1-2.9c-0.5-1.4-1.4-2-2.9-2c-0.8,0-2.5,0-4.8,0c-1-0.1-1.7-0.3-2.1-0.6c-0.4-0.3-0.6-1-0.6-1.9
		c0-0.8,0.1-1.6,0.2-2.5c0.1-0.7,0.3-1.2,0.6-1.4c0.3-0.3,0.8-0.4,1.5-0.4c0.3,0,1.8,0,4.4,0c0.7,0,2.9,0,6.4,0
		C321,103.6,324.4,103.6,325.4,103.6z"
          />
          <path
            d="M336.1,134.9c1.3-0.1,2.2,0.5,2.7,1.9c0.8,2.2,2.2,4.2,4,5.8c1.1,1,2.2,1.4,3.4,1.3c0.8-0.1,1.4-0.3,1.7-0.8
		c0.4-0.5,0.4-1.1,0.2-1.9c-0.3-1-0.8-2-1.5-3.3c-0.4-0.6-0.9-1.4-1.6-2.4s-1.2-1.7-1.5-2.2c-2.4-3.7-3.5-7.4-3.3-11
		c0.1-1.5-0.3-3.2-1.2-5c-1.1-2.5-1.7-3.9-1.8-4.2c-1.4-3.6-0.4-6.3,2.9-8.1c3.6-1.9,7.2-2.1,10.8-0.5c1.6,0.7,2.8,1.7,3.5,2.9
		c1.5,2.4,3,5,4.4,7.7c0.4,0.6,0.6,1.6,0.8,2.8c0.2,1-0.3,1.5-1.3,1.7c-0.2,0.1-0.6,0.1-1,0.1c-1.5,0-2.5-0.1-2.8-0.4
		c-0.3-0.2-0.6-1-1-2.3c-0.6-2.4-1.9-4.3-4-5.9c-1.2-0.8-2.4-1.2-3.6-1c-1,0.1-1.7,0.5-2.1,1.2c-0.4,0.7-0.5,1.5-0.1,2.5
		c0.2,0.5,0.6,1.2,1.1,2.1c0.5,0.8,1.2,1.9,2.1,3.2c1.5,2.2,2.4,4.7,2.6,7.5c0.2,2.4,0.9,4.8,2,7.1l2.3,4.6c0.5,1.1,0.8,1.8,1,2.1
		c1.1,2.4,1.2,4.4,0.4,6.2c-0.8,1.7-2.4,3-4.9,3.8c-4.3,1.3-8,0.5-11-2.6c-2.8-2.8-5-6.2-6.7-10.1c-0.1-0.3-0.2-0.5-0.2-0.7
		c-0.2-0.7-0.2-1.3,0-1.5c0.2-0.3,0.7-0.4,1.4-0.4c0.1,0,0.5,0,1.4,0V135h0.9L336.1,134.9L336.1,134.9z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Heading
