import React from "react"
import styled from "styled-components"

import Heading from "./heading"
import Name from "./name"
import Label from "../../elements/label"
import Image from "../../elements/image"

import media from "../../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  padding: 10vh 0 10vh;
  .heading {
    grid-row: 1 / 2;
  }
  .photo {
    grid-row: 2 / 3;
    position: relative;
    padding: 0 5vw 0 10vw;
    margin-bottom: 2rem;
    .mouse-link {
      transform: translateY(-50%);
      z-index: 1;
    }
  }
  .p {
    padding: 0 10vw 0;
    position: relative;
    p {
      font-size: 1rem;
      margin-bottom: 2rem;
    }
  }
  ${media.tablet`
    grid-template-columns: 2.5fr 0.75fr 1fr;
    grid-template-rows: auto auto;
    .heading {
      grid-row: 1 / 2;
      grid-column: 2 / 4;
    }
    .photo {
      grid-row: 2 / 3;
      grid-column: 2 / 4;
      padding: 0 2rem 0 0;
    }
    .p {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      padding: 5vh 0 0 10vw;
      p {
        font-size: 1.25rem;
        line-height: 1.75;
      }
    }
  `}
  ${media.laptop`
    grid-template-columns: 3fr 0.25fr 1fr;
    .p {
      padding: 10vh 0 0 20vw;
    }
  `}
`

const BlackCoffee = () => {
  return (
    <Container>
      <div className="photo">
        <Image
          src="AboutPage/black_coffee.jpg"
          alt="Sound Idea Sessions | About - Black Coffee"
        />
        <Name />
        <Label
          to="/black-coffee"
          fontSize={0.8}
          outerRotate={0}
          innerRotate={0}
          value="More about Black Coffee"
          className="mouse-link"
        />
      </div>
      <div className="heading">
        <Heading />
      </div>
      <div className="p">
        <p>
          While talent, authenticity, business savvy and an incredible work
          ethic were all key ingredients to Black Coffee becoming one of the
          most prominent electronic music producers of our time, there is no
          doubt that his unique ability to defy convention has fuelled his
          meteoric rise to international stardom.
        </p>
        <p>
          During this moderated conversation Black Coffee and Ryan Holiday, will
          unpack some of the techniques and strategies employed by Black Coffee
          and his team in conquering international markets.
        </p>
        <p>
          Delegates will gain unique insights into the power of having an
          uncompromising brand vision and how this has resulted in Black
          Coffee&apos;s induction into the Guinness Book of World Records, as
          well as being held up as an example of possibility for the high level
          of engagement with his millions of social media followers.
        </p>
      </div>
    </Container>
  )
}

export default BlackCoffee
