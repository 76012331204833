import React from "react"
import styled from "styled-components"

import Heading from "./heading"
import Name from "./name"
import Label from "../../elements/label"
import Image from "../../elements/image"

import media from "../../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: "photo photo" "p1 p1" "p2 p2" "p3 p3";
  grid-gap: 2rem;
  padding: 10vh 10vw 0;
  .photo {
    grid-area: photo;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mouse-link {
      transform: translateY(-50%);
    }
  }
  .p {
    p {
      font-size: 1rem;
    }
  }
  .p1 {
    grid-area: p1;
  }
  .p2 {
    grid-area: p2;
  }
  .p3 {
    grid-area: p3;
  }
  ${media.tablet`
    padding: 15vh 2rem 5vh;
    grid-template-columns: 1.25fr 0.75fr 2fr;
    grid-template-rows: auto auto;
    grid-template-areas: "photo p1 p1" "p2 p2 p3";
    grid-gap: 2rem;
    .photo {
      grid-area: photo;
    }
    .p {
      p {
        font-size: 1.25rem;
        line-height: 1.75;
      }
    }
    .p1 {
      grid-area: p1;
      padding-left: 5vw;
      padding-right: 15vw;
    }
    .p2 {
      grid-area: p2;
    }
    .p3 {
      grid-area: p3;
      padding-top: 4rem;
    }
  `}
`

const RyanHoliday = () => {
  return (
    <Container>
      <div className="photo">
        <Image
          src="AboutPage/ryan_holiday.jpg"
          alt="Sound Idea Sessions | About - Ryan Holiday"
        />
        <Heading />
        <Name />
        <Label
          to="/ryan-holiday"
          fontSize={0.8}
          outerRotate={0}
          innerRotate={0}
          value="More about Ryan Holiday"
          className="mouse-link"
        />
      </div>
      <div className="p p1">
        <p>
          Your new business went online yesterday, and you&apos;ve got a
          marketing budget of zero. How are you supposed to create a movement
          around your product? How can you get to your first thousand - or
          million - customers? Starting from zero, it feels impossible. Enter
          the growth hacker. You may not have heard of growth hacking yet, but
          you&apos;ve certainly used the billion-dollar brands built by it:
          AirBnB, Facebook, Dropbox, amongst many others. Growth hackers thrive
          on doing what traditional businessmen would consider impossible:
          creating something from nothing. They &apos;hack&apos; their
          company&apos;s growth to create a narrative of sensational success,
          turning excited media, users and social media into a viral marketing
          force that will help their business grow exponentially. Silicon Valley
          has realized that growth hacking - not television commercials and
          billboards - is the successful start- up&apos;s secret weapon. Now
          growth hacker extraordinaire Ryan Holiday is ready to share his
          experience, teaching you how to harness the power of growth to propel
          you to success. Featuring insights from leading growth hackers, Growth
          Hacker Marketing is the essential guide to the revolutionary new
          approach to growing your business.
        </p>
      </div>
      <div className="p p2">
        <p>
          Growth hackers have thrown out the old marketing playbook (and big
          budgets and PR teams) and replaced it with tools that are testable,
          trackable, and scalable. Tools that WORK. This Growth Hacker Marketing
          Masterclass will unpack the new marketing rules and provide delegates
          with a road map to the future of marketing packed with valuable
          examples and case studies.
        </p>
      </div>
      <div className="p p3">
        <p>
          Getting free media attention is a powerful and often misunderstood
          communication tool. Join Ryan on how to create smart, savvy and
          provocative PR campaigns. Whether you are a business owner, marketing
          exec, freelance artist or a creative entrepreneur, the Growth Hacker
          Marketing Masterclass will teach you how to get the right kind of
          attention, without paying for it.
        </p>
      </div>
    </Container>
  )
}

export default RyanHoliday
