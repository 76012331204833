import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

const Container = styled.div`
  position: absolute;
  width: 50%;
  overflow: hidden;
  bottom: 3rem;
  right: 0;
  svg {
    enable-background: new 0 0 530.7 334.1;
    fill: rgba(0, 0, 0, 0.35);
    transform: translate(50%, 0rem);
  }
  ${media.tablet`
    svg {}
  `}
`

const Name = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 530.7 334.1">
        <g>
          <path
            d="M44.7,0.2C53.6,0.5,61,3.8,67,10.1c7,7.5,13.2,15.8,18.6,24.9c0.4,0.7,0.9,1.8,1.6,3.4c2.3,5.2,3,9.9,2.4,13.9
		c-0.7,4-3,8-6.8,12c-3.8,3.8-7.4,6.9-10.7,9.2c-5.2,3.5-7.1,8.2-5.5,14.1c1.1,3.3,3.1,11.9,6.3,25.7c0.9,4.2,1.5,7.6,1.8,10.2
		c1.2,8.6-0.4,15.9-4.9,21.9c-4.4,6-11,9.6-19.5,10.6c-3.8,0.4-12.7,0.5-26.5,0.5c-1.9,0-3.2-0.4-3.9-1.2c-0.7-0.8-1.1-2.1-1.3-4.1
		c0-1.9-0.1-3.8-0.4-5.6c-0.3-1.8-0.7-3.9-1.3-6.2c-0.6-2.3-1.1-3.9-1.4-5c-0.4-1-1-3-2-6c-1-3-1.5-4.7-1.7-5.2
		c-2.1-6.5-3.6-11.4-4.5-14.9c-1.9-9.4-0.6-18,3.9-25.7c3.8-6.1,6.9-10.9,9.2-14.4c5.8-9.1,6.8-18.7,3.1-28.8l-4.7-13.9
		c-1.9-5.6-2.4-11.9-1.6-18.9c0.4-2.4,1-4.1,2.1-5c1-0.9,2.8-1.4,5.2-1.6h1h9.2C41,0.2,44.3,0.2,44.7,0.2z M37.1,130.9
		c1,2.5,2.8,3.7,5.1,3.8c2.4,0.1,4.3-0.9,5.9-3c2.4-3,3.4-6.7,2.9-11.3c-0.7-8.6-3.3-16.5-7.9-23.8c-1.2-2.3-2.8-4-4.7-5.2
		c-1.9-1.2-3.8-1.5-5.5-0.8c-1.7,0.7-2.9,2.2-3.4,4.4c0,0.2-0.2,1.7-0.5,4.7C29.3,108.7,32,119.1,37.1,130.9z M60.3,32.8
		c-4.5-5.7-9-9.1-13.5-10.4h-0.3c-1.7-0.4-3-0.2-3.8,0.5c-0.8,0.7-1,1.9-0.7,3.7c0.3,1.9,1.1,4.5,2.4,7.9
		c4.4,11.5,4.8,21.1,1.3,28.8c-0.7,1.4-0.7,2.5,0,3.3c0.7,0.8,1.9,0.9,3.7,0.4c6.3-1.6,11.2-4.9,14.7-10c0.2-0.4,1.3-3.2,3.4-8.6
		C67.1,43.7,64.7,38.5,60.3,32.8z"
          />
          <path
            d="M128.1,157.1c-3.1-0.2-5.2-0.7-6-1.6c-0.9-0.9-1.4-2.8-1.6-5.8c-0.4-4.5-2-11.6-5-21.2c-3.3-10-5.3-16.4-6-19.4
		c-2.4-9.8-1-19,4.2-27.5l9.2-14.4c5.2-8.2,6.3-17,3.1-26.5l-5.2-15.5c-1.9-5.2-2.5-11.2-1.6-17.8c0.4-3,1.1-4.9,2.1-5.9
		c1-1,3.1-1.4,6-1.4C130.9,0,134,0,136.5,0c2.4,0.1,4.1,0.6,4.8,1.4c0.8,0.9,1,2.4,0.7,4.7c-1.2,8,0.1,16.9,3.9,26.5
		c0.2,0.5,0.5,1.4,0.9,2.6c0.4,1.2,0.7,2.2,0.9,2.9c4.4,11,3.2,21.4-3.4,31.2c-0.7,1-3.4,5.2-8.1,12.6c-5.1,8.2-6.4,17.3-3.9,27.3
		c0.4,2.1,2.2,8.4,5.5,18.9c1.4,4.2,4.5,6.3,9.2,6.3c4.9-0.2,11.8-0.2,20.7,0c3.5,0,5.9,0.5,7.1,1.4c1.2,1,2.2,3.2,2.9,6.7
		c0.5,2.4,0.8,4.6,0.8,6.5c0.2,3.1-0.2,5.2-1.2,6.3c-1,1-2.9,1.7-5.9,1.8c-0.4,0-1.7,0-4.2,0c-6.1,0-12.1,0-17.8,0
		C135.8,157.1,128.8,157.1,128.1,157.1z"
          />
          <path
            d="M248.6,0.2c2.4,0,4.1,0.4,4.8,1.2c0.8,0.8,1.2,2.3,1.2,4.6c-0.4,7.7,1.7,16.2,6.3,25.4c2.3,5.1,4.3,10,6,14.9
		c2.1,6.1,1.8,12.1-0.8,18.1c-2.8,5.9-5.2,10.7-7.1,14.1c-5.9,10.5-6.6,21.5-1.8,33c0.9,2.1,2.3,5.3,4.2,9.7
		c1.9,4.4,3.3,7.6,4.2,9.7c1.4,3.7,3.3,9.3,5.8,16.8c0.4,0.9,0.6,2.4,0.8,4.5c0,2.8-1.2,4.3-3.7,4.5c-4.6,0.2-9.1,0.2-13.6,0
		c-2.3-0.2-3.8-1.8-4.5-5c-1-4-1.8-7-2.4-8.9c-1.2-4.2-4-6.3-8.4-6.3c-8.2,0-12.4,0-12.6,0c-2.1,0.2-3.5,0.6-4.2,1.3
		c-0.7,0.7-1,2.1-0.8,4.2c0,0.4,0,1.9,0.1,4.7c0.1,2.8,0,4.4-0.1,4.7c-0.2,1.7-0.6,3-1.3,3.8c-0.7,0.8-2,1.2-3.9,1.2
		c-2.1,0.2-5.7,0.2-10.7,0c-3,0-4.4-1.2-4.2-3.7c0.4-4.2,0.2-8.6-0.5-13.2c-0.7-4.6-1.7-9.6-3-15.1c-1.3-5.4-2.2-9.7-2.8-12.8
		c-1.6-10.8,0.7-20.6,6.8-29.4L213,67.8c6.8-8.7,8.9-18.5,6.3-29.4c-0.9-2.8-1.8-6.8-2.9-12c-1.6-6.8-1.4-13.4,0.5-19.7
		c0.7-2.6,1.6-4.4,2.6-5.2s2.9-1.3,5.5-1.3c0.7,0,4.5,0,11.5,0C244.2,0.2,248.3,0.2,248.6,0.2z M239.4,39.7c-0.2,3-0.3,4.5-0.3,4.7
		c0.7,7.3-1,14.1-5.2,20.2l-6,8.4c-5.4,7.7-8.3,11.8-8.6,12.3c-4.2,6.5-6,13.9-5.5,22.3c0.4,4.4,2.3,6.6,5.8,6.8
		c3.8,0.4,8,0.4,12.6,0c2.4-0.2,3.5-1.6,3.1-4.2c0-0.7-0.2-1.6-0.5-2.6c-3.5-10-2.4-19.9,3.1-29.9c2.1-4,4.3-8.7,6.6-14.1
		c2.4-6.1,2.2-12.7-0.8-19.7c0-0.2-1-1.7-2.9-4.5L239.4,39.7z"
          />
          <path
            d="M348.2,49.2c-1.1-0.7-2.2-3.4-3.4-8.1c-0.7-2.6-2.1-6.4-4.2-11.3c-1.6-4-4.1-6.7-7.6-8.1c-3.3-1.4-6.1-1.1-8.4,0.8
		c-2.1,1.7-2.6,4.3-1.6,7.6l3.7,11.3c3,8.9,2,17.3-2.9,25.2l-8.4,13.6c-6.3,9.6-7.9,20.1-4.7,31.4c1.2,4.7,2.7,9.4,4.5,14.1
		c1.6,4.2,4.2,7.1,7.9,8.6c3.3,1.6,6.1,1.5,8.4-0.3c2.3-1.6,2.9-4.2,1.8-7.9c-0.4-1-1-3-2-6s-1.6-5.3-2-7.1c-0.7-2.4-0.7-4.1,0-5.1
		c0.7-1,2.4-1.5,5-1.7c2.8,0,5.4,0,7.9,0c2.5,0.2,4.1,0.6,5.1,1.3c0.9,0.7,1.8,2.2,2.5,4.5l4.5,14.4c2.4,7.5,2,14-1.4,19.5
		c-3.4,5.5-9,8.9-16.9,10.1c-9.6,1.6-18-0.7-25.2-6.8c-6.1-4.9-10.7-10.7-13.9-17.3c-4-8.7-6.7-17.5-8.1-26.2
		c-1.2-7.7,0-14.9,3.7-21.8c0.4-0.9,2.1-3.8,5.2-8.9l5.5-8.9c4.7-7.9,5.7-16,2.9-24.4L302.1,30c-2.8-8.7-0.7-16,6.3-21.8
		c6.5-5.4,13.6-8.1,21.5-8.1c8.7,0,15.7,3.2,21,9.7c7,9.1,12.2,20.5,15.7,34.3c0.5,2.1,0.4,3.6-0.3,4.5c-0.7,0.9-2.2,1.4-4.4,1.6
		c-0.2,0-1.7,0-4.7,0C352.2,50.2,349.3,49.9,348.2,49.2z"
          />
          <path
            d="M431.8,51.3c2.1-4.7,3.8-9,5-12.8c0.3-1.2,1.1-4.6,2.4-10.2c1.7-7.9,5.8-15.9,12-24.1c1.9-2.5,4.3-3.8,7.1-3.9
		c6.5-0.2,11.4-0.2,14.9,0c4,0.2,5,2,2.9,5.5c-0.2,0.5-1,1.7-2.4,3.4c-4,5.8-6.9,12.4-8.6,19.9c-1,4.7-1.8,8.1-2.4,10.2
		c-2.1,8.9-6.3,16.3-12.6,22.3c-9.8,9.3-12.1,21.4-7.1,36.4c1.2,3.7,4.5,9.3,9.7,16.8c1.7,2.4,4.3,5.8,7.7,10.1
		c3.4,4.3,5.8,7.4,7.2,9.3c4,5.6,7.4,10.8,10.2,15.7c2.4,4.5,1.1,6.9-3.9,7.1c-1,0-5.1,0-12,0c-3.1,0-5.8-1.7-7.9-5
		c-4-6.8-8.5-13.6-13.4-20.4c-1.2-1.7-3.2-4.3-6-7.7c-2.8-3.4-4.8-6.1-6-8c-0.7-1-4.2-6.5-10.5-16.5c-1.9-3.3-4-3.6-6.3-0.8
		c-4,4.5-5.2,9.4-3.7,14.4c0.2,0.5,1,2.9,2.4,7.1c1.4,4.2,2.4,7.4,3.1,9.7c2.4,8.7,3.9,15.1,4.5,19.1c0.5,3.1,0.3,5.2-0.7,6.3
		c-1,1.1-3.1,1.6-6.4,1.6c-1.9,0-5.4,0-10.5,0c-3-0.2-4.5-1.7-4.7-4.7c0-2.6-0.4-5.5-1.1-8.8c-0.7-3.2-1.4-5.8-2-7.7
		c-0.6-1.9-1.6-4.8-2.9-8.8c-1.3-3.9-2.2-6.5-2.8-7.7c-1.2-3.8-2.2-7.5-2.9-11c-1.7-9.3-0.4-17.5,3.9-24.6c3.7-6.3,6.5-11,8.6-14.2
		c6.5-9.4,7.6-19.7,3.4-30.7c-0.9-2.8-2.4-7.3-4.5-13.4c-1.9-5.4-2.4-11.6-1.3-18.6c0.4-2.3,1-3.9,2.1-4.9c1-1,2.7-1.4,5-1.4
		c3.8-0.2,7.3-0.2,10.5,0c2.1,0,3.5,0.4,4.3,1.2c0.8,0.8,1,2.2,0.7,4.3c-1.2,7.3,0.4,17.1,4.7,29.4c0.2,0.4,1.5,5.9,3.9,16.5
		c0,0.2,0.4,1.4,1.1,3.7C429.1,55.2,430.8,53.9,431.8,51.3z"
          />
          <path
            d="M59.9,226.3c-1.1-0.7-2.2-3.4-3.4-8.1c-0.7-2.6-2.1-6.4-4.2-11.3c-1.6-4-4.1-6.7-7.6-8.1c-3.3-1.4-6.1-1.1-8.4,0.8
		c-2.1,1.7-2.6,4.3-1.6,7.6l3.7,11.3c3,8.9,2,17.3-2.9,25.2l-8.4,13.6c-6.3,9.6-7.9,20.1-4.7,31.4c1.2,4.7,2.7,9.4,4.5,14.1
		c1.6,4.2,4.2,7.1,7.9,8.6c3.3,1.6,6.1,1.5,8.4-0.3c2.3-1.6,2.9-4.2,1.8-7.9c-0.4-1-1-3-2-6s-1.6-5.3-2-7.1c-0.7-2.4-0.7-4.1,0-5.1
		c0.7-1,2.4-1.5,5-1.7c2.8,0,5.4,0,7.9,0c2.5,0.2,4.1,0.6,5.1,1.3c0.9,0.7,1.8,2.2,2.5,4.5l4.5,14.4c2.4,7.5,2,14-1.4,19.5
		c-3.4,5.5-9,8.9-16.9,10.1c-9.6,1.6-18-0.7-25.2-6.8c-6.1-4.9-10.7-10.7-13.9-17.3c-4-8.7-6.7-17.5-8.1-26.2
		c-1.2-7.7,0-14.9,3.7-21.8c0.4-0.9,2.1-3.8,5.2-8.9l5.5-8.9c4.7-7.9,5.7-16,2.9-24.4l-3.9-11.8c-2.8-8.7-0.7-16,6.3-21.8
		c6.5-5.4,13.6-8.1,21.5-8.1c8.7,0,15.7,3.2,21,9.7c7,9.1,12.2,20.5,15.7,34.3c0.5,2.1,0.4,3.6-0.3,4.5c-0.7,0.9-2.2,1.4-4.4,1.6
		c-0.2,0-1.7,0-4.7,0C64,227.3,61,227,59.9,226.3z"
          />
          <path
            d="M109,316.9c-6.6-9.4-11-21.2-13.1-35.4c-1.2-7.9,0.4-15.5,5-22.8l8.4-13.6c5.8-8.9,6.9-18.3,3.4-28.3
		c-1.4-4-2.6-7.8-3.7-11.3c-2.1-7-0.6-13.1,4.4-18.3c4.5-4.5,9.8-7.5,15.7-8.9c7.2-1.7,15.1-1.9,23.8-0.5c6.5,1,11.7,4.4,15.7,10
		c7.2,9.6,12.4,21.2,15.7,34.9c1.4,6.5,0.4,12.7-2.9,18.6c-0.9,1.6-2.3,3.9-4.2,7.1c-1.9,3.1-3.3,5.6-4.2,7.3
		c-3.1,5.6-5.2,10-6.3,13.1c-1.9,5.1-1.8,11.5,0.3,19.4c0.3,1.4,1.3,4.9,2.9,10.5c0.2,0.5,0.4,1.3,0.8,2.2c0.3,1,0.6,1.7,0.8,2.2
		c2.6,8,2.1,14.9-1.6,20.4c-3.7,5.6-9.7,8.9-18.1,10c-0.4,0-3.5,0.2-9.4,0.5C129.1,335,117.9,329.3,109,316.9z M122.6,299.9
		c1.7,5.2,4.1,8.8,7.1,10.6s7.2,2.6,12.6,2.2c6.5-0.5,8.8-3.8,7.1-10c-0.2-0.7-1.3-4.2-3.4-10.5c-4.4-13.8-2.5-26.3,5.5-37.5
		c1.9-3,4.7-7.6,8.4-13.9c3.1-5.8,3.9-11.8,2.4-18.1c-1.4-5.8-3.1-11.2-5.2-16.3c-2.6-6.1-7.2-9.1-13.6-8.9c-0.5,0-1.3,0-2.2,0
		c-1,0-1.8,0-2.5,0c-6.3,0.5-8.6,3.8-6.8,10c0.3,1.7,1.3,4.8,2.9,9.2c3.5,10,2.4,19.3-3.1,28l-8.9,14.4c-3.5,5.9-5.2,11.5-5,16.5
		C117.6,282.6,119.3,290.6,122.6,299.9z"
          />
          <path
            d="M264.9,177.3c2.8,0,4.5,0.4,5.2,1.2c0.7,0.8,1,2.7,0.8,5.6c0,2.6-0.1,6.1-0.3,10.5c0,2.1-0.4,3.5-1.1,4.2
		c-0.7,0.7-2,1.1-3.9,1.1c-1.6,0.2-5.1,0.2-10.5,0.1c-5.4-0.1-8.9-0.1-10.5-0.1c-3.7,0.2-5.9,0.7-6.6,1.7s-0.4,3.3,0.8,7
		c2.1,6.5,3.4,10.7,3.9,12.8c1.6,5.4,1.3,11-0.8,16.8c-1,2.8-1.3,4.6-0.7,5.4c0.6,0.8,2.5,1.2,5.6,1.2c1.9,0,4.5,0,7.7,0
		c3.2,0,5.9,0,8,0c2.1,0.2,3.4,0.7,3.8,1.4c0.4,0.8,0.1,2.1-0.9,4.1l-7.9,13.1c-1.6,2.8-4.3,4.2-8.1,4.2c-10.5,0-15.9,0-16.3,0
		c-3.3,0.2-5.6,0.9-6.8,2.1c-1.2,1.2-1.8,3.5-1.8,6.8c0.2,4.7,0.9,9.6,2.1,14.7c0.5,2.3,1.5,5.5,3,9.7c1.5,4.2,2.5,7.3,3,9.2
		c1.7,6.3,3,11.5,3.7,15.7c0.3,3.1,0,5.2-0.9,6.3c-1,1-3,1.6-6.2,1.6c-4.4,0-7.7,0-10,0c-1.7-0.2-3-0.6-3.8-1.2
		c-0.8-0.6-1.3-1.7-1.4-3.3c-0.2-3.8-0.7-7.6-1.6-11.3s-2.2-8.1-4.1-13.4c-1.8-5.2-3-8.7-3.5-10.5c-1.4-4.2-2.3-7.7-2.6-10.5
		c-1.4-9.1,0.2-17.3,4.7-24.6l9.2-14.4c5.2-8.2,6.3-17,3.1-26.5l-5.2-15.2c-1.9-5.6-2.5-12-1.6-19.1c0.4-2.3,1.1-3.9,2.1-4.9
		c1-1,2.8-1.4,5.2-1.4c1.4,0,9,0,22.8,0C256.3,177.3,263.7,177.3,264.9,177.3z"
          />
          <path
            d="M350.4,177.3c2.8,0,4.5,0.4,5.2,1.2c0.7,0.8,1,2.7,0.8,5.6c0,2.6-0.1,6.1-0.3,10.5c0,2.1-0.4,3.5-1.1,4.2
		c-0.7,0.7-2,1.1-3.9,1.1c-1.6,0.2-5.1,0.2-10.5,0.1c-5.4-0.1-8.9-0.1-10.5-0.1c-3.7,0.2-5.9,0.7-6.6,1.7s-0.4,3.3,0.8,7
		c2.1,6.5,3.4,10.7,3.9,12.8c1.6,5.4,1.3,11-0.8,16.8c-1,2.8-1.3,4.6-0.7,5.4c0.6,0.8,2.5,1.2,5.6,1.2c1.9,0,4.5,0,7.7,0
		c3.2,0,5.9,0,8,0c2.1,0.2,3.4,0.7,3.8,1.4c0.4,0.8,0.1,2.1-0.9,4.1l-7.9,13.1c-1.6,2.8-4.3,4.2-8.1,4.2c-10.5,0-15.9,0-16.3,0
		c-3.3,0.2-5.6,0.9-6.8,2.1c-1.2,1.2-1.8,3.5-1.8,6.8c0.2,4.7,0.9,9.6,2.1,14.7c0.5,2.3,1.5,5.5,3,9.7c1.5,4.2,2.5,7.3,3,9.2
		c1.7,6.3,3,11.5,3.7,15.7c0.3,3.1,0,5.2-0.9,6.3s-3,1.6-6.2,1.6c-4.4,0-7.7,0-10,0c-1.7-0.2-3-0.6-3.8-1.2
		c-0.8-0.6-1.3-1.7-1.4-3.3c-0.2-3.8-0.7-7.6-1.6-11.3c-0.9-3.7-2.2-8.1-4.1-13.4c-1.8-5.2-3-8.7-3.5-10.5
		c-1.4-4.2-2.3-7.7-2.6-10.5c-1.4-9.1,0.2-17.3,4.7-24.6l9.2-14.4c5.2-8.2,6.3-17,3.1-26.5l-5.2-15.2c-1.9-5.6-2.5-12-1.6-19.1
		c0.4-2.3,1.1-3.9,2.1-4.9c1-1,2.8-1.4,5.2-1.4c1.4,0,9,0,22.8,0C341.8,177.3,349.1,177.3,350.4,177.3z"
          />
          <path
            d="M392.6,333.7c-2.8,0-4.6-0.4-5.4-1.2c-0.8-0.8-1.4-2.7-1.7-5.6c-1-5.4-2.7-12.5-5-21.2c-0.5-1.9-1.5-5-2.9-9.2
		c-1.4-4.2-2.4-7.4-2.9-9.7c-2.6-10.5-1.1-20.3,4.7-29.4l8.4-13.1c5.2-8.2,6.3-17,3.1-26.5l-5.2-15.2c-1.9-5.6-2.4-12-1.3-19.1
		c0.4-2.3,1-3.9,2-4.9c1-1,2.7-1.4,5.1-1.4c14.3-0.2,29.4-0.2,45.3,0c2.5,0,4,0.4,4.7,1.2c0.7,0.8,1,2.5,1,5.1
		c-0.4,4.4-0.5,8-0.5,11c0,2.1-0.3,3.5-0.9,4.2c-0.6,0.7-2,1.1-4.1,1.1c-1.6,0.2-5,0.2-10.4,0.1c-5.3-0.1-8.9-0.1-10.6-0.1
		c-3.7,0.2-5.9,0.8-6.6,1.8c-0.7,1-0.4,3.3,0.8,6.8c1.9,5.6,3.1,8.8,3.4,9.7c2.3,7.3,2.2,14.1-0.3,20.4c-0.9,2.4-1,4.1-0.5,4.8
		c0.5,0.8,2.1,1.2,4.7,1.2c1.7,0,4.5,0,8.1,0s6.5,0,8.4,0c2.1,0.2,3.4,0.6,3.9,1.3c0.5,0.7,0.3,2-0.8,3.9c-0.5,0.9-3.3,5.5-8.4,13.9
		c-1.4,2.5-4,3.7-7.9,3.7c-2.1,0-4.8,0-8.1,0c-3.3,0-6,0-8.1,0c-3.1,0.2-5.4,0.8-6.7,2c-1.3,1.1-2,3.3-2,6.4c0,4,0.4,7.8,1.3,11.3
		c0,0.5,1.7,6.4,5.2,17.6c1.4,4.4,4.5,6.5,9.4,6.5c10,0,17,0,21.2,0c3,0,5.1,0.5,6.3,1.4c1.2,1,2.1,2.9,2.6,5.9
		c0.7,2.8,1.1,5.3,1.3,7.6c0.2,3.1-0.2,5.2-1,6.2s-2.9,1.4-6,1.4c-1.2,0-8.6,0-22.3,0C400.9,333.7,393.6,333.7,392.6,333.7z"
          />
          <path
            d="M479.6,333.7c-2.8,0-4.6-0.4-5.4-1.2c-0.8-0.8-1.4-2.7-1.7-5.6c-1-5.4-2.7-12.5-5-21.2c-0.5-1.9-1.5-5-2.9-9.2
		c-1.4-4.2-2.4-7.4-2.9-9.7c-2.6-10.5-1.1-20.3,4.7-29.4l8.4-13.1c5.2-8.2,6.3-17,3.1-26.5l-5.2-15.2c-1.9-5.6-2.4-12-1.3-19.1
		c0.4-2.3,1-3.9,2-4.9c1-1,2.7-1.4,5.1-1.4c14.3-0.2,29.4-0.2,45.3,0c2.5,0,4,0.4,4.7,1.2c0.7,0.8,1,2.5,1,5.1
		c-0.4,4.4-0.5,8-0.5,11c0,2.1-0.3,3.5-0.9,4.2c-0.6,0.7-2,1.1-4.1,1.1c-1.6,0.2-5,0.2-10.4,0.1c-5.3-0.1-8.9-0.1-10.6-0.1
		c-3.7,0.2-5.9,0.8-6.6,1.8c-0.7,1-0.4,3.3,0.8,6.8c1.9,5.6,3.1,8.8,3.4,9.7c2.3,7.3,2.2,14.1-0.3,20.4c-0.9,2.4-1,4.1-0.5,4.8
		c0.5,0.8,2.1,1.2,4.7,1.2c1.7,0,4.5,0,8.1,0c3.7,0,6.5,0,8.4,0c2.1,0.2,3.4,0.6,3.9,1.3c0.5,0.7,0.3,2-0.8,3.9
		c-0.5,0.9-3.3,5.5-8.4,13.9c-1.4,2.5-4,3.7-7.9,3.7c-2.1,0-4.8,0-8.1,0c-3.3,0-6,0-8.1,0c-3.1,0.2-5.4,0.8-6.7,2
		c-1.3,1.1-2,3.3-2,6.4c0,4,0.4,7.8,1.3,11.3c0,0.5,1.7,6.4,5.2,17.6c1.4,4.4,4.5,6.5,9.4,6.5c10,0,17,0,21.2,0c3,0,5.1,0.5,6.3,1.4
		c1.2,1,2.1,2.9,2.6,5.9c0.7,2.8,1.1,5.3,1.3,7.6c0.2,3.1-0.2,5.2-1,6.2c-0.9,1-2.9,1.4-6,1.4c-1.2,0-8.6,0-22.3,0
		C487.9,333.7,480.6,333.7,479.6,333.7z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Name
