import React from "react"
import styled, { keyframes } from "styled-components"

import logo from "./bf-logo.png"

import media from "../../../styles/media"

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Container = styled.div`
  transform-origin: top center;
  transform: scale(4);
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: center center;
  /* mix-blend-mode: multiply; */
  svg {
    animation: ${spin} 50000ms linear infinite;
    transform-origin: center center;
    enable-background: new 0 0 1538 1561.2;
    .st0 {
      fill: rgba(255, 255, 255, 0.15);
    }
  }
  ${media.tablet`
    transform-origin: top right;
    transform: scale(1.75) translate(0, -100px);
    max-width: 75%;
    background-size: 100px;
    svg {
      .st0 {
        fill: rgba(255, 255, 255, 0.99);
      }
    }
  `}
`

const Year = () => {
  return (
    <Container>
      <svg x="0px" y="0px" viewBox="0 0 1538 1561.2">
        <g>
          <path
            className="st0"
            d="M528.1,202.1l24.3,89.9l-144.9,84.9l16.2-140c11.5-179.8-147.3-93.6-94.6-32.1l-3.9,6.7
		c-49.7-30.5-50.1-105.6,12.9-142.5c36.4-21.3,88.3-8.7,107.7,24.4c32.8,56-16.3,145-21.2,181.6l-2.3,17l78.6-46.1
		c12.4-7.2,24.8-22.8,24.2-42.1L528.1,202.1z"
          />
          <path
            className="st0"
            d="M1195.4,517.7c-45.4-81.4-7.8-150.2,65.1-190.8c73.3-40.9,155.6-38.9,200.9,42.6c45.2,81.1,3.6,152-69.8,192.9
		C1318.8,603.1,1240.6,598.8,1195.4,517.7z M1458.2,371.4c-16.8-30.2-47.4-13.2-148.8,43.3c-101,56.2-127.5,71-110.7,101.2
		c15.6,28,43.2,15.1,144.1-41.2C1444.2,418.3,1474.2,400.1,1458.2,371.4z"
          />
          <path
            className="st0"
            d="M955.6,1252.5l-1.7-2.9l95.3-55.2l1.7,2.9c-15,8.7-9.9,20.8,0.1,38l92,158.8c19.9,34.3,40-13.4,40-13.4
		l3.3,1.5l-34.1,100.2l-33.9,19.7l-132.6-228.9C975,1254.9,968.4,1245.1,955.6,1252.5z"
          />
          <path
            className="st0"
            d="M215.4,1264.7c-54.3,36.4-104.1,15.9-139.8-37.4c-46-68.7-20-126.2,49.7-172.9
		c86.2-57.7,150.3-75.8,212.5,17.1c26.5,39.6,14.1,71.8,11.6,76.5c-5.4,11.7-19.1,21.4-33.8,21.6c-21.8,0.9-39.1-16-40-37.7
		c-0.4-21.1,16.5-39,37.6-39.4c10.6,0,20.7,2.9,27.2,10.2c2.8,2.7,8.8-7.4-5.8-29.1c-29.3-43.8-60.3-15-150.1,44.2
		c15.1-7.1,44.8,1,58,20.6C285.3,1202.2,254,1238.9,215.4,1264.7z M164.5,1203.9c45.9-30.7,66.1-51.4,55.6-67.2
		c-11.7-17.5-25.8-20.3-43.9-13.3c-75.4,52-114,76.9-98.3,100.3C92.9,1246.3,110.2,1240.3,164.5,1203.9z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Year
