import React from "react"
import styled from "styled-components"

import Heading from "./heading"
import Year from "./year"
import Label from "../../elements/label"

// import logo from "./bf-logo.png"

import media from "../../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template-columns: 15vw 1fr 10vw;
  grid-template-rows: auto auto;
  grid-template-areas: "heading heading heading" "year story social";
  ${media.tablet`
    grid-template-columns: 25vw 1fr 20vw;
    grid-template-rows: auto auto;
  `}
`

const HeadingContainer = styled.div`
  grid-area: heading;
  padding-bottom: 4rem;
  overflow: hidden;
  ${media.tablet`
    padding-bottom: 6rem;
  `}
`

const Story = styled.div`
  grid-area: story;
  padding-bottom: 4rem;
  h4 {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    line-height: 1.25;
  }
  p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  ul {
    li {
      font-size: 1rem;
      list-style: lower-roman;
      padding-left: 1rem;
    }
  }
  ${media.tablet`
    padding-bottom: 6rem;
    h4 {
      font-size: 1.85rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.75;
    }
    ul {
      margin-bottom: 2rem;
      li {
        font-size: 1.25rem;
        line-height: 1.75;
      }
    }
  `}
`

const YearContainer = styled.div`
  grid-area: year;
  /* overflow: hidden; */
`

const Social = styled.div`
  grid-area: social;
  transform-origin: 0% 0%;
  transform: rotate(90deg) translate(0px, calc(-20vw + 40px));
  white-space: nowrap;
`

const Intro = () => {
  return (
    <Container>
      <HeadingContainer>
        <Heading />
      </HeadingContainer>
      <Story>
        <h4>
          &#34;Ryan Holiday is part Machiavelli, part Ogilvy, and all
          results…this whiz kid is the secret weapon you&#39;ve never heard
          of.&#34; —Tim Ferriss
        </h4>
        <p>
          The bestselling author will give you the inside scoop on new marketing
          tactics being imagined and implemented by the likes of Pernod Ricard,
          Vlerick Business School in Belgium, Mastermind Talks and McGraw Hill.
          After a glimpse into the strategies of top start-ups and tech
          unicorns, Black Coffee will join Ryan Holiday on stage to discuss what
          he and his team are doing to propel his brand into the global market.
          And the two heavy-weights will be taking questions from the audience
          too.
        </p>
        <p>
          This 5-hour masterclass is for everyone, from students to business
          leaders, from entrepreneurs to marketers. Whatever your industry or
          ambitions, you will leave with the practical skills to drive
          exponential growth within your business.
        </p>
        <p>
          Our Sound Idea Sessions are designed for individuals and businesses
          who want to master the art of growth hacking through understanding the
          principles and grasping the practical skills.
        </p>
        <p>Our broad range of delegates include:</p>
        <ul>
          <li>Heads of Marketing</li>
          <li>
            Heads of Sales and Distribution seeking to drive growth for large
            organisations with limited resources.
          </li>
          <li>
            Emerging entrepreneurs seeking to drive exponential growth for their
            start-ups.
          </li>
          <li>
            Corporate leaders who are seeking to drive cultural transformation
            by infusing an agile, start-up culture within their companies.
          </li>
          <li>
            Senior academics and lecturers in the disciplines of Marketing and
            PR.
          </li>
          <li>
            Musicians and creatives with aspirations of growing a fanbase
            outside of South Africa.
          </li>
        </ul>
      </Story>
      <YearContainer>
        <Year />
      </YearContainer>
      <Social>
        <Label
          fontSize={0.8}
          fontScaled
          innerRotate={0}
          outerRotate={0}
          value="Instagram"
          mRight={0.5}
          url="https://www.instagram.com/soundideasessions/"
          className="mouse-link"
        />
        <Label
          fontSize={0.8}
          fontScaled
          innerRotate={0}
          outerRotate={0}
          value="Twitter"
          mRight={0.5}
          url="https://twitter.com/SISessions"
          className="mouse-link"
        />
        <Label
          fontSize={0.8}
          fontScaled
          innerRotate={0}
          outerRotate={0}
          value="Facebook"
          mRight={0.5}
          url="https://www.facebook.com/soundideasessions/"
          className="mouse-link"
        />
      </Social>
    </Container>
  )
}

export default Intro
