import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import AboutPage from "../components/AboutPage"

const About = () => {
  return (
    <>
      <SEO title="About" />
      <Navbar back topOffset={0} bottomOffset={100} />
      <AboutPage />
      <Footer back />
    </>
  )
}

export default About
