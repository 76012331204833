import React from "react"
import styled from "styled-components"

import Label from "../elements/label"
import Image from "../elements/image"

import media from "../../styles/media"

const Container = styled.div`
  background-color: rgba(246, 86, 100, 0.99);
  padding: var(--gutter-l) var(--gutter-s);
  ${media.tablet`
    padding: var(--gutter-l) var(--gutter-l) var(--gutter-l) 15vw;
  `}
`

const Row = styled.div`
  display: grid;
  grid-template: 200px auto / 1fr;
  margin-bottom: calc(5vh - 2rem);
  ${media.tablet`
    grid-template: auto / 250px 1fr;
    margin-bottom: calc(10vh - 2rem);
  `}
`

const RowTitle = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const RowList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${media.tablet`
    flex-wrap: wrap;
    justify-content: flex-start;
  `}
`

const Logo = styled.div`
  margin: 1rem 2rem;
  a {
    .gatsby-image-wrapper {
      width: 100px;
      height: 75px;
      img {
        object-fit: contain !important;
      }
    }
  }
  ${media.tablet`
    a {
      .gatsby-image-wrapper {
        width: 150px;
        height: 100px;
      }
    }
  `}
`

const Sponsors = () => {
  return (
    <Container name="partners" id="Partners">
      {/* <Row>
        <RowTitle>
          <Label outerRotate={10} innerRotate={1} value="Sponsors" />
        </RowTitle>
        <RowList>
        </RowList>
      </Row> */}
      <Row>
        <RowTitle>
          <Label
            fontSize={0.8}
            outerRotate={-10}
            innerRotate={-1}
            value="Growth Hacker"
          />
          <Label
            fontSize={0.8}
            outerRotate={-10}
            innerRotate={-1}
            value="Marketing Partners"
          />
        </RowTitle>
        <RowList>
          <Logo>
            <a
              href="https://adbot.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/ad-bot.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://africaworksventures.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/africa-works-ventures.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.dailymaverick.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/daily-maverick.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.experthub.info/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/experthub.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://firingsquad.co/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/firing-squad.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.heavychef.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/heavy-chef.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.kayafm.co.za"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/kayafm.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.dailymaverick.co.za/maverick-life/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/maverick-life.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.nfinity.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/nfinity-logo.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.nfinity.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/nfluential.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.raizcorp.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/raizcorp.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.bee123.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/sage.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://sandtoncity.com/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/sandtoncity.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://thesociallocal.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/social-local.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.treeshake.com"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/treeshake.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="http://wordstart.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/word-start.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
        </RowList>
      </Row>
      <Row>
        <RowTitle>
          <Label
            fontSize={0.8}
            outerRotate={-10}
            innerRotate={-1}
            value="Value Add"
          />
          <Label
            fontSize={0.8}
            outerRotate={-10}
            innerRotate={-1}
            value="Partners"
          />
        </RowTitle>
        <RowList>
          <Logo>
            <a
              href="https://www.geoafrika.co.za"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/geoafrika.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.kw.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/kwa.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://www.parcelninja.com/?gclid=CjwKCAjwm4rqBRBUEiwAwaWjjMF_9R6apo8pdn310LN4_elJAhdMVykJdIihKZ0vc6BKYfuvQawFuxoCq7AQAvD_BwE"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/parcel-ninja.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
          <Logo>
            <a
              href="https://youknow.co.za/"
              target="_blank"
              rel="noreferrer noopener"
              className="mouse-link"
            >
              <Image
                src="sponsors/youknow.png"
                alt="Sound Idea Sessions | Partners"
              />
            </a>
          </Logo>
        </RowList>
      </Row>
    </Container>
  )
}

export default Sponsors
